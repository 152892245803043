import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { RegionSettingsInvoices } from "./region-settings-invoices";
import { RegionSettingsNav } from "./region-settings-nav";

export const RegionSettings: React.FC<unknown> = () => {
    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <div className="lg:col-span-3">
                <RegionSettingsNav />
            </div>
            <div className="lg:col-span-9 pb-4">
                <Switch>
                    <Route
                        path="/settings/region/invoices"
                        component={RegionSettingsInvoices}
                    />
                    <Route
                        path="/settings/region"
                        render={() => <Redirect to="/settings/region/invoices" />}
                    />
                </Switch>
            </div>
        </div>
    );
};
