import { SearchHeader } from "components/simple-search/search-header";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logout, navigate } from "store/actions";
import { ResponsiveMode } from "store/reducers/responsive";
import { useProductBranding } from "store/selectors/branding";
import {
    useCurrentTenant,
    useCurrentUser,
    useResponsiveMode,
} from "store/selectors/hooks";
import { Reminders } from "../reminders";
import { MenuControl } from "../responsive/menu-control";
import { Col, Row } from "../shared/grid";
import { TopMenu } from "./top-menu";

export type HeaderItem =
    | "pipeline"
    | "timeline"
    | "clients"
    | "communities"
    | "references";

const AppLogo: React.FC = () => {
    const tenant = useCurrentTenant();
    const { branding } = useProductBranding();

    const customTenantLogo = tenant?.hasLogo
        ? `/api/tenants/${tenant.tenantId}/logo`
        : null;

    if (customTenantLogo) {
        return (
            <Col className="title-whitelabel-container">
                <Link to="/">
                    <img className="app-whitelabel-logo" src={customTenantLogo} />
                </Link>
            </Col>
        );
    }

    if (branding) {
        const customBrandingLogo = branding.logoUrl.startsWith("data:image");

        if (customBrandingLogo) {
            return (
                <Col className="title-whitelabel-container">
                    <Link to="/">
                        <img className="app-whitelabel-logo" src={branding.logoUrl} />
                    </Link>
                </Col>
            );
        }

        const logoStyle = {
            backgroundImage: `url(${branding.logoUrl})`,
            backgroundPosition: "0 7px",
            backgroundRepeat: "no-repeat",
        };

        return (
            <Col className="title-link-container">
                <Link to="/" className="title-link" style={logoStyle}>
                    {branding.productName}
                </Link>
            </Col>
        );
    }

    return null;
};

const HeaderMenu: React.FC = () => {
    const responsiveMode = useResponsiveMode();
    const user = useCurrentUser();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const onNavigate = React.useCallback((path: string) => {
        navigate(path);
    }, []);

    const onLogout = React.useCallback(() => {
        logout(dispatch);
    }, [dispatch]);

    let item: HeaderItem = null;
    if (pathname === "/" || pathname.startsWith("/pipeline")) {
        item = "pipeline";
    } else if (pathname.startsWith("/timeline")) {
        item = "timeline";
    } else if (pathname.startsWith("/clients")) {
        item = "clients";
    } else if (pathname.startsWith("/communities")) {
        item = "communities";
    } else if (pathname.startsWith("/references")) {
        item = "references";
    }

    if (!user) return null;

    if (responsiveMode >= ResponsiveMode.large) {
        return (
            <TopMenu
                logout={onLogout}
                currentUser={user}
                navigate={onNavigate}
                selectedItem={item}
            />
        );
    }
    return null;
};

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = props => {
    return (
        <Row className="header-control noprint z-1400 shadow" type="flex" id="header">
            <Col className="menu-control-container">
                <MenuControl />
            </Col>
            <AppLogo />
            <Col className="menu-container">
                <HeaderMenu />
            </Col>
            <Col>
                <SearchHeader />
            </Col>
            <Reminders />
        </Row>
    );
};
