import { ClientId, EmailTemplateId } from "@sp-crm/core";
import { TemplateSelect } from "components/templates/template-select";
import { fancyAlert } from "components/ui/fancy-alert";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { getClient } from "store/selectors/selectors";
import { useTenantSettings } from "store/selectors/settings";
import { ApplicationState } from "store/state";
import { handleEvent } from "util/user-events";
import {
    OutboundClientIntakeFax,
    useGetClientIntakeFaxRecipientsQuery,
    useSendClientIntakeFaxMutation,
} from "../../../../generated/graphql";
import { userPreferences } from "../../../../store/selectors/preferences";
import { ClientReferralPdfCustomization, defaultClientPDFOptions } from "./customize";
import { FaxRecipient, FaxRecipients } from "./fax-recipients";

interface ClientIntakeFaxProps {
    clientId: ClientId;
    onDismiss: () => void;
}

export const ClientIntakeFax: React.FC<ClientIntakeFaxProps> = props => {
    const client = useSelector((state: ApplicationState) =>
        getClient(state, props.clientId),
    );
    const prefs = useSelector((state: ApplicationState) => userPreferences(state));
    const [pdfOptions, setPdfOptions] = React.useState(
        defaultClientPDFOptions(client, prefs),
    );
    const mutation = useSendClientIntakeFaxMutation();

    const [recipients, setRecipients] = React.useState<FaxRecipient[]>([]);
    const query = useGetClientIntakeFaxRecipientsQuery(
        { clientId: props.clientId },
        { refetchOnReconnect: false, refetchOnMount: false, refetchOnWindowFocus: false },
    );
    useEffect(() => {
        if (query && query.data && !query.isLoading && !query.isError) {
            setRecipients(
                query.data.getClientIntakeFaxRecipients.map(r => {
                    return { ...r, selected: true };
                }),
            );
        }
    }, [query.isError, query.isLoading, query.data, setRecipients]); // eslint-disable-line react-hooks/exhaustive-deps
    const [selectedTemplateId, setSelectedTemplateId] =
        React.useState<EmailTemplateId | null>(null);
    const { settings } = useTenantSettings();
    useEffect(() => setSelectedTemplateId(settings.faxCoverPageTemplateId), [settings]);
    const [showErrors, setShowErrors] = React.useState(false);
    const send = useCallback(() => {
        (async () => {
            const actualRecipients = recipients
                .filter(r => r.selected)
                .map(r => {
                    return {
                        communityId: r.communityId,
                        faxNumber: r.faxNumber,
                    };
                });
            const actualOptions = {
                ...pdfOptions,
            };
            delete actualOptions.filename;
            if (actualRecipients.length === 0) {
                await fancyAlert(
                    "No communities selected",
                    "Please select at least one community.",
                );
                setShowErrors(true);
                return;
            }
            if (actualRecipients.some(r => !r.faxNumber || r.faxNumber.trim() === "")) {
                await fancyAlert(
                    "Missing fax number",
                    "Please enter a fax number for each selected community.",
                );
                setShowErrors(true);
                return;
            }
            handleEvent("message-send", { source: "client-intake-fax" });
            const payload: OutboundClientIntakeFax = {
                clientId: props.clientId,
                recipients: actualRecipients,
                options: actualOptions,
                coverPageTemplateId: selectedTemplateId,
            };
            await mutation.mutateAsync({ message: payload });
            props.onDismiss();
        })();
    }, [props, recipients, pdfOptions, selectedTemplateId, mutation]);
    return (
        <Panel
            type={PanelType.largeFixed}
            onDismiss={props.onDismiss}
            isOpen={true}
            headerText="Fax client intake">
            <div className="space-y-2 lg:space-y-6">
                <FaxRecipients
                    showErrors={showErrors}
                    recipients={recipients}
                    setRecipients={setRecipients}
                />
                <div>
                    <TemplateSelect
                        label="Cover page"
                        selectedTemplateId={selectedTemplateId}
                        onChange={setSelectedTemplateId}
                    />
                </div>
                <div>
                    <ClientReferralPdfCustomization
                        options={pdfOptions}
                        callback={setPdfOptions}
                    />
                </div>
                <div className="w-full space-x-2 items-center justify-end flex">
                    <SecondaryButton onClick={props.onDismiss}>Discard</SecondaryButton>
                    <PrimaryButton onClick={send}>Send</PrimaryButton>
                </div>
            </div>
        </Panel>
    );
};
