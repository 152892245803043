import { clientLocationTypes, IClient } from "@sp-crm/core";
import { Header } from "components/header";
import { LocaleContext } from "components/locale-provider/locale-provider";
import { AutosavingInput } from "components/ui/autosaving-input";
import { AutosavingStateInput } from "components/ui/autosaving-state-input";
import { CalendarDateInput } from "components/ui/calendar-date";
import { SelectPro } from "components/ui/select-pro";
import { SelectProOption } from "helpers/select-defs";
import React from "react";
import ClientBiographicalField from "./client-biographical-field";

interface ClientCurrentLocationProps {
    client: IClient;
    onFieldChange: (field: string, value: string) => void;
}

export const ClientCurrentLocation: React.FC<ClientCurrentLocationProps> = props => {
    const { client, onFieldChange } = props;

    const locationOptions: SelectProOption[] = [];
    for (const key in clientLocationTypes) {
        locationOptions.push({
            value: key,
            text: (clientLocationTypes as { [key: string]: string })[key],
        });
    }

    return (
        <LocaleContext.Consumer>
            {locale => (
                <div className="input-form-block-no-bottom-margin client-current-location-block">
                    <div className="flex-centered-column flex-space-between flex-row-no-bottom-margin">
                        <div className="header">
                            <Header iconName="37_DefaultCommunityAltColor">
                                Client&apos;s Current Location
                            </Header>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <AutosavingInput
                            label="Current location (where currently residing)"
                            initial={client.locationName}
                            onCommit={newVal => onFieldChange("locationName", newVal)}
                        />
                        <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                            <div className="flex-1">
                                <SelectPro
                                    label="Location type"
                                    includePlaceholderOption
                                    className="location-dropdown small-input-field form-group"
                                    value={client.currentLocationType}
                                    onChange={e =>
                                        onFieldChange(
                                            "currentLocationType",
                                            e.target.value,
                                        )
                                    }
                                    options={locationOptions}
                                />
                            </div>
                            <div className="flex-1">
                                <CalendarDateInput
                                    className="form-group location-dropdown"
                                    label="Discharge date (if applicable)"
                                    initial={client.dischargeDate}
                                    onChange={newVal =>
                                        onFieldChange(
                                            "dischargeDate",
                                            newVal ? newVal.toString() : null,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <ClientBiographicalField
                            type={"text"}
                            label={"Address"}
                            fieldName={"address"}
                            initial={client.address}
                            onFieldChange={onFieldChange}
                            disabled={client.clientSameAsBestContact}
                        />
                        <div className="flex items-begin space-x-4">
                            <div className="flex-1">
                                <ClientBiographicalField
                                    type={"text"}
                                    label={"City"}
                                    fieldName={"city"}
                                    initial={client.city}
                                    onFieldChange={onFieldChange}
                                    disabled={client.clientSameAsBestContact}
                                />
                            </div>
                            <div className="flex-1">
                                <AutosavingStateInput
                                    label={locale.strings.state}
                                    initial={client.state}
                                    onCommit={val => onFieldChange("state", val)}
                                    disabled={client.clientSameAsBestContact}
                                />
                            </div>
                            <div className="flex-1">
                                <ClientBiographicalField
                                    type={"text"}
                                    label={locale.strings.zip}
                                    fieldName={"zip"}
                                    initial={client.zip}
                                    onFieldChange={onFieldChange}
                                    disabled={client.clientSameAsBestContact}
                                />
                            </div>
                        </div>
                        <div className="other-location-notes">
                            <AutosavingInput
                                label="Additional living situation details"
                                multiLine={true}
                                initial={client.currentLocationNotes}
                                onCommit={newVal =>
                                    onFieldChange("currentLocationNotes", newVal)
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </LocaleContext.Consumer>
    );
};
