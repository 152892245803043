import { Input } from "components/ui/input";
import * as React from "react";
interface Props {
    label: string;
    initial: number;
    onCommit?: (newValue: number) => void;
    disabled?: boolean;
}
export const NumberInput: React.FC<Props> = props => {
    const { onCommit, initial, label, disabled } = props;
    const [value, setValue] = React.useState(initial);
    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.valueAsNumber);
            if (onCommit) {
                onCommit(e.target.valueAsNumber);
            }
        },
        [onCommit],
    );
    return (
        <div>
            <label>
                {label}
                <br />
                <Input
                    type="number"
                    value={value}
                    onChange={onChange}
                    min="0"
                    disabled={disabled}
                />
            </label>
        </div>
    );
};
