import {
    ArrowRightCircleIcon,
    CheckCircleIcon,
    ClockIcon,
} from "@heroicons/react/24/outline";
import { SignatureRequestId } from "@sp-crm/core";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { defaultLinkStyle } from "components/ui/link";
import { Spinner } from "components/ui/spinner";
import {
    GetSignatureRequestsForEntityQuery,
    SignatureRequestState,
    useAbandonSignatureRequestMutation,
    useResendSignatureRequestMutation,
} from "generated/graphql";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Actions } from "store/actions";
import { GlobalSuccessNotificationCreate } from "store/actions/global-notifications";

interface SignatureRequestRecipientsProps {
    request: GetSignatureRequestsForEntityQuery["getSignatureRequestsForEntity"][number];
    onRefetch(): void;
}

export const SignatureRequestRecipients: React.FC<
    SignatureRequestRecipientsProps
> = props => {
    const { request, onRefetch } = props;

    const globalDispatch = useDispatch();
    const router = useHistory();
    const resendMutation = useResendSignatureRequestMutation();
    const abandonMutation = useAbandonSignatureRequestMutation();

    const sortedRecipients = request.signatureRequestRecipients
        .slice()
        .sort((a, b) => a.order - b.order);
    const firstIncompleteRecipientIndex = sortedRecipients.findIndex(r => !r.isComplete);
    const cancellable = request.state === SignatureRequestState.InProgress;
    const canResend = request.state === SignatureRequestState.InProgress;
    const isCancelled = request.state === SignatureRequestState.Abandoned;

    const resend = async (signatureRequestId: SignatureRequestId) => {
        await resendMutation.mutateAsync({ signatureRequestId });
        const successNotification: GlobalSuccessNotificationCreate = {
            messageId: "signature-request-resent",
            messageTitle: "Sent!",
            messageBody: "Signature request re-sent successfully.",
            type: Actions[Actions.GLOBAL_SUCCESS_NOTIFY],
        };
        globalDispatch(successNotification);
    };

    const abandon = async (signatureRequestId: SignatureRequestId) => {
        const shouldAbandon = await fancyConfirm(
            "Cancel signature request?",
            "Are you sure you want to cancel this signature request?",
            "Yes, cancel",
            "No, keep signature",
        );
        if (shouldAbandon) {
            await abandonMutation.mutateAsync({ signatureRequestId });
            onRefetch();
            const successNotification: GlobalSuccessNotificationCreate = {
                messageId: "signature-request-abandon",
                messageTitle: "Cancelled",
                messageBody: "Signature request cancelled",
                type: Actions[Actions.GLOBAL_SUCCESS_NOTIFY],
            };
            globalDispatch(successNotification);
        }
    };

    const duplicate = async (
        destination: string,
        signatureRequestId: SignatureRequestId,
        cancellable: boolean,
    ) => {
        if (cancellable) {
            const shouldAbandon = await fancyConfirm(
                "Duplicate signature request",
                "Would you like to cancel the original signature request?",
                "Yes, cancel",
                "No, keep request",
            );
            if (shouldAbandon) {
                await abandonMutation.mutateAsync({ signatureRequestId });
                onRefetch();
            }
        }
        router.push(destination);
    };

    return (
        <ul className="space-y-1">
            {sortedRecipients.map((recipient, index) => (
                <li key={recipient.id}>
                    <div className="flex space-x-2 items-center">
                        {recipient.isComplete ? (
                            <CheckCircleIcon
                                className={`w-5 h-5  ${
                                    isCancelled ? "text-gray-200" : "text-green-400"
                                }`}
                            />
                        ) : index === firstIncompleteRecipientIndex ? (
                            <ArrowRightCircleIcon
                                className={`w-5 h-5  ${
                                    isCancelled ? "text-gray-200" : "text-blue-600"
                                }`}
                            />
                        ) : (
                            <ClockIcon
                                className={`w-5 h-5  ${
                                    isCancelled ? "text-gray-200" : "text-yellow-500"
                                }`}
                            />
                        )}
                        <span>{recipient.email}</span>
                        {index === firstIncompleteRecipientIndex && canResend ? (
                            <>
                                <a
                                    className={defaultLinkStyle}
                                    href="#"
                                    onClick={e => {
                                        e.preventDefault();
                                        resend(request.id);
                                    }}>
                                    Resend email
                                </a>
                                {resendMutation.isLoading &&
                                resendMutation.variables.signatureRequestId ===
                                    request.id ? (
                                    <Spinner />
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    {index === firstIncompleteRecipientIndex && recipient.remindAfter ? (
                        <p className="text-xs italic ml-7 text-gray-500">
                            Reminders will be sent 7, 14, and 30 days after they received
                            their first request.
                        </p>
                    ) : null}
                </li>
            ))}
            {cancellable ? (
                <li className="pl-7">
                    <a
                        className={defaultLinkStyle}
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            abandon(request.id);
                        }}>
                        Cancel request
                    </a>
                    {abandonMutation.isLoading &&
                    abandonMutation.variables.signatureRequestId === request.id ? (
                        <Spinner />
                    ) : null}
                </li>
            ) : null}
            {request.signatureTemplateFileId ? (
                <li className="pl-7">
                    <a
                        className={defaultLinkStyle}
                        href={`/files/${request.signatureTemplateFileId}/signatures/${request.entityId}/new?sourceRequestId=${request.id}`}
                        onClick={e => {
                            e.preventDefault();
                            duplicate(
                                `/files/${request.signatureTemplateFileId}/signatures/${request.entityId}/new?sourceRequestId=${request.id}`,
                                request.id,
                                cancellable,
                            );
                        }}>
                        Duplicate request
                    </a>
                </li>
            ) : null}
        </ul>
    );
};
