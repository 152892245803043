import { ActionType, RegionId } from "@sp-crm/core";
import { GetEntityRegionPermissionsQuery } from "generated/graphql";
import React, { useCallback } from "react";
import Multiselect from "react-widgets/Multiselect";
import { useEntityRegions } from "store/selectors/regions";
import { InlineBanner } from "./inline-banner";

interface RegionAccessInputProps {
    actionType: ActionType;
    label: string;
    editable: boolean;
    regions: RegionId[];
    onChange: (regions: RegionId[]) => void;
}

export const RegionAccessInput: React.FC<RegionAccessInputProps> = props => {
    const { label, editable, regions, onChange, actionType } = props;

    const entityRegions = useEntityRegions({ actionType, enabled: editable });

    const handleChange = useCallback(
        (
            newRegions: GetEntityRegionPermissionsQuery["getEntityRegionPermissions"]["editableRegions"],
        ) => {
            onChange(newRegions.map(r => r.id));
        },
        [onChange],
    );

    if (editable && entityRegions?.length > 1) {
        return (
            <div>
                <label>{label}</label>
                <Multiselect
                    data={entityRegions}
                    value={regions}
                    dataKey="id"
                    textField="name"
                    onChange={handleChange}
                />
                {regions.length === 0 ? (
                    <InlineBanner type="warning">
                        At least one office must be selected
                    </InlineBanner>
                ) : null}
            </div>
        );
    }

    return null;
};
