import { Meters, singleLineAddress } from "@sp-crm/core";
import { useLocalizedStrings } from "components/locale-provider/locale-provider";
import { GetUserProfileQuery, UserTerritoryType } from "generated/graphql";
import React from "react";

interface UserTerritoryPreviewProps {
    territory: GetUserProfileQuery["getUserProfile"]["territories"][number];
}

export const UserTerritoryPreview: React.FC<UserTerritoryPreviewProps> = props => {
    const { territory } = props;

    const locale = useLocalizedStrings();

    if (!territory) {
        return <div className="text-sm">No service area has been configured.</div>;
    }

    switch (territory.type) {
        case UserTerritoryType.Proximity:
            return (
                <div>
                    Within{" "}
                    {Math.floor(
                        locale.conversions.fromMeters(territory.distance as Meters),
                    )}{" "}
                    {territory.distance > 1
                        ? locale.strings.distanceUnitPlural
                        : locale.strings.distanceUnit}{" "}
                    of {singleLineAddress(territory)}
                </div>
            );
        default: {
            const exhaustiveCheck: never = territory.type;
            throw new Error(`Unknown territory type: ${exhaustiveCheck}`);
        }
    }
};
