import { UserCircleIcon } from "@heroicons/react/20/solid";
import { SearchUserProfilesQuery } from "generated/graphql";
import React from "react";

interface AgentDirectoryThumbnailProps {
    agent: SearchUserProfilesQuery["searchUserProfiles"]["hits"][number];
}

export const AgentDirectoryThumbnail: React.FC<AgentDirectoryThumbnailProps> = props => {
    const { agent } = props;

    return agent.pictureUrl ? (
        <img className="h-32 w-32 flex-shrink-0 rounded-md" src={agent.pictureUrl} />
    ) : (
        <UserCircleIcon className="h-32 w-32 flex-shrink-0 rounded-md text-gray-200" />
    );
};
