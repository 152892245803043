import {
    EnvelopeIcon,
    LinkIcon,
    PencilSquareIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {
    AnswerEntityId,
    IAnswer,
    LayoutSectionKey,
    LayoutSectionParentKey,
    QuestionSource,
    ReferenceBusinessId,
    ReferralTypesOptions,
    UserId,
    formatDateNoTime,
    parseLocation,
} from "@sp-crm/core";
import { BylineHeader } from "components/byline/header";
import { ListClient } from "components/clients/list-client";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { Activity } from "components/entity-activity/entity-activity";
import { FilesControl } from "components/files";
import { SignatureRequestPanel } from "components/files/signature-request/signature-request-panel";
import { Header } from "components/header";
import { EntityPageHeader, Stage } from "components/layout";
import { LayoutFormSection } from "components/layout/layout-form-section";
import { LayoutSectionResult } from "components/layout/layout-items";
import { LocaleContext } from "components/locale-provider/locale-provider";
import { NextgenStaticMap } from "components/map/nextgen-static";
import { MessageCompose } from "components/messages/message-compose";
import { SentMessages } from "components/messages/sent";
import { Notes } from "components/notes/index";
import { ActionBarComponent } from "components/shared/action-bar-component";
import { NgGrid } from "components/shared/grid";
import { PhoneInput } from "components/shared/phone-input";
import { Subnav } from "components/shared/subnav";
import { SubnavItem } from "components/shared/subnav/subnav-types";
import { UserSelect } from "components/shared/user-select";
import { CrmTable } from "components/table/crm-table";
import { CrmTableColumn } from "components/table/types";
import { Tasks } from "components/tasks/child";
import {
    ActionsMenu,
    ActionsMenuAction,
    ActionsMenuSection,
} from "components/ui/actions-menu";
import { AutosavingInput } from "components/ui/autosaving-input";
import { AutosavingStateInput } from "components/ui/autosaving-state-input";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { SecondaryButton } from "components/ui/secondary-button";
import { SelectPro } from "components/ui/select-pro";
import { Spinner } from "components/ui/spinner";
import { UrlButton } from "components/ui/url-button";
import {
    AdvancedSearchEntityType,
    FileEntityType,
    GetReferenceBusinessQuery,
    MutationPatchReferenceBusinessArgs,
    ReferenceContactSearchQuery,
    ReferenceContactSearchRequest,
    ReferralBasicsFragment,
    SearchClientsQuery,
    useCreateReferenceContactMutation,
    useGetLayoutContainerByKeyQuery,
    useGetReferenceBusinessQuery,
    useGetReferralsToBusinessQuery,
    usePatchReferenceBusinessMutation,
    usePatchReferenceContactMutation,
    useReferenceContactSearchQuery,
    useSearchClientsQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { navigate } from "store/actions";
import { loadLeafElementsForEntity } from "store/actions/entity";
import { createChangeRegionAction } from "store/actions/region";
import {
    useCanSeeReferralSourceOwners,
    useFeature,
    useRegionId,
    useRegions,
    useResponsiveMode,
    useUsers,
} from "store/selectors/hooks";
import { useEntityTasks } from "store/selectors/tasks";
import { ApplicationState } from "store/state";
import { stableQueryOptions } from "util/requests";
import { parseRouteId } from "util/route-id";
import { CreateReferralLinkPanel } from "../create-referral-link-panel";

type ReferenceBusinessResult = GetReferenceBusinessQuery["getReferenceBusiness"];

const getPreferredName = (reference: ReferenceBusinessResult) => {
    const contactName = reference.businessContactEntity.name || "(no name)";
    return contactName;
};

interface ReferenceBusinessHeaderProps {
    reference: ReferenceBusinessResult;
    onCompose: () => void;
    onDelete: () => void;
    onRequestSignature: () => void;
    onCreateReferralLink: () => void;
}

const ReferenceBusinessHeader: React.FC<ReferenceBusinessHeaderProps> = props => {
    const { reference, onCompose, onDelete, onRequestSignature, onCreateReferralLink } =
        props;
    return (
        <EntityPageHeader>
            <div className="page-title">
                <h2>
                    <Header iconName="39_Business">{getPreferredName(reference)}</Header>
                </h2>
            </div>
            <div className="flex items-center space-x-4">
                <div>
                    <BylineHeader entity={reference} />
                </div>
                <div>
                    <ActionsMenu>
                        <ActionsMenuSection>
                            <ActionsMenuAction Icon={EnvelopeIcon} onClick={onCompose}>
                                Send email
                            </ActionsMenuAction>
                            <ActionsMenuAction
                                Icon={PencilSquareIcon}
                                onClick={onRequestSignature}>
                                Request signature
                            </ActionsMenuAction>
                            <ActionsMenuAction
                                Icon={LinkIcon}
                                onClick={onCreateReferralLink}>
                                Share referral link
                            </ActionsMenuAction>
                        </ActionsMenuSection>
                        <ActionsMenuSection>
                            <ActionsMenuAction Icon={TrashIcon} onClick={onDelete}>
                                Delete
                            </ActionsMenuAction>
                        </ActionsMenuSection>
                    </ActionsMenu>
                </div>
            </div>
        </EntityPageHeader>
    );
};

interface ReferenceBusinessActionBarProps {
    reference: ReferenceBusinessResult;
    onChange: (args: MutationPatchReferenceBusinessArgs) => void;
}

const ReferenceBusinessActionBar: React.FC<ReferenceBusinessActionBarProps> = props => {
    const { reference, onChange } = props;

    const showAssignedTo = useCanSeeReferralSourceOwners();
    const userState = useUsers();

    let ownerName = "Unassigned";

    if (reference.ownerId && userState.users[reference.ownerId]) {
        ownerName = userState.users[reference.ownerId].name;
    }

    return (
        <div className="action-bar action-bar-standalone flex">
            <div className="notes-form flex-column widget">
                <span className="glyphicon glyphicon-book" />
                <label htmlFor="edit-reference-summary-notes">
                    <Header iconName="38_ReferralSource">
                        Organization Summary Notes
                    </Header>
                </label>
                <AutosavingInput
                    multiLine={true}
                    rows={5}
                    autoGrow={false}
                    initial={reference.summary}
                    onCommit={newVal => onChange({ id: reference.id, summary: newVal })}
                />
            </div>
            {showAssignedTo && (
                <div className="flex-column widget">
                    <ActionBarComponent label="Assigned to" value={ownerName}>
                        <UserSelect
                            includeEveryone={false}
                            includeUnassigned={true}
                            label="Assigned to"
                            onChange={newVal =>
                                onChange({
                                    id: reference.id,
                                    ownerId: (newVal as UserId) || null,
                                })
                            }
                            value={reference.ownerId || ""}
                        />
                    </ActionBarComponent>
                </div>
            )}
            <div className={`flex-column widget map ${showAssignedTo ? "" : "xl:ml-40"}`}>
                <NextgenStaticMap
                    entityId={reference.id}
                    iconType="violet"
                    location={parseLocation(
                        reference.latitude,
                        reference.longitude,
                    ).getOrElse(null)}
                />
            </div>
        </div>
    );
};

interface ReferenceBusinessEntityFormProps {
    reference: ReferenceBusinessResult;
    onChange: (args: MutationPatchReferenceBusinessArgs) => void;
    onAnswerCommitted: (entityId: AnswerEntityId, answer: IAnswer) => void;
    layoutSection: LayoutSectionResult | null;
}

const ReferenceBusinessEntityForm: React.FC<ReferenceBusinessEntityFormProps> = props => {
    const { reference, onChange, onAnswerCommitted, layoutSection } = props;

    const business = reference.businessContactEntity;
    return (
        <div className="business-details input-form-block-no-bottom-margin">
            <Header iconName="39_Business">Organization Info</Header>
            <LocaleContext.Consumer>
                {locale => (
                    <div className="space-y-2">
                        <div className="flex items-begin space-x-4">
                            <div className="flex-1">
                                <AutosavingInput
                                    label="Organization Name"
                                    initial={business.name}
                                    onCommit={newVal =>
                                        onChange({
                                            id: reference.id,
                                            businessContactEntity: { name: newVal },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-begin space-x-4">
                            <div className="flex-1">
                                <SelectPro
                                    label="Organization Type"
                                    includePlaceholderOption
                                    value={business.contactType}
                                    onChange={e =>
                                        onChange({
                                            id: reference.id,
                                            businessContactEntity: {
                                                contactType: e.target.value,
                                            },
                                        })
                                    }
                                    options={ReferralTypesOptions}
                                />
                            </div>
                        </div>
                        <div className="flex items-begin space-x-4">
                            <div className="flex-1">
                                <AutosavingInput
                                    label="Address"
                                    initial={business.address1}
                                    onCommit={newVal =>
                                        onChange({
                                            id: reference.id,
                                            businessContactEntity: { address1: newVal },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-begin space-x-4">
                            <div className="flex-1">
                                <AutosavingInput
                                    label="City"
                                    initial={business.city}
                                    onCommit={newVal =>
                                        onChange({
                                            id: reference.id,
                                            businessContactEntity: { city: newVal },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex-1">
                                <AutosavingStateInput
                                    label={locale.strings.state}
                                    initial={business.state}
                                    onCommit={newVal =>
                                        onChange({
                                            id: reference.id,
                                            businessContactEntity: { state: newVal },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex-1">
                                <AutosavingInput
                                    label={locale.strings.zip}
                                    initial={business.zip}
                                    onCommit={newVal =>
                                        onChange({
                                            id: reference.id,
                                            businessContactEntity: { zip: newVal },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-begin space-x-4">
                            <div className="flex-1">
                                <PhoneInput
                                    label="Office phone number"
                                    initial={business.phone1}
                                    onCommit={newVal =>
                                        onChange({
                                            id: reference.id,
                                            businessContactEntity: { phone1: newVal },
                                        })
                                    }
                                />
                            </div>
                            <div className="flex-1">
                                <PhoneInput
                                    label="Fax number"
                                    initial={business.fax}
                                    onCommit={newVal =>
                                        onChange({
                                            id: reference.id,
                                            businessContactEntity: { fax: newVal },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-begin space-x-4">
                            <div className="flex-1 flex items-end space-x-1">
                                <AutosavingInput
                                    label="Website"
                                    initial={business.website}
                                    onCommit={newVal =>
                                        onChange({
                                            id: reference.id,
                                            businessContactEntity: { website: newVal },
                                        })
                                    }
                                />
                                <UrlButton url={business.website} />
                            </div>
                        </div>
                        <LayoutFormSection
                            entityId={reference.id}
                            entity={reference}
                            entityType="ReferenceBusiness"
                            layoutSection={layoutSection}
                            mode="fieldsonly"
                            answerMode={QuestionSource.Entity}
                            onAnswerCommitted={onAnswerCommitted}
                        />
                    </div>
                )}
            </LocaleContext.Consumer>
        </div>
    );
};

interface ReferenceBusinessInboundReferralsProps {
    reference: ReferenceBusinessResult;
    clients: SearchClientsQuery["getClients"]["clients"];
}

const ReferenceBusinessInboundReferrals: React.FC<
    ReferenceBusinessInboundReferralsProps
> = props => {
    const { clients, reference } = props;

    const users = useUsers().users;
    const responsiveMode = useResponsiveMode();

    if (!reference) {
        return null;
    }

    if (clients.length == 0) {
        return (
            <div>
                <h3>
                    No Inbound Client Referrals have been received from this referral
                    source yet.
                </h3>
                <br />
                <p>
                    Note: when editing a Client profile, if you mark any client&apos;s
                    &quot;Referral Source&quot; as this referral organization, that client
                    will then be listed here.
                </p>
            </div>
        );
    }
    return (
        <div>
            <p>
                The table below shows all Clients referred to you (or your agency) that
                have come from this referral source ({getPreferredName(reference)}
                ).
            </p>
            <br />

            <ListClient
                showAssignedToColumn={false}
                users={users}
                clients={clients}
                tableShort={true}
                responsiveMode={responsiveMode}
                sourceType={"fromReferralBusiness"}
            />
        </div>
    );
};

interface ReferenceBusinessOutboundReferralsProps {
    outboundClients: ReferralBasicsFragment["client"][];
}

const ReferenceBusinessOutboundReferrals: React.FC<
    ReferenceBusinessOutboundReferralsProps
> = props => {
    const { outboundClients } = props;

    const users = useUsers().users;
    const responsiveMode = useResponsiveMode();

    if (outboundClients.length == 0) {
        return (
            <div>
                <h3>
                    No Outbound Client Referrals have been linked to this referral source
                    yet.
                </h3>
                <br />
                <p>
                    Note: when editing a Client profile, if you add a &quot;Professional
                    Referral&quot; and choose this referral source, that client will then
                    be listed here.
                </p>
                <p>
                    Tracking your Outbound Professional Referrals can help you identify
                    how often you are referring to this referral source vs. how often they
                    are referring to you.
                </p>
            </div>
        );
    }
    return (
        <div>
            <p>
                The table below shows all Clients you have referred out to this business /
                organization (marked as &quot;Professional Referrals&quot; on a Client
                profile).
            </p>
            <br />
            <ListClient
                showAssignedToColumn={false}
                users={users}
                clients={outboundClients}
                tableShort={true}
                responsiveMode={responsiveMode}
                sourceType={"fromReferralBusiness"}
            />
        </div>
    );
};

interface ReferenceBusinessOverviewProps {
    reference: ReferenceBusinessResult;
    onChange: (args: MutationPatchReferenceBusinessArgs) => Promise<void>;
    onAnswerCommitted: (entityId: AnswerEntityId, answer: IAnswer) => void;
}

const ReferenceBusinessOverview: React.FC<ReferenceBusinessOverviewProps> = props => {
    const { reference, onChange, onAnswerCommitted } = props;

    const getLayoutContainer = useGetLayoutContainerByKeyQuery(
        { key: LayoutSectionParentKey.ReferenceBusiness },
        stableQueryOptions(),
    );

    const contactInformationSection =
        getLayoutContainer.data?.getLayoutContainerByKey.layoutSections.find(
            s => s.sectionKey === LayoutSectionKey.ReferenceBusinessContactInformation,
        );
    const additionalInformationSection =
        getLayoutContainer.data?.getLayoutContainerByKey.layoutSections.find(
            s => s.sectionKey === LayoutSectionKey.ReferenceBusinessAdditionalInformation,
        );

    if (!reference) {
        return null;
    }

    return (
        <NgGrid>
            <NgGrid.Column>
                <ReferenceBusinessEntityForm
                    reference={reference}
                    onChange={onChange}
                    onAnswerCommitted={onAnswerCommitted}
                    layoutSection={contactInformationSection}
                />
            </NgGrid.Column>
            <NgGrid.Column>
                <Notes entityId={reference.id} />
                <LayoutFormSection
                    entityId={reference.id}
                    entity={reference}
                    entityType="ReferenceBusiness"
                    layoutSection={additionalInformationSection}
                    mode="tile"
                    answerMode={QuestionSource.Entity}
                    onAnswerCommitted={onAnswerCommitted}
                />
            </NgGrid.Column>
        </NgGrid>
    );
};

interface ReferenceBusinessContactsTabProps {
    referenceId: ReferenceBusinessId;
}

export const ReferenceBusinessContactsTab: React.FC<
    ReferenceBusinessContactsTabProps
> = props => {
    const { referenceId } = props;
    const createContactMutation = useCreateReferenceContactMutation();
    const patchContactMutation = usePatchReferenceContactMutation();
    const regionId = useRegionId();

    const handleNewContact = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            const result = await createContactMutation.mutateAsync({ regionId });
            await patchContactMutation.mutateAsync({
                id: result.createReferenceContact.id,
                businessId: referenceId,
            });
            navigate(`/references/contacts/show/${result.createReferenceContact.id}`);
        },
        [createContactMutation, regionId, patchContactMutation, referenceId],
    );

    const referenceContactSearchRequest: ReferenceContactSearchRequest = {
        page: 0,
        perPage: 1000,
        search: "",
        sort: "numberOfReferrals",
        sortDirection: "DESC",
        regionId,
        businessId: referenceId,
    };

    const contactSearchQuery = useReferenceContactSearchQuery({
        search: referenceContactSearchRequest,
    });

    const rows = useMemo(() => {
        return contactSearchQuery.data?.referenceContactSearch.hits || [];
    }, [contactSearchQuery.data?.referenceContactSearch.hits]);

    const columns = useMemo(() => {
        return [
            {
                key: "name",
                header: "Name",
                renderCell: (props: {
                    record: ReferenceContactSearchQuery["referenceContactSearch"]["hits"][0];
                }) => {
                    return (
                        <Link to={`/references/contacts/show/${props.record.id}`}>
                            {props.record.name || "(no name)"}
                        </Link>
                    );
                },
            },
            {
                key: "role",
                header: "Role / Title",
            },
            {
                key: "numberOfReferrals",
                header: "Number of referrals",
            },
            {
                key: "mostRecentReferral",
                header: "Most recent referral",
                renderCell: (props: {
                    record: ReferenceContactSearchQuery["referenceContactSearch"]["hits"][0];
                }) => {
                    if (!props.record.mostRecentReferral) {
                        return <span></span>;
                    }

                    return (
                        <span>{formatDateNoTime(props.record.mostRecentReferral)}</span>
                    );
                },
            },
        ] as CrmTableColumn<
            ReferenceContactSearchQuery["referenceContactSearch"]["hits"][0]
        >[];
    }, []);

    return (
        <div>
            <SecondaryButton onClick={handleNewContact}>
                Add a new contact
            </SecondaryButton>
            <CrmTable data={rows} columns={columns} />
        </div>
    );
};

interface ShowReferenceBusinessInternalProps {
    referenceId: ReferenceBusinessId;
    subpage?: string;
}

const ShowReferenceBusinessInternal: React.FC<
    ShowReferenceBusinessInternalProps
> = props => {
    const { referenceId, subpage } = props;

    const getReferenceBusinessQuery = useGetReferenceBusinessQuery(
        { id: referenceId },
        { keepPreviousData: true },
    );
    const getOutboundReferralsQuery = useGetReferralsToBusinessQuery({
        referenceBusinessId: referenceId,
    });
    const reference = getReferenceBusinessQuery.data?.getReferenceBusiness;
    const patchReferenceBusinessMutation = usePatchReferenceBusinessMutation();
    const [composing, setComposing] = useState(false);
    const [requestSignature, setReqesutSignature] = useState(false);
    const [createReferralLink, setCreateReferralLink] = useState(false);
    const client = useQueryClient();
    const faxEnabled = useFeature("fax");
    const files = useSelector(
        (state: ApplicationState) =>
            (state.files.files && state.files.files[referenceId]) || [],
    );
    const dispatch = useDispatch();
    const regionId = useRegionId();
    const selectedReferenceRegionId = useRegionId("reference");
    const regions = useRegions();

    useEffect(() => {
        loadLeafElementsForEntity(referenceId, dispatch);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            selectedReferenceRegionId &&
            reference?.regionId &&
            selectedReferenceRegionId !== reference?.regionId
        ) {
            const newRegionKey = regions.find(
                r => r.referenceRegionId === reference?.regionId,
            )?.key;
            if (newRegionKey) {
                dispatch(createChangeRegionAction(newRegionKey));
            }
        }
    }, [reference?.regionId, selectedReferenceRegionId]); // eslint-disable-line react-hooks/exhaustive-deps

    const { tasks, refetchTasks } = useEntityTasks(referenceId);
    const activeTasks = useMemo(() => {
        return tasks.filter(t => t.active());
    }, [tasks]);

    const getInboundClientsQuery = useSearchClientsQuery(
        {
            regionId,
            referralBusinessId: referenceId,
            page: 1,
            perPage: 1000,
        },
        {
            keepPreviousData: true,
        },
    );

    const inboundClients = useMemo(() => {
        if (!referenceId || !getInboundClientsQuery.data?.getClients?.clients) {
            return [];
        }

        return getInboundClientsQuery.data.getClients.clients;
    }, [referenceId, getInboundClientsQuery.data]);

    const outboundClients = useMemo(() => {
        if (!referenceId || !getOutboundReferralsQuery.data) {
            return [];
        }

        return getOutboundReferralsQuery.data.getReferralsToBusiness
            .map(r => r.client)
            .filter(c => !!c);
    }, [referenceId, getOutboundReferralsQuery.data]);

    const handleReferenceChange = useCallback(
        async (args: MutationPatchReferenceBusinessArgs) => {
            const referenceData = client.getQueryData<GetReferenceBusinessQuery>([
                "getReferenceBusiness",
                { id: referenceId },
            ]);
            const result = await patchReferenceBusinessMutation.mutateAsync(args);

            const newData = produce(referenceData, draft => {
                if (draft) {
                    draft.getReferenceBusiness = result.patchReferenceBusiness;
                }
            });

            client.setQueryData<GetReferenceBusinessQuery>(
                ["getReferenceBusiness", { id: referenceId }],
                newData,
            );
        },
        [patchReferenceBusinessMutation, client, referenceId],
    );

    const handleAnswerCommitted = useCallback(
        (entityId: AnswerEntityId, answer: IAnswer) => {
            getReferenceBusinessQuery.refetch();
        },
        [getReferenceBusinessQuery],
    );

    const renderOverview = useCallback(() => {
        return (
            <ReferenceBusinessOverview
                reference={reference}
                onChange={handleReferenceChange}
                onAnswerCommitted={handleAnswerCommitted}
            />
        );
    }, [reference, handleReferenceChange, handleAnswerCommitted]);

    const renderInboundReferrals = useCallback(() => {
        if (!getInboundClientsQuery.data) {
            return <Spinner />;
        }

        return (
            <ReferenceBusinessInboundReferrals
                reference={reference}
                clients={inboundClients}
            />
        );
    }, [reference, inboundClients, getInboundClientsQuery.data]);

    const renderOutboundReferrals = useCallback(() => {
        return <ReferenceBusinessOutboundReferrals outboundClients={outboundClients} />;
    }, [outboundClients]);

    const renderMessages = useCallback(() => {
        return (
            <SentMessages
                entityId={referenceId}
                showClient={false}
                showCommunity={false}
            />
        );
    }, [referenceId]);

    const renderTasks = useCallback(() => {
        return (
            <NgGrid>
                <NgGrid.Column>
                    <Tasks parentId={referenceId} tasks={tasks} refetch={refetchTasks} />
                </NgGrid.Column>
            </NgGrid>
        );
    }, [referenceId, tasks, refetchTasks]);

    const renderFiles = useCallback(() => {
        return (
            <FilesControl entityId={referenceId} entityType={FileEntityType.Referral} />
        );
    }, [referenceId]);
    const renderActivity = useCallback(() => {
        return <Activity entityId={referenceId} entityType="ReferenceBusiness" />;
    }, [referenceId]);
    const renderContacts = useCallback(() => {
        return <ReferenceBusinessContactsTab referenceId={referenceId} />;
    }, [referenceId]);

    const handleNavigate = useCallback(
        (path: string) => {
            navigate(`/references/organizations/show/${referenceId}/${path}`);
        },
        [referenceId],
    );

    const handleCloseSection = useCallback(() => {
        navigate(`/references/organizations/show/${referenceId}`);
    }, [referenceId]);

    const handleCompose = useCallback(() => {
        setComposing(true);
    }, [setComposing]);

    const handleComposeDismiss = useCallback(() => {
        setComposing(false);
    }, [setComposing]);

    const handleDelete = useCallback(async () => {
        if (reference) {
            const shouldDelete = await fancyConfirm(
                `Delete ${getPreferredName(reference)}?`,
                `Really delete ${getPreferredName(reference)}?`,
                "Yes, delete",
                "Cancel",
            );
            if (shouldDelete) {
                await handleReferenceChange({ id: reference.id, deleted: true });
                navigate(`/references`);
            }
        }
    }, [reference, handleReferenceChange]);
    const handleRequestSignature = useCallback(() => {
        setReqesutSignature(true);
    }, []);
    const dismissRequestSignature = useCallback(() => {
        setReqesutSignature(false);
    }, []);

    const handleCreateReferralLink = useCallback(() => {
        setCreateReferralLink(true);
    }, []);
    const dismissCreateReferralLink = useCallback(() => {
        setCreateReferralLink(false);
    }, []);

    return (
        <QueryRenderer
            query={getReferenceBusinessQuery}
            name="ShowReferenceBusinessInternal.getReferenceBusiness">
            {data => {
                const subpages = [
                    {
                        href: `/references/organizations/show/${referenceId}/overview`,
                        linkText: "Organization Overview",
                        subpage: "overview",
                        render: renderOverview,
                    },
                    {
                        href: `/references/organizations/show/${referenceId}/contacts`,
                        linkText: "Contacts",
                        subpage: "contacts",
                        render: renderContacts,
                    },
                    {
                        href: `/references/organizations/show/${referenceId}/inbound`,
                        linkText: `Inbound Referrals`,
                        badgeCount: inboundClients.length,
                        subpage: "inbound",
                        render: renderInboundReferrals,
                    },
                    {
                        href: `/references/organizations/show/${referenceId}/outbound`,
                        linkText: `Outbound Referrals`,
                        badgeCount: outboundClients.length,
                        subpage: "outbound",
                        render: renderOutboundReferrals,
                    },
                    {
                        href: `/references/organizations/show/${referenceId}/emails`,
                        linkText: faxEnabled ? "Messages" : `Emails Sent`,
                        subpage: "emails",
                        render: renderMessages,
                    },
                    {
                        href: `/references/organizations/show/${referenceId}/tasks`,
                        linkText: "Tasks",
                        subpage: "tasks",
                        render: renderTasks,
                        badgeCount: activeTasks.length || undefined,
                        badgeType: activeTasks.some(t => t.overdue()) ? "error" : "info",
                    } as SubnavItem,
                    {
                        href: `/references/organizations/show/${referenceId}/files`,
                        subpage: "files",
                        linkText: `Files`,
                        badgeCount: files.length,
                        render: renderFiles,
                    },
                    {
                        href: `/references/contacts/show/${referenceId}/activity`,
                        subpage: "activity",
                        linkText: `Activity`,
                        render: renderActivity,
                    },
                ].filter(Boolean);

                const bgClass = ["files", "tasks", "contacts"].includes(subpage)
                    ? "bg-white"
                    : "";
                return reference.deleted ? (
                    <InlineBanner type="error">
                        This organization has been deleted and its information is no
                        longer available
                    </InlineBanner>
                ) : (
                    <>
                        <ReferenceBusinessHeader
                            reference={data.getReferenceBusiness}
                            onCompose={handleCompose}
                            onDelete={handleDelete}
                            onRequestSignature={handleRequestSignature}
                            onCreateReferralLink={handleCreateReferralLink}
                        />
                        {composing ? (
                            <MessageCompose
                                entityId={referenceId}
                                entityType={AdvancedSearchEntityType.ReferenceBusiness}
                                onDismiss={handleComposeDismiss}
                            />
                        ) : null}
                        <SignatureRequestPanel
                            fileEntityType={FileEntityType.Referral}
                            entityId={referenceId}
                            onDismiss={dismissRequestSignature}
                            isOpen={requestSignature}
                        />
                        {createReferralLink ? (
                            <CreateReferralLinkPanel
                                referenceBusinessId={reference.id}
                                onDismiss={dismissCreateReferralLink}
                            />
                        ) : null}
                        <ReferenceBusinessActionBar
                            reference={data.getReferenceBusiness}
                            onChange={handleReferenceChange}
                        />

                        <div
                            className={`col main-body full-width ${bgClass} min-h-screen`}>
                            <Subnav
                                subpages={subpages}
                                selectedSubpage={subpage}
                                defaultSubpage="overview"
                                navigate={handleNavigate}
                                closeSection={handleCloseSection}
                            />
                        </div>
                    </>
                );
            }}
        </QueryRenderer>
    );
};

type ShowOrganizationReferenceRouteMatch = {
    params?: { referenceId: string; subpage?: string };
} | null;

export const ShowOrganizationReference: React.FC<unknown> = () => {
    const routeMatch: ShowOrganizationReferenceRouteMatch = useRouteMatch();

    const referenceId = routeMatch?.params?.referenceId
        ? parseRouteId<ReferenceBusinessId>(
              "ReferenceBusiness",
              routeMatch.params.referenceId,
          )
        : null;

    if (referenceId) {
        return (
            <Stage>
                <ShowReferenceBusinessInternal
                    referenceId={referenceId}
                    subpage={routeMatch.params?.subpage}
                />
            </Stage>
        );
    }

    return null;
};
