import { InputCurrency } from "components/shared/input-currency";
import React from "react";
import { useTenantSettings } from "store/selectors/settings";

interface ClientDefaultFinanceQuestionsProps {
    budgetMonthly: string;
    budgetTotal: string;
    onFieldChange: (fieldName: string, newValue: string) => void;
}

export const ClientDefaultFinanceQuestions: React.FC<
    ClientDefaultFinanceQuestionsProps
> = props => {
    const { budgetMonthly, budgetTotal, onFieldChange } = props;
    const { settings } = useTenantSettings();

    return (
        <div className="adjacent-inputs">
            {settings.showClientMonthlyBudget || budgetMonthly ? (
                <InputCurrency
                    label="Monthly Budget"
                    initial={budgetMonthly}
                    onCommit={newVal => onFieldChange("budgetMonthly", newVal)}
                />
            ) : null}
            {settings.showClientTotalFinances || budgetTotal ? (
                <InputCurrency
                    label="Total Finances"
                    initial={budgetTotal}
                    onCommit={newVal => onFieldChange("budgetTotal", newVal)}
                />
            ) : null}
        </div>
    );
};
