import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React from "react";

export interface AdvancedTableProps {
    data: Record<string, unknown>[];
    columns: {
        key: string;
        header: string | JSX.Element;
        renderCell?: (props: {
            record: Record<string, unknown>;
            key: string;
        }) => JSX.Element;
        width?: number;
    }[];
    special?: (props: { record: Record<string, unknown> }) => JSX.Element;
}

export const AdvancedSearchTable = (props: AdvancedTableProps): JSX.Element => {
    const { data, columns, special } = props;
    const columnHelper = createColumnHelper<Record<string, unknown>>();
    const actualColumns = React.useMemo(() => {
        const result = columns.map(c => {
            if (c.renderCell) {
                return columnHelper.accessor(c.key, {
                    header: (c.header ?? c.key) as string,
                    size: c.width,
                    cell: p => {
                        return c.renderCell({ record: p.row.original, key: c.key });
                    },
                });
            }
            return columnHelper.accessor(c.key, {
                header: (c.header ?? c.key) as string,
                size: c.width,
            });
        });

        if (special) {
            result.push(
                columnHelper.display({
                    id: "_actions",
                    header: "",
                    cell: c => special({ record: c.row.original }),
                }),
            );
        }
        return result;
    }, [columns, columnHelper, special]);

    const table = useReactTable({
        columns: actualColumns,
        data,
        getCoreRowModel: getCoreRowModel(),
    });
    return (
        <table className="divide-y divide-gray-300 table-fixed">
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th
                                key={header.id}
                                colSpan={header.colSpan}
                                className={`py-3.5 text-left text-sm font-semibold text-gray-900 px-3 ${
                                    header.column.columnDef.size === 4
                                        ? "min-w-4"
                                        : "min-w-48"
                                } max-w-xl`}>
                                {header.isPlaceholder ? null : (
                                    <div>
                                        <div className="flex items-center space-x-1">
                                            <div>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className="hover:bg-brand-100">
                        {row.getVisibleCells().map(cell => (
                            <td
                                key={cell.id}
                                className={`px-3 py-4 text-sm font-medium text-gray-900 ${
                                    cell.column.columnDef.size === 4
                                        ? "min-w-4"
                                        : "min-w-48"
                                } max-w-xl`}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext(),
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
