import { CrmPersona } from "components/ui/persona";
import React from "react";
import { useTenantSettings } from "store/selectors/settings";
import { NO_NAME } from "../../util/text";

type ClientPageNameHeaderProps = {
    primaryClientName: string;
    additionalClientName: string;
    bestContactName: string;
    serial?: number;
};

export const ClientNamesHeader: React.FC<ClientPageNameHeaderProps> = props => {
    const { primaryClientName, additionalClientName, bestContactName, serial } = props;
    const { settings } = useTenantSettings();
    const showClientFirst = settings.showClientFirst;
    const showClientSerial = settings.showClientSerial && !!serial;
    const bestContactSegment =
        bestContactName && bestContactName.trim() !== "" ? (
            <CrmPersona name={bestContactName} size="auto" />
        ) : null;
    const clientNameSegment =
        (primaryClientName && primaryClientName.trim() !== "") ||
        (additionalClientName && additionalClientName.trim() !== "") ? (
            <div className="flex space-x-2 md:space-x-4">
                {primaryClientName && primaryClientName.trim() !== "" ? (
                    <CrmPersona name={primaryClientName} size="auto" />
                ) : null}
                {additionalClientName && additionalClientName.trim() !== "" ? (
                    <CrmPersona name={additionalClientName} size="auto" />
                ) : null}
            </div>
        ) : null;

    const nameSegments = (
        showClientFirst
            ? [clientNameSegment, bestContactSegment]
            : [bestContactSegment, clientNameSegment]
    ).filter(x => !!x);

    if (nameSegments.length === 0) {
        nameSegments.push(<h2 className="twoverride text-sm md:text-lg">{NO_NAME}</h2>);
    }
    return (
        <div className="flex items-center">
            {showClientSerial ? (
                <div className="mr-4 md:mr-8 text-gray-500 font-light text-lg md:text-2xl">
                    {serial}
                </div>
            ) : null}
            <div className="divide-x divide-solid divide-gray-300 flex items-center -ml-2 md:-ml-4">
                {nameSegments.map((x, i) => (
                    <div className="px-2 md:px-4" key={i}>
                        {x}
                    </div>
                ))}
            </div>
        </div>
    );
};
