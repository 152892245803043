import { ILocation, RegionId } from "@sp-crm/core";
import {
    HoveredMapEntity,
    MapMarkerId,
} from "components/community-search/community-map/types";
import { SearchResultsMap } from "components/map/search-results-map";
import { MapSearchResults, MapView } from "components/map/types";
import {
    ReferenceContactSearchRequest,
    ReferenceOrganizationSearchRequest,
    useReferenceContactMapSearchQuery,
    useReferenceOrganizationMapSearchQuery,
} from "generated/graphql";
import "maplibre-gl/dist/maplibre-gl.css";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

const staleTime = 1000 * 60 * 10; // 10m

interface ReferenceContactSearchResultsMapProps {
    hoveredOrganization: null | HoveredMapEntity;
    searchParams: ReferenceContactSearchRequest;
    regionId: RegionId;
    onExplicitBoundsChanged: (northWest: ILocation, southEast: ILocation) => void;
}

export const ReferenceContactSearchResultsMap: React.FC<
    ReferenceContactSearchResultsMapProps
> = props => {
    const { hoveredOrganization, searchParams, regionId, onExplicitBoundsChanged } =
        props;
    const [mapView, setMapView] = React.useState<MapView | null>(null);

    const query = useReferenceContactMapSearchQuery(
        {
            searchParams: { regionId, ...searchParams },
            mapView,
        },
        {
            staleTime,
            keepPreviousData: true,
            enabled: !!mapView,
        },
    );

    const searchResults: MapSearchResults = useMemo(() => {
        if (!query.data) {
            return null;
        }

        return {
            entityClusters:
                query.data.referenceContactMapSearch.referenceContactClusters.map(c => ({
                    lat: c.lat,
                    lng: c.lng,
                    count: c.count,
                })),
            entityCoordinates:
                query.data.referenceContactMapSearch.referenceContactCoordinates.map(
                    coordinate => ({
                        id: coordinate.businessId || coordinate.communityId,
                        lat: coordinate.lat,
                        lng: coordinate.lng,
                        name: coordinate.name,
                        color: "violet",
                        appLink: coordinate.appLink,
                    }),
                ),
            searchCoordinates: query.data.referenceContactMapSearch.coordinates,
            recommendedZoom: query.data.referenceContactMapSearch.recommendedZoom,
        };
    }, [query.data]);

    const renderMarkerContacts = React.useCallback(
        (id: MapMarkerId) => {
            const matchingCoordinate =
                query.data?.referenceContactMapSearch.referenceContactCoordinates.find(
                    c => c.businessId === id || c.communityId === id,
                );

            if (!matchingCoordinate) {
                return null;
            }

            return (
                <ul>
                    {matchingCoordinate.contacts.map(c => (
                        <li key={c.id}>
                            <Link to={c.appLink}>{c.name || "(no name)"}</Link>
                        </li>
                    ))}
                </ul>
            );
        },
        [query.data?.referenceContactMapSearch.referenceContactCoordinates],
    );

    return (
        <SearchResultsMap
            explicitBounds={searchParams.geoParams?.bounds || null}
            onExplicitBoundsChanged={onExplicitBoundsChanged}
            hoveredEntity={hoveredOrganization}
            isLoading={query.isLoading}
            onMapViewChanged={setMapView}
            results={searchResults}
            error={query.error}
            renderMarkerControl={renderMarkerContacts}
        />
    );
};

interface ReferenceOrganizationSearchResultsMapProps {
    hoveredOrganization: null | HoveredMapEntity;
    searchParams: ReferenceOrganizationSearchRequest;
    regionId: RegionId;
    onExplicitBoundsChanged: (northWest: ILocation, southEast: ILocation) => void;
}

export const ReferenceOrganizationSearchResultsMap: React.FC<
    ReferenceOrganizationSearchResultsMapProps
> = props => {
    const { hoveredOrganization, searchParams, regionId, onExplicitBoundsChanged } =
        props;
    const [mapView, setMapView] = React.useState<MapView | null>(null);

    const query = useReferenceOrganizationMapSearchQuery(
        {
            searchParams: { regionId, ...searchParams },
            mapView,
        },
        {
            staleTime,
            keepPreviousData: true,
            enabled: !!mapView,
        },
    );

    const searchResults: MapSearchResults = useMemo(() => {
        if (!query.data) {
            return null;
        }

        return {
            entityClusters:
                query.data.referenceOrganizationMapSearch.organizationClusters.map(c => ({
                    lat: c.lat,
                    lng: c.lng,
                    count: c.count,
                })),
            entityCoordinates:
                query.data.referenceOrganizationMapSearch.organizationCoordinates.map(
                    coordinate => ({
                        id: coordinate.id,
                        lat: coordinate.lat,
                        lng: coordinate.lng,
                        name: coordinate.name,
                        color: "violet",
                        appLink: coordinate.appLink,
                    }),
                ),
            searchCoordinates: query.data.referenceOrganizationMapSearch.coordinates,
            recommendedZoom: query.data.referenceOrganizationMapSearch.recommendedZoom,
        };
    }, [query.data]);

    return (
        <SearchResultsMap
            explicitBounds={searchParams.geoParams?.bounds || null}
            onExplicitBoundsChanged={onExplicitBoundsChanged}
            hoveredEntity={hoveredOrganization}
            isLoading={query.isLoading}
            onMapViewChanged={setMapView}
            results={searchResults}
            error={query.error}
        />
    );
};
