import { ChevronUpIcon, PencilIcon } from "@heroicons/react/24/outline";
import { AdvancedSearchDownload } from "components/advanced-search/advanced-search-download";
import { AdvancedSearchDuplicateButton } from "components/advanced-search/advanced-search-duplicate-button";
import { AdvancedSearchFavoriteButton } from "components/advanced-search/advanced-search-favorite-button";
import { AdvancedSearchInput } from "components/advanced-search/advanced-search-input";
import { AdvancedSearchResults } from "components/advanced-search/advanced-search-results";
import { AdvancedSearchScheduleButton } from "components/advanced-search/advanced-search-schedule-button";
import { AdvancedSearchScheduleEditor } from "components/advanced-search/advanced-search-schedule-editor";
import { AdvancedSearchSelect } from "components/advanced-search/advanced-search-select";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentContainer, ContentFull, SectionHeader } from "components/layout";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    AdvancedSearchRequest,
    GetSavedQueriesQuery,
    useGetEntitiesQuery,
} from "generated/graphql";
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { customReportsSlice } from "store/slices/custom-reports";
import { stableQueryOptions } from "util/requests";
import { handleEvent } from "util/user-events";
import { CustomReportWarnings } from "./custom-report-warnings";

interface Props {
    report: GetSavedQueriesQuery["getSavedQueries"]["savedQueries"][0];
    onReload: () => void;
    canDuplicateReports: boolean;
}

export const CustomReportsExisting: React.FC<Props> = props => {
    const { report, onReload, canDuplicateReports } = props;
    const { initialReportView, setExistingReportInput } = customReportsSlice.actions;
    const dispatch = useAppDispatch();
    const searchRequest = useAppSelector(
        state => state.customReports.existingReportInput[report.id] || null,
    );
    const [isEditing, setIsEditing] = useState(false);
    const hideEditor = useCallback(() => setIsEditing(false), []);
    const showEditor = useCallback(() => setIsEditing(true), []);
    const entitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());
    const [editingSchedule, setEditingSchedule] = useState(false);

    useEffect(() => {
        handleEvent("view-saved-report-results");
    }, [searchRequest]);

    useEffect(() => {
        dispatch(initialReportView(report));
    }, [report.id]); // eslint-disable-line react-hooks/exhaustive-deps

    const setSearchRequest = useCallback(
        (input: SetStateAction<AdvancedSearchRequest> | AdvancedSearchRequest) => {
            dispatch(
                setExistingReportInput({
                    reportId: report.id,
                    input,
                }),
            );
        },
        [report.id, setExistingReportInput, dispatch],
    );

    const savedReportContents = useMemo(() => {
        return {
            condition: report.condition,
            sort: report.sort,
            entityType: report.entityType,
            select: report.select,
            summaries: report.summaries,
        };
    }, [report]);

    if (!searchRequest) {
        return null;
    }

    return (
        <QueryRenderer query={entitiesQuery} name="CustomReportsExisting.getEntities">
            {data => (
                <div className="flex-1 min-w-0 space-y-2">
                    <SectionHeader title={report.name}>
                        <div className="flex items-center space-x-3">
                            {canDuplicateReports ? (
                                <AdvancedSearchDuplicateButton
                                    report={report}
                                    reload={onReload}
                                />
                            ) : null}
                            <AdvancedSearchFavoriteButton
                                report={report}
                                reload={onReload}
                            />
                            <AdvancedSearchScheduleButton
                                schedule={report.schedule}
                                onClick={() => setEditingSchedule(true)}
                            />
                            <AdvancedSearchDownload
                                searchRequest={searchRequest}
                                reportName={report.name}
                            />
                            <AdvancedSearchSelect
                                request={searchRequest}
                                entities={data.getEntities}
                                entityType={searchRequest.entityType}
                                onChange={setSearchRequest}
                            />
                            {isEditing ? (
                                <SecondaryButton
                                    className="flex items-center space-x-2"
                                    onClick={hideEditor}>
                                    <ChevronUpIcon className="w-4 h-4" />
                                    <div>Close editor</div>
                                </SecondaryButton>
                            ) : (
                                <SecondaryButton
                                    className="flex items-center space-x-2"
                                    onClick={showEditor}>
                                    <PencilIcon className="w-4 h-4" />
                                    <div>Edit query</div>
                                </SecondaryButton>
                            )}
                        </div>
                    </SectionHeader>
                    <ContentContainer className="space-y-2 lg:space-y-4">
                        {isEditing ? (
                            <AdvancedSearchInput
                                onCommit={setSearchRequest}
                                initial={savedReportContents}
                                report={report}
                                entities={data.getEntities}
                                committed={searchRequest}
                                canSaveAdditional={
                                    true /* since resaving existing report */
                                }
                            />
                        ) : null}
                        {searchRequest ? (
                            <ContentFull>
                                <CustomReportWarnings report={report} />
                                <AdvancedSearchResults
                                    request={searchRequest}
                                    onChangeRequest={setSearchRequest}
                                    includeIconLinks={true}
                                    includeSummaries={true}
                                />
                            </ContentFull>
                        ) : null}
                    </ContentContainer>
                    <Panel
                        isOpen={editingSchedule}
                        headerText="Email report"
                        type={PanelType.large}
                        onDismiss={() => setEditingSchedule(false)}>
                        <AdvancedSearchScheduleEditor
                            savedQueryId={report.id}
                            initial={report.schedule}
                            onSaved={() => {
                                setEditingSchedule(false);
                                onReload();
                            }}
                            onCancel={() => setEditingSchedule(false)}
                        />
                    </Panel>
                </div>
            )}
        </QueryRenderer>
    );
};
