import { formatDateNoTime, LatLng, ReferralMappings } from "@sp-crm/core";
import { HoveredMapEntity } from "components/community-search/community-map/types";
import { Icon } from "components/icon";
import { ClampedText } from "components/ui/clamped-text";
import { AdvancedSearchEntityType, ReferenceContactSearchQuery } from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useCanSeeReferralSourceOwners, usePreferences } from "store/selectors/hooks";
import { twoLineFormattedAddress } from "util/address";
import { EmailLink } from "util/email-link";
import { PhoneSingleNumber } from "util/phone-display";
import { breakNewlines } from "util/text";
import { ReferenceContactSearchCardAnswers } from "./reference-contact-search-card-answers";

interface ReferenceContactSearchListProps {
    contactSearchResults: ReferenceContactSearchQuery["referenceContactSearch"]["hits"];
    setHoveredOrganization: (hoveredOrganization: HoveredMapEntity | null) => void;
}

export const ReferenceContactSearchList: React.FC<
    ReferenceContactSearchListProps
> = props => {
    const { contactSearchResults, setHoveredOrganization } = props;

    const handleEndHover = useCallback(() => {
        setHoveredOrganization(null);
    }, [setHoveredOrganization]);

    return (
        <ul className="space-y-4">
            {contactSearchResults.map(contact => (
                <li key={contact.id}>
                    <ReferenceContactSearchCard
                        contact={contact}
                        onHover={setHoveredOrganization}
                        onEndHover={handleEndHover}
                    />
                </li>
            ))}
        </ul>
    );
};

interface ReferenceContactSearchCardProps {
    contact: ReferenceContactSearchQuery["referenceContactSearch"]["hits"][0];
    onHover: (hoveredOrganization: HoveredMapEntity | null) => void;
    onEndHover: () => void;
}

const ReferenceContactSearchCard: React.FC<ReferenceContactSearchCardProps> = props => {
    const { contact, onHover, onEndHover } = props;

    const { showReferralLastUpdatedDateOnMainTable } = usePreferences();
    const assignedToVisible = useCanSeeReferralSourceOwners();

    const orgTypeMapping = useMemo(() => {
        if (contact.organizationType && ReferralMappings[contact.organizationType]) {
            return ReferralMappings[contact.organizationType];
        }

        return null;
    }, [contact.organizationType]);

    const handleMouseEnter = () => {
        if (contact.businessId || contact.communityId) {
            let location: LatLng | null = null;
            if (contact.latitude && contact.longitude) {
                location = {
                    lat: parseFloat(contact.latitude),
                    lng: parseFloat(contact.longitude),
                };
            }

            onHover({ entityId: contact.businessId || contact.communityId, location });
        }
    };

    const handleMouseLeave = () => {
        onEndHover();
    };

    return (
        <div
            className="col-span-1 divide-y divide-gray-200 rounded md:rounded-lg bg-white shadow p-6"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="flex-1 overflow-auto">
                <div className="flex space-x-2">
                    <div className="flex-1">
                        <h3 className="twoverride text-gray-900 text-lg">
                            <Link
                                to={contact.appLink}
                                className="twoverride hover:underline">
                                <div className="flex space-x-2 items-center">
                                    <div className="w-6">
                                        <Icon name="40_ReferralPerson" />
                                    </div>
                                    <div>{contact.name || "(no name)"}</div>
                                </div>
                            </Link>
                        </h3>
                        {contact.role ? (
                            <div className="text-sm font-medium">{contact.role}</div>
                        ) : null}
                        <div className="text-sm">
                            {contact.email ? (
                                <EmailLink
                                    email={contact.email}
                                    entityId={contact.id}
                                    entityType={AdvancedSearchEntityType.ReferenceContact}
                                />
                            ) : null}
                            {contact.cellPhone ? (
                                <PhoneSingleNumber
                                    phoneNumber={contact.cellPhone}
                                    displayName="cell"
                                />
                            ) : null}
                            {contact.phone ? (
                                <PhoneSingleNumber
                                    phoneNumber={contact.phone}
                                    displayName="other"
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="flex-1">
                        {contact.organizationName &&
                        (contact.businessId || contact.communityId) ? (
                            <>
                                <h3 className="twoverride text-gray-900 text-lg">
                                    <Link
                                        to={
                                            contact.businessId
                                                ? `/references/organizations/show/${contact.businessId}`
                                                : `/communities/show/${contact.communityId}`
                                        }
                                        className="twoverride hover:underline">
                                        <div className="flex space-x-2 items-center">
                                            <div className="w-6">
                                                <Icon
                                                    name={
                                                        contact.businessId
                                                            ? "39_Business"
                                                            : "57_Community"
                                                    }
                                                />
                                            </div>
                                            <div>
                                                {contact.organizationName || "(no name)"}
                                            </div>
                                        </div>
                                    </Link>
                                </h3>
                                {orgTypeMapping ? (
                                    <div className="text-sm font-medium mb-1">
                                        {orgTypeMapping.category} &raquo;{" "}
                                        {orgTypeMapping.humanReadable}
                                    </div>
                                ) : null}
                                {twoLineFormattedAddress(
                                    contact.address,
                                    contact.city,
                                    contact.state,
                                    contact.zip,
                                    "text-sm text-gray-600",
                                )}
                                <div className="text-sm">
                                    {contact.organizationPhone ? (
                                        <PhoneSingleNumber
                                            displayName="office"
                                            phoneNumber={contact.organizationPhone}
                                        />
                                    ) : null}
                                    {contact.organizationFax ? (
                                        <PhoneSingleNumber
                                            displayName="fax"
                                            phoneNumber={contact.organizationFax}
                                        />
                                    ) : null}
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
                <div className="mt-4 border-t border-gray-200 pt-2">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                        {contact.summary ? (
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">
                                    Summary
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <ClampedText lines={3}>
                                        {breakNewlines(contact.summary)}
                                    </ClampedText>
                                </dd>
                            </div>
                        ) : null}
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                                Number of referrals
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900">
                                {contact.numberOfReferrals}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                                Most recent referral
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900">
                                {formatDateNoTime(contact.mostRecentReferral)}
                            </dd>
                        </div>
                        {showReferralLastUpdatedDateOnMainTable ? (
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Last updated
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900">
                                    {formatDateNoTime(contact.updatedAt)}
                                </dd>
                            </div>
                        ) : null}
                        {assignedToVisible && contact.assignedTo ? (
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Assigned to
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900">
                                    {contact.assignedTo}
                                </dd>
                            </div>
                        ) : null}
                        <ReferenceContactSearchCardAnswers contact={contact} />
                    </dl>
                </div>
            </div>
        </div>
    );
};
