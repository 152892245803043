import * as C from "@sp-crm/core";
import { ActionType, CommunityId, ContactId, FileId, IAnswer, IFile } from "@sp-crm/core";
import { BridgeQuestionShim } from "components/questions/bridge-question-shim";
import { QueryClientShim } from "components/shared/query-client-shim";
import { EntityTasksShim } from "components/tasks/child/entity-tasks-shim";
import { AdvancedSearchEntityType } from "generated/graphql";
import * as React from "react";
import { QueryClient } from "react-query";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { createChangeRegionAction } from "store/actions/region";
import { ApplicationState } from "store/state";
import { parseRouteId } from "util/route-id";
import * as actions from "../../../store/actions";
import {
    loadCommunity,
    updateCommunity,
    updateCommunityAnswer,
    updateCommunityFields,
    updateCommunityPromise,
} from "../../../store/actions";
import { loadLeafElementsForEntity } from "../../../store/actions/entity";
import { ResponsiveMode } from "../../../store/reducers/responsive";
import { clientsByCommunity } from "../../../store/selectors/selectors";
import { inAdvancedMode } from "../../../util/browser";
import { allowedRegionsForAction, isAllowed } from "../../../util/permissions";
import { isFeatureEnabled } from "../../feature";
import { ShowCommunity, Subpage } from "./index";

interface ShowCommunityFromRoutePropsFromState {
    community?: C.ICommunity;
    communityId?: CommunityId;
    subpage?: string;
    clients: C.IClient[];
    responsiveMode: ResponsiveMode;
    now: Date;
    users: { [id: string]: C.User };
    fileCount: number;
    contractsEnabled: boolean;
    newlyAddedContactPerson?: ContactId;
    showInvoicesTab: boolean;
    showAdvancedTab: boolean;
    faxEnabled: boolean;
    canUpdateCommunitySearch: boolean;
    selectedCommunityRegionId: C.RegionId | null;
    regions: C.TenantRegion[];
}

interface ShowCommunityFromRoutePropsFromDispatch {
    // eslint-disable-next-line @typescript-eslint/ban-types -- eslintintroduction
    triggerCommunityLoad?: Function;
    onFieldChange?: (
        communityId: CommunityId,
        fieldName: string,
        newValue: unknown,
        queryClient: QueryClient,
    ) => void;
    onFieldChanges?: (
        communityId: CommunityId,
        changes: Record<string, unknown>,
        queryClient: QueryClient,
    ) => void;
    onFieldChangePromise?: (
        communityId: CommunityId,
        fieldName: string,
        newValue: unknown,
        queryClient: QueryClient,
    ) => Promise<C.Community | void>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onAddPhotos?: (communityId: CommunityId, photos: File[]) => Promise<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onUpdatePhotos?: (communityId: CommunityId, photos: Partial<IFile>[]) => Promise<any>;
    onUpdatePhoto?: (
        communityId: CommunityId,
        photoId: FileId,
        newPhoto: File,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    ) => Promise<any>;
    onUpdatePhotoDescription?: (
        communityId: CommunityId,
        photoId: FileId,
        description: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    ) => Promise<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onDeletePhoto?: (communityId: CommunityId, photoId: FileId) => Promise<any>;
    onUpdateAnswer?: (
        communityId: CommunityId,
        answer: C.IAnswer,
        queryClient: QueryClient,
    ) => Promise<IAnswer>;
    navigate?: (path: string) => void;
    changeRegion: (regionKey: string) => void;
}

type ShowCommunityFromRouteProps = ShowCommunityFromRoutePropsFromState &
    ShowCommunityFromRoutePropsFromDispatch;

class ShowCommunityFromRoute extends React.Component<
    ShowCommunityFromRouteProps,
    undefined
> {
    constructor(props: ShowCommunityFromRouteProps) {
        super(props);
        this.onUpdateAnswer = this.onUpdateAnswer.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.triggerCommunityLoad(this.props.communityId);
    }

    componentDidUpdate(prevProps: Readonly<ShowCommunityFromRouteProps>): void {
        if (this.props.communityId !== prevProps.communityId) {
            this.props.triggerCommunityLoad(this.props.communityId);
        }

        if (
            this.props.selectedCommunityRegionId &&
            this.props.regions &&
            this.props.community?.regionId &&
            this.props.selectedCommunityRegionId !== this.props.community.regionId
        ) {
            const matchingCommunityRegionKey = this.props.regions.find(
                r => r.communityRegionId === this.props.community.regionId,
            )?.key;
            if (matchingCommunityRegionKey) {
                this.props.changeRegion(matchingCommunityRegionKey);
            }
        }
    }

    private triggerReload(): void {
        this.props.triggerCommunityLoad(this.props.communityId);
    }

    private onFieldChange(fieldName: string, newValue: string, queryClient: QueryClient) {
        this.props.onFieldChange(
            this.props.communityId,
            fieldName,
            newValue,
            queryClient,
        );
    }

    private onFieldChanges(
        changes: Record<string, unknown>,
        queryClient: QueryClient,
    ): void {
        this.props.onFieldChanges(this.props.communityId, changes, queryClient);
    }

    private onUpdateAnswer(
        answer: C.IAnswer,
        queryClient: QueryClient,
    ): Promise<IAnswer> {
        return this.props.onUpdateAnswer(this.props.communityId, answer, queryClient);
    }

    // this method is silly
    private activeSubpage(): Subpage {
        const { subpage } = this.props;
        if (subpage) {
            if (subpage === "details") return "details";
            if (subpage === "attributes") return "attributes";
            if (subpage === "records") return "records";
            if (subpage === "history") return "history";
            if (subpage === "tasks") return "tasks";
            if (subpage === "files") return "files";
            if (subpage === "inbound") return "inbound";
            if (subpage === "invoices") return "invoices";
            if (subpage === "outbound") return "outbound";
            if (subpage === "emails") return "emails";
            if (subpage === "advanced") return "advanced";
            if (subpage === "activity") return "activity";
        }
        return null;
    }

    private selectSubpage(section: Subpage) {
        this.props.navigate(`/communities/show/${this.props.communityId}/${section}`);
    }

    private closeSection() {
        this.props.navigate(`/communities/show/${this.props.communityId}`);
    }

    private async delete(queryClient: QueryClient) {
        await this.props.onFieldChange(
            this.props.communityId,
            "deleted",
            true,
            queryClient,
        );
        this.props.navigate("/communities");
    }

    render() {
        if (this.props.community) {
            return (
                <QueryClientShim>
                    {queryClient => (
                        <BridgeQuestionShim
                            entityName={AdvancedSearchEntityType.Community}
                            regionKeys={this.props.community.regions}>
                            {questions => (
                                <EntityTasksShim parentId={this.props.community.id}>
                                    {({ tasks, refetchTasks }) => (
                                        <ShowCommunity
                                            delete={() => this.delete(queryClient)}
                                            activeSubpage={this.activeSubpage()}
                                            selectSubpage={(section: Subpage) =>
                                                this.selectSubpage(section)
                                            }
                                            community={this.props.community}
                                            onFieldChange={(n, v) =>
                                                this.onFieldChange(n, v, queryClient)
                                            }
                                            onFieldChanges={changes =>
                                                this.onFieldChanges(changes, queryClient)
                                            }
                                            onReloadData={() => this.triggerReload()}
                                            onAddPhotos={this.props.onAddPhotos}
                                            onUpdatePhotos={this.props.onUpdatePhotos}
                                            onUpdatePhotoDescription={
                                                this.props.onUpdatePhotoDescription
                                            }
                                            onDeletePhoto={this.props.onDeletePhoto}
                                            onUpdateAnswer={a =>
                                                this.onUpdateAnswer(a, queryClient)
                                            }
                                            questions={questions}
                                            closeSection={() => this.closeSection()}
                                            clients={this.props.clients}
                                            responsiveMode={this.props.responsiveMode}
                                            users={this.props.users}
                                            now={this.props.now}
                                            fileCount={this.props.fileCount}
                                            contractsEnabled={this.props.contractsEnabled}
                                            newlyAddedContactPerson={
                                                this.props.newlyAddedContactPerson
                                            }
                                            showInvoicesTab={this.props.showInvoicesTab}
                                            showAdvancedTab={this.props.showAdvancedTab}
                                            faxEnabled={this.props.faxEnabled}
                                            canUpdateCommunitySearch={
                                                this.props.canUpdateCommunitySearch
                                            }
                                            tasks={tasks}
                                            refetchTasks={refetchTasks}
                                        />
                                    )}
                                </EntityTasksShim>
                            )}
                        </BridgeQuestionShim>
                    )}
                </QueryClientShim>
            );
        } else {
            return <div>Loading...</div>;
        }
    }
}

function mapDispatchToProps(
    dispatch: Dispatch<Action>,
): ShowCommunityFromRoutePropsFromDispatch {
    const val = {
        triggerCommunityLoad: (communityId: CommunityId) => {
            loadCommunity(communityId, dispatch);
            loadLeafElementsForEntity(communityId, dispatch);
            actions.loadClientsForCommunity(dispatch, communityId);
        },
        onFieldChange: async (
            communityId: CommunityId,
            fieldName: string,
            newValue: unknown,
            queryClient: QueryClient,
        ) => {
            await updateCommunity(
                communityId,
                fieldName,
                newValue,
                queryClient,
                dispatch,
            );
        },
        onFieldChanges: (
            communityId: CommunityId,
            changes: Record<string, unknown>,
            queryClient: QueryClient,
        ) => {
            updateCommunityFields(communityId, changes, queryClient, dispatch);
        },
        onFieldChangePromise: (
            communityId: CommunityId,
            fieldName: string,
            newValue: unknown,
            queryClient: QueryClient,
        ) => {
            return updateCommunityPromise(
                communityId,
                fieldName,
                newValue,
                queryClient,
                dispatch,
            );
        },
        onAddPhotos: (communityId: CommunityId, photos: File[]) => {
            return actions.addCommunityPhotos(communityId, photos, dispatch);
        },
        onUpdatePhotos: (communityId: CommunityId, photos: IFile[]) => {
            return actions.updateCommunityPhotos(communityId, photos, dispatch);
        },
        onUpdatePhoto: (communityId: CommunityId, photoId: FileId, newPhoto: File) => {
            return actions.updateCommunityPhoto(communityId, photoId, newPhoto, dispatch);
        },
        onUpdatePhotoDescription: (
            communityId: CommunityId,
            photoId: FileId,
            description: string,
        ) => {
            return actions.updateCommunityPhotoDescription(
                communityId,
                photoId,
                description,
                dispatch,
            );
        },
        onDeletePhoto: (communityId: CommunityId, photoId: FileId) => {
            return actions.deleteCommunityPhoto(communityId, photoId, dispatch);
        },
        updateSelectedContact: (communityId: CommunityId, contactId: ContactId) => {
            dispatch(actions.selectContact(communityId, contactId));
        },
        onUpdateAnswer: (
            communityId: CommunityId,
            answer: C.IAnswer,
            queryClient: QueryClient,
        ) => {
            return updateCommunityAnswer(
                communityId,
                answer,
                queryClient,
                dispatch,
            ) as Promise<IAnswer>;
        },
        navigate: (path: string) => {
            actions.navigate(path);
        },
        changeRegion: (regionKey: string) => {
            dispatch(createChangeRegionAction(regionKey));
        },
    };
    return val;
}

function mapStateToProps(
    state: ApplicationState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    ownProps: any,
): ShowCommunityFromRoutePropsFromState {
    const subpage = ownProps.match.params.subpage;
    const communityId = parseRouteId<CommunityId>(
        "Community",
        ownProps.match.params.communityId,
    );
    const community = state.communities.communities[communityId]
        ? C.Community.load(state.communities.communities[communityId])
        : undefined;
    const clients = clientsByCommunity(state, ownProps);
    let fileCount = 0;
    if (state.files.files && state.files.files[communityId]) {
        fileCount = state.files.files[communityId].length;
    }

    const userRegionKeys = allowedRegionsForAction(
        ActionType.UpdateCommunitySearch,
        state.permissions,
    );
    const communityRegions = state.region.regions.filter(
        r => !!community && r.communityRegionId === community.regionId,
    );
    const canUpdateCommunitySearch = communityRegions.some(r =>
        userRegionKeys.includes(r.key),
    );

    return {
        contractsEnabled: isAllowed(
            ActionType.ViewCommunityContract,
            state.permissions,
            state.region,
        ),
        clients,
        subpage,
        responsiveMode: state.responsive.mode,
        community,
        communityId: communityId,
        now: state.time.now,
        users: state.users.users,
        fileCount,
        newlyAddedContactPerson: state.selectedContacts.selections[communityId],
        showInvoicesTab: isAllowed(
            ActionType.ViewAllInvoices,
            state.permissions,
            state.region,
        ),
        showAdvancedTab: inAdvancedMode(),
        faxEnabled: isFeatureEnabled(state, "fax"),
        canUpdateCommunitySearch,
        selectedCommunityRegionId:
            state.region.regions.find(r => r.key === state.region.selectedRegion)
                ?.communityRegionId || null,
        regions: state.region.regions,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowCommunityFromRoute);
