import { SearchInput } from "components/ui/search-input";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { useFeature } from "store/selectors/hooks";

export const SearchHeader: React.FC<unknown> = props => {
    const router = useHistory();
    const enabled = useFeature("simpleSearch");
    const [search, setSearch] = React.useState("");
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
        [setSearch],
    );
    const reset = useCallback(() => setSearch(""), [setSearch]);
    const submit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            router.push(`/search?q=${search}`);
            reset();
        },
        [search, router, reset],
    );

    if (!enabled) {
        return null;
    }

    return (
        <form onSubmit={submit} className="max-w-sm">
            <SearchInput
                type="text"
                onChange={onChange}
                value={search}
                placeholder="Search"
            />
        </form>
    );
};
