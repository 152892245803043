import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { useSimpleSearchQuery } from "generated/graphql";
import React from "react";
import { useLocation } from "react-router-dom";
import { useRegionId } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";
import { Content, SectionHeader, Stage } from "../layout";

export const SimpleSearch: React.FC<unknown> = props => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const regionId = useRegionId();
    const searchResults = useSimpleSearchQuery(
        { query: { query: urlParams.get("q"), regionId } },
        stableQueryOptions(),
    );
    return (
        <Stage>
            <SectionHeader title="Search" />
            <Content>
                <QueryRenderer name="Search" query={searchResults}>
                    {data =>
                        data.simpleSearch.hits.length > 0 ? (
                            <div className="divide-y divide-gray-200 space-y-2">
                                {data.simpleSearch.hits.map(hit => (
                                    <div key={hit.entityId}>
                                        <span
                                            className={`inline-block rounded-full px-3 py-1 text-sm font-semibold text-white ${
                                                hit.type === "Community"
                                                    ? "bg-blue-500"
                                                    : "bg-green-500"
                                            } mr-2`}>
                                            {hit.type}
                                        </span>

                                        <a
                                            href={`/details/${hit.entityId}`}
                                            className="text-blue-600 hover:underline">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: hit.excerpt.replace(
                                                        /<highlight>(.*?)<\/highlight>/g,
                                                        '<span class="font-bold text-yellow-500">$1</span>',
                                                    ),
                                                }}
                                            />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div>
                                <p>No results found.</p>
                            </div>
                        )
                    }
                </QueryRenderer>
            </Content>
        </Stage>
    );
};
