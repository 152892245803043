import {
    ActionType,
    IClient,
    Maybe,
    ReferenceBusinessId,
    ReferenceMap,
} from "@sp-crm/core";
import { ReferralSourcePicker } from "components/references/select-reference/referral-source-picker";
import { IsAllowed } from "components/shared/is-allowed";
import { AutosavingInput } from "components/ui/autosaving-input";
import React, { useMemo } from "react";

interface ClientReferralSourceProps {
    client: IClient;
    onFieldChange: (fieldName: string, newValue: string) => void;
}

export const ClientReferralSource: React.FC<ClientReferralSourceProps> = props => {
    const { client, onFieldChange } = props;

    const referralSourceMap: ReferenceMap = useMemo(() => {
        if (
            client.referralSourceMap &&
            (client.referralSourceMap.business.hasValue ||
                client.referralSourceMap.contact.hasValue ||
                client.referralSourceMap.identifier.hasValue)
        ) {
            return client.referralSourceMap;
        }
        if (client.referralContactId || client.referralBusinessId) {
            return {
                identifier: Maybe.none(),
                contact: Maybe.fromValue(client.referralContactId),
                business: Maybe.fromValue(
                    client.referralBusinessId as ReferenceBusinessId,
                ),
            };
        }
        return client.referralSourceMap;
    }, [client]);

    const showNotes = useMemo(() => {
        return (
            client.referralSourceId ||
            client.referralContactId ||
            client.referralBusinessId ||
            typeof client.referralNotes === "string"
        );
    }, [client]);

    return (
        <IsAllowed actionType={ActionType.ViewReference}>
            <div className="input-form-block-no-bottom-margin">
                <div className="mb-2">
                    <ReferralSourcePicker
                        clientId={client.id}
                        value={referralSourceMap}
                        onChange={(newValue: ReferenceMap) => {
                            const valueAsString = [
                                newValue.identifier.getOrElse(""),
                                newValue.business.getOrElse(""),
                                newValue.contact.getOrElse(""),
                            ].join(";");

                            onFieldChange("referralSourceId", valueAsString);
                        }}
                    />
                </div>
                {showNotes ? (
                    <div className="form-group">
                        <AutosavingInput
                            multiLine={true}
                            label="Referral Notes (optional)"
                            initial={client.referralNotes}
                            onCommit={newVal => onFieldChange("referralNotes", newVal)}
                        />
                    </div>
                ) : null}
            </div>
        </IsAllowed>
    );
};
