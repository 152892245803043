import { QuestionType } from "@sp-crm/core";
import { countFiltersApplied } from "components/advanced-search/advanced-search-utility";
import { SearchbarItemBadge } from "components/community-search/search-bar/searchbar-item-badge";
import { Icon } from "components/icon";
import { EntityFilters } from "components/shared/entity-filters";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { SearchInput } from "components/ui/search-input";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    AdvancedSearchCondition,
    AdvancedSearchEntityType,
    GetLayoutContainersByKeyQuery,
    LayoutSectionParentKey,
    useGetLayoutContainersByKeyQuery,
} from "generated/graphql";
import React, { useMemo, useState } from "react";
import { useBridgeEntityType, useBridgeTypes } from "store/selectors/bridge";
import { stableQueryOptions } from "util/requests";

export interface EntityMoreFilterValue {
    condition: AdvancedSearchCondition | null;
    entityType: string;
    layoutContainerKeys: LayoutSectionParentKey[];
    label: string;
    icon: string;
}

interface EntityMoreFilterProps {
    conditions: EntityMoreFilterValue[];
    onConditionChange: (
        entityType: string,
        condition: AdvancedSearchCondition | null,
    ) => void;
    label: string;
}

export const EntityMoreFilter: React.FC<EntityMoreFilterProps> = props => {
    const { conditions, onConditionChange, label } = props;

    const bridgeTypes = useBridgeTypes();
    const layoutContainerKeys = useMemo(() => {
        return conditions.flatMap(c => c.layoutContainerKeys);
    }, [conditions]);

    const layoutQuery = useGetLayoutContainersByKeyQuery(
        { keys: layoutContainerKeys },
        { ...stableQueryOptions() },
    );
    const [showPanel, setShowPanel] = useState(false);
    const [searchText, setSearchText] = useState("");

    const entityQuestions = useMemo(() => {
        return (
            bridgeTypes.entityTypes
                ?.filter(t => conditions.some(c => c.entityType === t.name))
                .flatMap(t => t.questions) ?? []
        );
    }, [bridgeTypes.entityTypes, conditions]);

    const hasRelevantQuestions = useMemo(() => {
        return (
            entityQuestions?.length > 0 &&
            layoutQuery.data?.getLayoutContainersByKey?.some(container =>
                container.layoutSections.some(
                    section =>
                        section.layoutItems?.length > 0 &&
                        section.layoutItems?.some(layoutItem => {
                            const question = entityQuestions.find(
                                q => q.id === layoutItem.questionId,
                            );
                            return (
                                question?.questionType ===
                                    QuestionType[QuestionType.nary] ||
                                question?.questionType === QuestionType[QuestionType.user]
                            );
                        }),
                ),
            )
        );
    }, [entityQuestions, layoutQuery.data]);

    const badgeCount = useMemo(() => {
        return conditions
            .map(c => countFiltersApplied(c.condition))
            .reduce((a, b) => a + b, 0);
    }, [conditions]);

    if (hasRelevantQuestions) {
        return (
            <>
                <div
                    className="flex items-center space-x-1 cursor-pointer text-gray-700"
                    onClick={e => {
                        e.preventDefault();
                        setShowPanel(true);
                    }}>
                    <div className="text-lg">{label}</div>
                    {badgeCount > 0 ? <SearchbarItemBadge counter={badgeCount} /> : null}
                </div>
                <Panel
                    headerText={label}
                    type={PanelType.large}
                    isOpen={showPanel}
                    onDismiss={() => {
                        setShowPanel(false);
                        setSearchText("");
                    }}>
                    <div>
                        <div className="flex space-x-2 items-end justify-between">
                            <div className="flex-1">
                                <SearchInput
                                    value={searchText}
                                    onChange={e => setSearchText(e.target.value)}
                                    label="Search for filter"
                                />
                            </div>
                            <div>
                                <SecondaryButton
                                    onClick={() => setSearchText("")}
                                    disabled={!searchText}>
                                    Clear
                                </SecondaryButton>
                            </div>
                        </div>
                        {conditions.map(c => (
                            <EntityMoreFilterSection
                                key={c.entityType}
                                entityType={c.entityType as AdvancedSearchEntityType}
                                condition={c.condition}
                                icon={c.icon}
                                label={c.label}
                                onConditionChange={onConditionChange}
                                searchText={searchText}
                                layoutContainers={(
                                    layoutQuery.data?.getLayoutContainersByKey ?? []
                                ).filter(lc =>
                                    c.layoutContainerKeys.includes(
                                        lc.key as LayoutSectionParentKey,
                                    ),
                                )}
                            />
                        ))}
                    </div>
                </Panel>
            </>
        );
    }

    return null;
};

interface EntityMoreFilterSectionProps {
    entityType: AdvancedSearchEntityType;
    condition: AdvancedSearchCondition;
    icon: string;
    label: string;
    onConditionChange: (
        entityType: string,
        condition: AdvancedSearchCondition | null,
    ) => void;
    searchText: string;
    layoutContainers: GetLayoutContainersByKeyQuery["getLayoutContainersByKey"];
}

export const EntityMoreFilterSection: React.FC<EntityMoreFilterSectionProps> = props => {
    const {
        condition,
        entityType,
        icon,
        label,
        onConditionChange,
        searchText,
        layoutContainers,
    } = props;

    const { entityType: bridgeType } = useBridgeEntityType(entityType);

    return (
        <div>
            <h3 className="twoverride text-gray-900 text-lg py-4">
                <div className="flex items-center space-x-2">
                    <div className="w-8">
                        <Icon name={icon} />
                    </div>
                    <p>{label}</p>
                </div>
            </h3>
            <EntityFilters
                entityMetadata={bridgeType}
                layoutSections={layoutContainers.flatMap(lc => lc.layoutSections)}
                condition={condition}
                onChange={newCondition => onConditionChange(entityType, newCondition)}
                searchText={searchText}
            />
        </div>
    );
};
