import { BookmarkIcon } from "@heroicons/react/24/outline";
import { ActionType, RegionId } from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import { useRefreshCustomQueries } from "components/reports/show/custom-reports/custom-reports-helpers";
import { DeleteButton } from "components/ui/action-button";
import { Checkbox } from "components/ui/checkbox";
import { fancyAlert } from "components/ui/fancy-alert";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { fancyPrompt, FancyPromptBehavior } from "components/ui/fancy-prompt";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { RegionAccessInput } from "components/ui/region-access-input";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import {
    AdvancedSearchRequest,
    GetSavedQueriesQuery,
    useCreateSavedQueryMutation,
    useDeleteSavedQueryMutation,
    useGetSavedQueryFoldersQuery,
    useUpdateSavedQueryMutation,
} from "generated/graphql";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUsers } from "store/selectors/hooks";
import { useEntityRegions } from "store/selectors/regions";

// Special option values for the folder select
const NONE_FOLDER_VALUE = "__none__";
const NEW_FOLDER_VALUE = "__new__";

interface FolderSelectProps {
    value: string | null;
    onChange: (value: string | null) => void;
    isShared: boolean;
    editable?: boolean;
}

const FolderSelect: React.FC<FolderSelectProps> = ({
    value,
    onChange,
    isShared,
    editable = true,
}) => {
    const foldersQuery = useGetSavedQueryFoldersQuery();
    const [isCreatingNewFolder, setIsCreatingNewFolder] = useState(false);
    const [lastCreatedFolder, setLastCreatedFolder] = useState<string | null>(null);

    const folderOptions = useMemo(() => {
        if (!foldersQuery.data) return [];

        // Filter folders based on shared status
        const filteredFolders = foldersQuery.data.getSavedQueries.savedQueries
            .filter(query => query.isShared === isShared && query.folderName)
            .map(query => query.folderName)
            .filter((name): name is string => name !== null);

        // Get unique folder names and sort alphabetically
        const uniqueFolders = Array.from(new Set(filteredFolders)).sort((a, b) =>
            a.localeCompare(b, undefined, { sensitivity: "base" }),
        );

        // Add the current value and last created folder if they're not in the list
        const valuesToAdd = [value, lastCreatedFolder]
            .filter(
                (v): v is string =>
                    !!v &&
                    !uniqueFolders.includes(v) &&
                    v !== NONE_FOLDER_VALUE &&
                    v !== NEW_FOLDER_VALUE,
            )
            .reduce((acc, v) => {
                if (!acc.includes(v)) {
                    acc.push(v);
                }
                return acc;
            }, []);

        if (valuesToAdd.length > 0) {
            uniqueFolders.push(...valuesToAdd);
            uniqueFolders.sort((a, b) =>
                a.localeCompare(b, undefined, { sensitivity: "base" }),
            );
        }

        return uniqueFolders;
    }, [foldersQuery.data, isShared, value, lastCreatedFolder]);

    const handleChange = useCallback(
        async (e: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedValue = e.target.value;

            if (selectedValue === NEW_FOLDER_VALUE) {
                // Set flag that we're creating a new folder
                setIsCreatingNewFolder(true);

                // Show dialog to create a new folder
                const newFolderName = await fancyPrompt(
                    "Create New Folder",
                    "Enter a name for the new folder",
                    FancyPromptBehavior.OkCancel,
                );

                if (newFolderName && newFolderName.trim() !== "") {
                    const trimmedName = newFolderName.trim();
                    // Store the created folder name locally to ensure UI consistency
                    setLastCreatedFolder(trimmedName);
                    // Notify parent component
                    onChange(trimmedName);
                }

                // Done creating, clear flag
                setIsCreatingNewFolder(false);
            } else if (selectedValue === NONE_FOLDER_VALUE) {
                onChange(null);
            } else {
                onChange(selectedValue);
            }
        },
        [onChange],
    );

    // Always show the current value in the select when not creating a new folder
    // Prioritize lastCreatedFolder over value from parent since it's more up-to-date
    const effectiveValue = lastCreatedFolder !== null ? lastCreatedFolder : value;
    const selectValue = isCreatingNewFolder
        ? NEW_FOLDER_VALUE
        : effectiveValue || NONE_FOLDER_VALUE;

    return (
        <Select
            label="Folder"
            value={selectValue}
            onChange={handleChange}
            disabled={!editable}>
            <option value={NONE_FOLDER_VALUE}>None</option>
            <option value={NEW_FOLDER_VALUE}>New folder...</option>
            {folderOptions.map(folder => (
                <option key={folder} value={folder}>
                    {folder}
                </option>
            ))}
        </Select>
    );
};

interface AdvancedSearchSaveExistingProps {
    report: GetSavedQueriesQuery["getSavedQueries"]["savedQueries"][0];
    searchRequest: AdvancedSearchRequest;
}

export const AdvancedSearchSaveExisting: React.FC<
    AdvancedSearchSaveExistingProps
> = props => {
    const { report, searchRequest } = props;
    const updateMutation = useUpdateSavedQueryMutation();
    const deleteMutation = useDeleteSavedQueryMutation();
    const { push } = useHistory();

    const refresh = useRefreshCustomQueries();
    const [queryName, setQueryName] = useState(report.name);
    const [saveQueryUiVisible, setSaveQueryUiVisible] = useState(false);
    const [isShared, setIsShared] = useState(report.isShared);
    const [folderName, setFolderName] = useState(report.folderName);
    const [regions, setRegions] = useState<RegionId[]>(report.regionVisibility.regions);
    const toggleSaveQueryUi = useCallback(
        (
            e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
        ) => {
            e && e.preventDefault && e.preventDefault();
            setSaveQueryUiVisible(x => !x);
        },
        [],
    );
    const saveReport = useCallback(async () => {
        if (!queryName || queryName.trim() === "") {
            await fancyAlert("Name missing", "Please enter a name for the report");
            return;
        }
        const savedQuery = {
            name: queryName,
            id: report.id,
            condition: searchRequest.condition,
            sort: searchRequest.sort,
            isShared,
            regionVisibility: isShared ? { regions } : null,
            entityType: searchRequest.entityType,
            select: searchRequest.select,
            summaries: searchRequest.summaries,
            folderName,
        };
        await updateMutation.mutateAsync({ savedQuery: savedQuery });
        toggleSaveQueryUi(null);
        refresh();
    }, [
        updateMutation,
        searchRequest,
        report,
        isShared,
        refresh,
        queryName,
        toggleSaveQueryUi,
        regions,
        folderName,
    ]);

    const handleSubmit = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            await saveReport();
        },
        [saveReport],
    );
    const deleteReport = useCallback(async () => {
        const confirmed = await fancyConfirm(
            "Delete report",
            "Are you sure you want to delete this report?",
            "Yes, delete",
            "Cancel",
        );
        if (!confirmed) return;
        await deleteMutation.mutateAsync({ savedQueryId: report.id });
        refresh();
        push(`/reports/show/custom/new`);
    }, [refresh, push, deleteMutation, report]);

    const users = useUsers().users;

    const ownerName = users[report.createdBy]?.name || "another user";

    if (!saveQueryUiVisible) {
        return (
            <div className="flex space-x-4 items-center">
                <SecondaryButton disabled={!report.editable} onClick={toggleSaveQueryUi}>
                    Save
                </SecondaryButton>
                {report.editable ? (
                    <DeleteButton backgroundColor="bg-gray-200" onClick={deleteReport} />
                ) : (
                    <InlineBanner type="info">
                        This query is read-only because it is owned by {ownerName}
                    </InlineBanner>
                )}
            </div>
        );
    }
    return (
        <div className="space-y-4 p-4">
            <div>
                <Input
                    label="Name"
                    value={queryName}
                    onChange={e => setQueryName(e.target.value)}
                />
            </div>
            <div>
                <FolderSelect
                    value={folderName}
                    onChange={setFolderName}
                    isShared={isShared}
                    editable={report.editable}
                />
            </div>
            <div>
                <Checkbox
                    label="Share query with others at my company"
                    checked={isShared}
                    onChange={e => setIsShared(e.target.checked)}
                />
            </div>
            {isShared ? (
                <RegionAccessInput
                    editable={report.editable}
                    actionType={ActionType.Noop}
                    label="Region visibility"
                    onChange={setRegions}
                    regions={regions}
                />
            ) : null}
            <div className="flex space-x-4 items-center">
                <PrimaryButton
                    type="button"
                    onClick={handleSubmit}
                    disabled={regions.length === 0}>
                    Save
                </PrimaryButton>
                <SecondaryButton type="button" onClick={toggleSaveQueryUi}>
                    Cancel
                </SecondaryButton>
            </div>
        </div>
    );
};

interface AdvancedSearchSaveNewProps {
    searchRequest: AdvancedSearchRequest;
    canSaveAdditional: boolean;
}

export const AdvancedSearchSaveNew: React.FC<AdvancedSearchSaveNewProps> = props => {
    const { searchRequest, canSaveAdditional } = props;
    const { push } = useHistory();
    const refresh = useRefreshCustomQueries();
    const createMutation = useCreateSavedQueryMutation();
    const [queryName, setQueryName] = useState("");
    const [saveQueryUiVisible, setSaveQueryUiVisible] = useState(false);
    const [isShared, setIsShared] = useState(false);
    const [folderName, setFolderName] = useState<string | null>(null);

    const entityRegions = useEntityRegions({ actionType: ActionType.Noop });

    const [manualRegions, setManualRegions] = useState<RegionId[] | null>(null);

    const regions = useMemo(() => {
        return manualRegions || entityRegions.map(r => r.id);
    }, [entityRegions, manualRegions]);

    const toggleSaveQueryUi = useCallback(
        (
            e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
        ) => {
            e && e.preventDefault && e.preventDefault();
            setSaveQueryUiVisible(x => !x);
        },
        [setSaveQueryUiVisible],
    );
    const saveReport = useCallback(async () => {
        if (!queryName || queryName.trim() === "") {
            await fancyAlert("Name missing", "Please enter a name for the report");
            return;
        }
        const savedQuery = {
            name: queryName,
            condition: searchRequest.condition,
            sort: searchRequest.sort,
            isShared,
            regionVisibility: isShared ? { regions } : null,
            entityType: searchRequest.entityType,
            select: searchRequest.select,
            summaries: searchRequest.summaries,
            folderName,
        };
        const result = await createMutation.mutateAsync({ savedQuery: savedQuery });
        push(`/reports/show/custom/${result.createSavedQuery.id}`);
        refresh();
    }, [
        createMutation,
        searchRequest,
        isShared,
        push,
        refresh,
        queryName,
        regions,
        folderName,
    ]);

    const handleSubmit = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            await saveReport();
        },
        [saveReport],
    );

    if (!saveQueryUiVisible) {
        return (
            <SecondaryButton
                onClick={toggleSaveQueryUi}
                className="flex items-center space-x-2">
                <BookmarkIcon className="w-6 h-6" />
                <div>Save report</div>
            </SecondaryButton>
        );
    }
    if (!canSaveAdditional) {
        return (
            <div className="flex space-x-4 items-center">
                <SecondaryButton
                    disabled={!canSaveAdditional}
                    onClick={toggleSaveQueryUi}>
                    Save
                </SecondaryButton>
                <InlineBanner type="info">
                    You have reached the maximum number of saved reports. Please contact
                    your administrator or <SupportEmailLink /> to upgrade to a higher
                    plan.
                </InlineBanner>
            </div>
        );
    }
    return (
        <div className="space-y-4 p-4">
            <div>
                <Input
                    label="Name"
                    value={queryName}
                    onChange={e => setQueryName(e.target.value)}
                />
            </div>
            <div>
                <FolderSelect
                    value={folderName}
                    onChange={setFolderName}
                    isShared={isShared}
                />
            </div>
            <div>
                <Checkbox
                    label="Share query with others at my company"
                    checked={isShared}
                    onChange={e => setIsShared(e.target.checked)}
                />
            </div>
            {isShared ? (
                <RegionAccessInput
                    editable={true}
                    actionType={ActionType.Noop}
                    label="Office visibility"
                    regions={regions}
                    onChange={setManualRegions}
                />
            ) : null}
            <div className="flex space-x-4 items-center">
                <PrimaryButton
                    type="button"
                    onClick={handleSubmit}
                    disabled={regions.length === 0}>
                    Save
                </PrimaryButton>
                <SecondaryButton type="button" onClick={toggleSaveQueryUi}>
                    Cancel
                </SecondaryButton>
            </div>
        </div>
    );
};
