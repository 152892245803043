import { AnswerEntityId, EmailTemplateId, TokenPrefix } from "@sp-crm/core";
import { Template, buildInitialEmailContent } from "components/editor/template";
import React, {
    ForwardRefExoticComponent,
    RefAttributes,
    forwardRef,
    useEffect,
} from "react";
import { useBridgeTokens } from "store/selectors/bridge";
import {
    AdvancedSearchEntityType,
    useComposeMessageBasicQuery,
} from "../../generated/graphql";
import { Editor, PlacementEditor } from "../editor/editor";
import { getSignatureTemplates, getTemplatesByType } from "../editor/upgrade-template";
import { ErrorMessage } from "../ui/error-message";
import { Spinner } from "../ui/spinner";

interface MessageComposeBasicProps {
    initialTemplateId?: EmailTemplateId | null;
    onTemplateInserted: (template: Template) => void;
    entityType: AdvancedSearchEntityType;
    entityId: AnswerEntityId;
    preserveTokens?: TokenPrefix[];
}

const MessageComposeBasic: ForwardRefExoticComponent<
    MessageComposeBasicProps & RefAttributes<PlacementEditor>
> = forwardRef<PlacementEditor, MessageComposeBasicProps>((props, ref) => {
    const { initialTemplateId, onTemplateInserted, entityType, entityId } = props;
    const query = useComposeMessageBasicQuery();

    const { tokenReplacements, addToken } = useBridgeTokens(entityType, entityId);

    useEffect(() => {
        if (initialTemplateId && query.data) {
            const initialTemplate = getTemplatesByType(
                query.data.getTemplates.templates,
                ["insertable", "setting"],
            ).find(t => t.id === initialTemplateId);

            if (initialTemplate && onTemplateInserted) {
                onTemplateInserted(initialTemplate);
            }
        }
    }, [initialTemplateId, query.data?.getTemplates.templates]); // eslint-disable-line react-hooks/exhaustive-deps

    if (query.isLoading) {
        return <Spinner />;
    }
    if (query.isError) {
        return (
            <ErrorMessage component="MessageComposeBasic">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (!query.data) {
        return <Spinner />;
    }
    const templates = getTemplatesByType(query.data.getTemplates.templates, [
        "insertable",
        "setting",
    ]);
    const signatures = getSignatureTemplates(query.data.getTemplates.templates);

    const initialTemplate = initialTemplateId
        ? templates.find(t => t.id === initialTemplateId)
        : null;
    const defaultSignature = signatures.find(s => s.isDefaultSignature) || null;
    let initialContent = "";

    if (initialTemplate || defaultSignature) {
        initialContent = buildInitialEmailContent(initialTemplate, defaultSignature);
    }

    return (
        <Editor
            ref={ref}
            signatures={signatures}
            templates={templates.filter(t => t.type === "insertable")}
            initialContent={initialContent}
            onTemplateInserted={onTemplateInserted}
            placeholderTypes={[entityType]}
            tokenReplacements={tokenReplacements}
            onTokenInserted={addToken}
            preserveTokens={props.preserveTokens}
        />
    );
});

MessageComposeBasic.displayName = "MessageComposeBasic";
export { MessageComposeBasic };
