import { UserCircleIcon } from "@heroicons/react/20/solid";
import { Upload } from "components/files/upload";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { GetUserProfileQuery } from "generated/graphql";
import React from "react";
import { useRegionId } from "store/selectors/hooks";

interface ProfilePictureProps {
    userProfile: GetUserProfileQuery["getUserProfile"];
    reload: () => void;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = props => {
    const { userProfile, reload } = props;

    return (
        <div>
            <p>Profile Picture</p>
            {userProfile.pictureUrl ? (
                <img
                    className="h-32 w-32 flex-shrink-0 rounded-md"
                    src={props.userProfile.pictureUrl}
                    alt="Profile"
                />
            ) : (
                <UserCircleIcon className="h-32 w-32 flex-shrink-0 rounded-md text-gray-200" />
            )}
            <ProfilePictureUpload reload={reload} />
        </div>
    );
};

interface ProfilePictureUploadProps {
    reload: () => void;
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = props => {
    const { reload } = props;
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [successMessage, setSuccess] = React.useState<string | null>(null);
    const [errorMessage, setError] = React.useState<string | null>(null);

    const showModal = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        if (e.preventDefault) e.preventDefault();
        setModalVisible(true);
    }, []);

    const hideModal = React.useCallback(() => {
        setModalVisible(false);
    }, []);

    const onComplete = React.useCallback(() => {
        setSuccess("Success");
        setError(null);
        reload();
        setTimeout(hideModal, 2 * 1000);
    }, [reload, hideModal]);

    const regionId = useRegionId();

    return (
        <>
            <div>
                <a
                    href="#"
                    className="text-sm text-gray-400 hover:underline"
                    onClick={showModal}>
                    Change profile picture
                </a>
            </div>
            <Panel
                type={PanelType.large}
                headerText="Upload photo"
                isOpen={isModalVisible}
                onDismiss={hideModal}>
                <Upload
                    onComplete={onComplete}
                    onError={setError}
                    endpoint={`/api/user/profile/picture`}
                    accept="image/*"
                    regionId={regionId}
                />
                {successMessage ? (
                    <div className="text-green-600">{successMessage}</div>
                ) : null}
                {errorMessage ? <div className="text-red-600">{errorMessage}</div> : null}
            </Panel>
        </>
    );
};
