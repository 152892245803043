import { SignatureRequestId, formatDate } from "@sp-crm/core";
import { CrmTable, CrmTableProps } from "components/table/crm-table";
import { CrmTableColumn } from "components/table/types";
import { defaultLinkStyle } from "components/ui/link";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import {
    GetAllSignatureRequestsQuery,
    GetSignatureRequestsForEntityQuery,
} from "generated/graphql";
import React from "react";
import { Link } from "react-router-dom";
import { SignatureRequestExisting } from "./signature-request-existing";
import { SignatureRequestRecipients } from "./signature-request-recipients";
import { SignatureRequestStateText } from "./signature-request-state-text";

type SingleSignatureRequest =
    GetSignatureRequestsForEntityQuery["getSignatureRequestsForEntity"][0];
type AllSignatureRequest =
    GetAllSignatureRequestsQuery["getAllSignatureRequests"]["signatureRequests"][0]["signatureRequest"];

type SignatureRequest = SingleSignatureRequest | AllSignatureRequest;
type SignatureRequestPayload = SignatureRequest & {
    entity?: GetAllSignatureRequestsQuery["getAllSignatureRequests"]["signatureRequests"][0]["entity"];
};

interface SignatureRequestListProps {
    signatureRequests: SignatureRequestPayload[];
    refetchSignatures: () => void;
}

export const SignatureRequestList: React.FC<SignatureRequestListProps> = props => {
    const { signatureRequests, refetchSignatures } = props;

    const includeEntityColumn = signatureRequests.some(r => !!r.entity);

    const [selectedRequestId, setSelectedRequestId] =
        React.useState<SignatureRequestId | null>(null);

    const columns: CrmTableProps<SignatureRequestPayload>["columns"] =
        React.useMemo(() => {
            const candidates: CrmTableColumn<SignatureRequestPayload>[] = [
                {
                    key: "filename",
                    header: "Template",
                    renderCell: ({ record }) => {
                        return (
                            <a
                                className={defaultLinkStyle}
                                href="#"
                                onClick={e => {
                                    e.preventDefault();
                                    setSelectedRequestId(record.id);
                                }}>
                                {record.filename}
                            </a>
                        );
                    },
                },
                includeEntityColumn
                    ? {
                          key: "entity",
                          header: "For",
                          enableSorting: false,
                          renderCell: ({ record }) => {
                              return (
                                  <Link
                                      to={`${record.entity.path}/files`}
                                      className={defaultLinkStyle}>
                                      {record.entity.displayName || "(no name)"}
                                  </Link>
                              );
                          },
                      }
                    : null,
                {
                    key: "state",
                    header: "Status",
                    renderCell: ({ record }) => {
                        return <SignatureRequestStateText state={record.state} />;
                    },
                },
                {
                    key: "signatureRequestRecipients",
                    header: "Recipients",
                    enableSorting: false,
                    renderCell: ({ record }) => {
                        return (
                            <SignatureRequestRecipients
                                request={record}
                                onRefetch={refetchSignatures}
                            />
                        );
                    },
                },
                {
                    key: "createdAt",
                    header: "Sent",
                    renderCell: ({ record }) => (
                        <span>{formatDate(record.createdAt)}</span>
                    ),
                },
            ];

            return candidates.filter(Boolean);
        }, [refetchSignatures, includeEntityColumn]);

    return (
        <div>
            <CrmTable data={signatureRequests} columns={columns} />
            <Panel
                headerText="Signature Request Details"
                type={PanelType.extraLarge}
                isOpen={!!selectedRequestId}
                onDismiss={() => {
                    setSelectedRequestId(null);
                }}>
                {selectedRequestId ? (
                    <SignatureRequestExisting
                        signatureRequestId={selectedRequestId}
                        onRefetch={refetchSignatures}
                    />
                ) : null}
            </Panel>
        </div>
    );
};
