import {
    ActionType,
    CalendarDate,
    ClientId,
    CurrencyInCents,
    formatCurrency,
    genEntityId,
} from "@sp-crm/core";
import { DeleteButton } from "components/ui/action-button";
import { Checkbox } from "components/ui/checkbox";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { TextArea } from "components/ui/textarea";
import { inputClassNames } from "components/ui/utility";
import { Field, FieldArray, Form, Formik, FormikHelpers } from "formik";
import {
    ExternalInvoiceSearchHit,
    ExternalInvoicesCreateRequest,
    useAddExistingExternalInvoiceMutation,
    useCreateExternalInvoicesMutation,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { useSupportEmailAddress } from "store/selectors/branding";
import { useIsAllowed } from "store/selectors/hooks";
import { InvoiceInput } from "../clients/invoices";
import { PrimaryButton } from "../ui/primary-button";
import { ExternalInvoiceSearch } from "./external-invoice-search";
import { ExternalInvoiceCustomer, InvoiceCustomerField } from "./invoice-customer-field";
import { InvoiceDueDateAndTerms } from "./invoice-due-date-and-terms";
import { InvoiceRecipientsField } from "./invoice-recipients-field";

interface Props {
    clientId: ClientId;
    invoiceInput: InvoiceInput;
    refetch: () => void;
    defaultDueDate: CalendarDate | null;
}

interface LineItem {
    description: string;
    unitPrice: number;
}

interface Invoice {
    dueDate: string;
    lineItems: LineItem[];
    recipients?: string[];
    sendOnSave: boolean;
    message: string;
    termId?: string;
    customer: ExternalInvoiceCustomer | null;
}

interface FormValues {
    invoices: Invoice[];
}

const generateInitialFormValues = (defaultDueDate: CalendarDate | null): FormValues => {
    return {
        invoices: [
            {
                dueDate: defaultDueDate?.humanFriendly() ?? "",
                lineItems: [
                    {
                        description: "",
                        unitPrice: 0,
                    },
                ],
                sendOnSave: true,
                message: "",
                customer: null,
            },
        ],
    };
};

const getSaveErrorMessageFromError = (
    e: Record<string, unknown> | null,
    supportEmailAddress: string,
): string => {
    if (typeof e?.message === "string") {
        return e.message;
    }

    return `An error occurred while saving the invoice. If this issue persists, please contact ${supportEmailAddress}`;
};

const ensureFloat = (value: number | string): number => {
    if (typeof value === "number") {
        return value;
    }

    const parsed = parseFloat(value);

    return isNaN(parsed) ? 0 : parsed;
};

const validate = (params: ExternalInvoicesCreateRequest) => {
    const indexWithoutCustomer = params.invoices.findIndex(i => !i.customerId);
    if (indexWithoutCustomer >= 0) {
        throw new Error(
            `Invoice ${
                indexWithoutCustomer + 1
            } does not have a customer selected. Choose or create a customer for this invoice and try again`,
        );
    }
};

export const InvoiceCreateExternal = (props: Props) => {
    const { clientId, invoiceInput, refetch, defaultDueDate } = props;
    const clientLinkedCorrectly = !!invoiceInput.communityName;
    const [isCreateOpen, setIsCreateOpen] = React.useState(false);
    const openCreate = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsCreateOpen(true);
    }, []);
    const closeCreate = useCallback(async (isFormDirty: boolean) => {
        if (isFormDirty) {
            const shouldClose = await fancyConfirm(
                "Cancel creating invoice?",
                "Are you sure you want to cancel creating this invoice?",
                "Yes, cancel",
                "No, keep editing",
            );
            if (shouldClose) {
                setIsCreateOpen(false);
                setSaveError(null);
            }
        } else {
            setIsCreateOpen(false);
        }
    }, []);
    const [isAddExistingOpen, setIsAddExistingOpen] = React.useState(false);
    const openAddExisting = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsAddExistingOpen(true);
    }, []);
    const closeAddExising = useCallback(async () => {
        setIsAddExistingOpen(false);
    }, []);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [saveError, setSaveError] = React.useState<string | null>(null);
    const [addExistingError, setAddExistingError] = React.useState<string | null>(null);
    const createExternalInvoices = useCreateExternalInvoicesMutation();
    const addExistingInvoiceMutation = useAddExistingExternalInvoiceMutation();
    const canAddExisting = useIsAllowed(ActionType.AddExistingExternalInvoice);
    const supportEmailAddress = useSupportEmailAddress();
    const save = useCallback(
        async (input: FormValues, helpers: FormikHelpers<FormValues>) => {
            setIsSubmitting(true);
            const params: ExternalInvoicesCreateRequest = {
                clientId,
                invoices: input.invoices.map(invoice => ({
                    dueDate: invoice.dueDate,
                    lineItems: invoice.lineItems.map(lineItem => ({
                        id: genEntityId(),
                        description: lineItem.description,
                        quantity: 1,
                        unitPriceInCents: (lineItem.unitPrice * 100) as CurrencyInCents,
                    })),
                    recipients: invoice.recipients,
                    sendOnSave: invoice.sendOnSave,
                    message: invoice.message,
                    termId: invoice.termId,
                    customerId: invoice.customer?.id,
                })),
            };
            try {
                validate(params);
                await createExternalInvoices.mutateAsync({ params });
                helpers.resetForm();
                refetch();
                setSaveError(null);
                setIsCreateOpen(false);
                setIsSubmitting(false);
            } catch (e) {
                setIsSubmitting(false);
                setSaveError(getSaveErrorMessageFromError(e, supportEmailAddress));
            }
        },
        [
            setIsCreateOpen,
            setIsSubmitting,
            refetch,
            clientId,
            setSaveError,
            createExternalInvoices,
            supportEmailAddress,
        ],
    );

    const addExistingInvoice = useCallback(
        async (externalInvoiceId: string) => {
            try {
                setAddExistingError(null);
                await addExistingInvoiceMutation.mutateAsync({
                    params: {
                        clientId,
                        externalInvoiceId,
                    },
                });
                refetch();
                setIsAddExistingOpen(false);
            } catch (e) {
                setAddExistingError(getSaveErrorMessageFromError(e, supportEmailAddress));
            }
        },
        [clientId, addExistingInvoiceMutation, refetch, supportEmailAddress],
    );

    const initialFormValues: FormValues = useMemo(
        () => generateInitialFormValues(defaultDueDate),
        [defaultDueDate],
    );

    const createInitialInvoiceValues = (seedInvoice: Invoice): Invoice => ({
        dueDate: seedInvoice.dueDate,
        lineItems: [...seedInvoice.lineItems],
        recipients: [...seedInvoice.recipients],
        sendOnSave: seedInvoice.sendOnSave,
        message: seedInvoice.message,
        termId: seedInvoice.termId,
        customer: seedInvoice.customer ? { ...seedInvoice.customer } : null,
    });
    const createInitialLineItemValues = (): LineItem => ({
        description: "",
        unitPrice: 0,
    });
    if (!clientLinkedCorrectly) {
        return (
            <InlineBanner type="info">
                To create a QuickBooks invoice, first select the community.
            </InlineBanner>
        );
    }
    return (
        <>
            <div className="flex items-center space-x-4">
                <PrimaryButton onClick={openCreate}>Create new invoice</PrimaryButton>
                {canAddExisting ? (
                    <SecondaryButton onClick={openAddExisting}>
                        Add existing invoice
                    </SecondaryButton>
                ) : null}
            </div>
            <Formik<FormValues> initialValues={initialFormValues} onSubmit={save}>
                {formValues => (
                    <Panel
                        headerText="New QuickBooks Invoice"
                        isOpen={isCreateOpen}
                        onDismiss={() => closeCreate(formValues.dirty)}
                        type={PanelType.extraLarge}>
                        <Form>
                            <FieldArray name="invoices">
                                {({ insert, remove, replace }) => (
                                    <div className="space-y-4">
                                        {formValues.values.invoices.map(
                                            (invoice, index) => (
                                                <div
                                                    key={index}
                                                    className="rounded p-4 bg-gray-100 space-y-2">
                                                    {formValues.values.invoices.length >
                                                    1 ? (
                                                        <div className="flex space-x-2">
                                                            <h2 className="text-lg twoverride">
                                                                Invoice {index + 1}
                                                            </h2>
                                                            {index > 0 ? (
                                                                <DeleteButton
                                                                    backgroundColor="bg-gray-100"
                                                                    confirm={{
                                                                        title: "Remove Invoice",
                                                                        message:
                                                                            "Are you sure you want to remove this invoice?",
                                                                    }}
                                                                    onClick={() =>
                                                                        remove(index)
                                                                    }
                                                                />
                                                            ) : null}
                                                        </div>
                                                    ) : null}
                                                    <div>
                                                        <InvoiceCustomerField
                                                            clientId={clientId}
                                                            initial={invoice.customer}
                                                            onChange={c => {
                                                                replace(index, {
                                                                    ...invoice,
                                                                    customer: c,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    {invoice.customer ? (
                                                        <>
                                                            <div>
                                                                <InvoiceRecipientsField
                                                                    clientId={clientId}
                                                                    initial={
                                                                        invoice.recipients
                                                                    }
                                                                    onChange={r =>
                                                                        replace(index, {
                                                                            ...invoice,
                                                                            recipients: r,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                            <Checkbox
                                                                label="Send invoice on save"
                                                                checked={
                                                                    invoice.sendOnSave
                                                                }
                                                                onChange={e =>
                                                                    replace(index, {
                                                                        ...invoice,
                                                                        sendOnSave:
                                                                            e.target
                                                                                .checked,
                                                                    })
                                                                }
                                                            />
                                                            <InvoiceDueDateAndTerms
                                                                dueDate={invoice.dueDate}
                                                                onDueDateChange={d => {
                                                                    replace(index, {
                                                                        ...invoice,
                                                                        dueDate: d,
                                                                    });
                                                                }}
                                                                termId={invoice.termId}
                                                                onTermIdChange={t => {
                                                                    replace(index, {
                                                                        ...invoice,
                                                                        termId: t,
                                                                    });
                                                                }}
                                                            />
                                                            <FieldArray
                                                                name={`invoices[${index}].lineItems`}>
                                                                {({ insert, remove }) => (
                                                                    <div className="space-y-2">
                                                                        {invoice.lineItems.map(
                                                                            (
                                                                                lineItem,
                                                                                lineIndex,
                                                                            ) => (
                                                                                <div
                                                                                    className="flex space-x-2 items-end"
                                                                                    key={
                                                                                        lineIndex
                                                                                    }>
                                                                                    <label className="flex-1">
                                                                                        {lineIndex ===
                                                                                        0 ? (
                                                                                            <div className="mb-1">
                                                                                                Description
                                                                                            </div>
                                                                                        ) : null}
                                                                                        <Field
                                                                                            name={`invoices[${index}].lineItems[${lineIndex}].description`}
                                                                                            className={
                                                                                                inputClassNames
                                                                                            }
                                                                                        />
                                                                                    </label>
                                                                                    <label>
                                                                                        {lineIndex ===
                                                                                        0 ? (
                                                                                            <div className="mb-1">
                                                                                                Amount
                                                                                            </div>
                                                                                        ) : null}
                                                                                        <Field
                                                                                            name={`invoices[${index}].lineItems[${lineIndex}].unitPrice`}
                                                                                            inputmode="decimal"
                                                                                            type="number"
                                                                                            className={
                                                                                                inputClassNames
                                                                                            }
                                                                                        />
                                                                                    </label>
                                                                                    {lineIndex >
                                                                                    0 ? (
                                                                                        <div className="pb-1">
                                                                                            <DeleteButton
                                                                                                backgroundColor="bg-gray-100"
                                                                                                confirm={{
                                                                                                    title: "Remove Line Item",
                                                                                                    message:
                                                                                                        "Are you sure you want to remove this line item?",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                    remove(
                                                                                                        lineIndex,
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="w-7" />
                                                                                    )}
                                                                                </div>
                                                                            ),
                                                                        )}
                                                                        <SecondaryButton
                                                                            type="button"
                                                                            onClick={() =>
                                                                                insert(
                                                                                    invoice
                                                                                        .lineItems
                                                                                        .length,
                                                                                    createInitialLineItemValues(),
                                                                                )
                                                                            }>
                                                                            Add Line Item
                                                                        </SecondaryButton>
                                                                    </div>
                                                                )}
                                                            </FieldArray>
                                                            <div>
                                                                <TextArea
                                                                    label="Message"
                                                                    value={
                                                                        invoice.message
                                                                    }
                                                                    onChange={e =>
                                                                        replace(index, {
                                                                            ...invoice,
                                                                            message:
                                                                                e.target
                                                                                    .value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                            {formValues.values.invoices
                                                                .length > 1 ? (
                                                                <div className="pt-4 flex space-x-1 justify-end items-center">
                                                                    <div>Subtotal:</div>
                                                                    <div className="font-bold">
                                                                        {formatCurrency(
                                                                            invoice.lineItems.reduce(
                                                                                (
                                                                                    acc,
                                                                                    lineItem,
                                                                                ) =>
                                                                                    acc +
                                                                                    ensureFloat(
                                                                                        lineItem.unitPrice,
                                                                                    ),
                                                                                0,
                                                                            ),
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                </div>
                                            ),
                                        )}
                                        {formValues.values.invoices.some(
                                            i => !!i.customer,
                                        ) ? (
                                            <div className="rounded p-4 bg-gray-100">
                                                <SecondaryButton
                                                    type="button"
                                                    onClick={() =>
                                                        insert(
                                                            formValues.values.invoices
                                                                .length,
                                                            createInitialInvoiceValues(
                                                                formValues.values
                                                                    .invoices[
                                                                    formValues.values
                                                                        .invoices.length -
                                                                        1
                                                                ],
                                                            ),
                                                        )
                                                    }>
                                                    Add Another Invoice
                                                </SecondaryButton>
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </FieldArray>
                            <div className="pt-4 text-lg flex space-x-1 justify-end items-center">
                                <div>Total:</div>
                                <div className="font-bold">
                                    {formatCurrency(
                                        formValues.values.invoices.reduce(
                                            (acc, invoice) =>
                                                acc +
                                                invoice.lineItems.reduce(
                                                    (acc, lineItem) =>
                                                        acc +
                                                        ensureFloat(lineItem.unitPrice),
                                                    0,
                                                ),
                                            0,
                                        ),
                                    )}
                                </div>
                            </div>
                            <div className="pt-4">
                                {isSubmitting ? (
                                    <Spinner />
                                ) : (
                                    <div className="space-y-4">
                                        <div className="flex items-center justify-end space-x-4">
                                            <PrimaryButton
                                                type="button"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    formValues.handleSubmit();
                                                }}>
                                                Save
                                            </PrimaryButton>
                                            <SecondaryButton
                                                type="button"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    closeCreate(formValues.dirty);
                                                }}>
                                                Cancel
                                            </SecondaryButton>
                                        </div>
                                        {saveError ? (
                                            <InlineBanner type="error">
                                                {saveError}
                                            </InlineBanner>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </Form>
                    </Panel>
                )}
            </Formik>
            <Panel
                type={PanelType.extraLarge}
                headerText="Add existing QuickBooks invoice"
                isOpen={isAddExistingOpen}
                onDismiss={closeAddExising}>
                {isAddExistingOpen ? (
                    <div className="space-y-4">
                        <AddExistingExternalInvoice
                            onDismiss={closeAddExising}
                            onSave={addExistingInvoice}
                        />
                        {addExistingError ? (
                            <InlineBanner type="error">{addExistingError}</InlineBanner>
                        ) : null}
                    </div>
                ) : null}
            </Panel>
        </>
    );
};

interface AddExistingExternalInvoiceProps {
    onDismiss: () => void;
    onSave: (externalInvoiceId: string) => void;
}

export const AddExistingExternalInvoice: React.FC<
    AddExistingExternalInvoiceProps
> = props => {
    const { onDismiss, onSave } = props;
    const [existingInvoiceResult, setExternalInvoiceResult] = React.useState<
        ExternalInvoiceSearchHit[]
    >([]);

    const handleSave = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            const invoice = existingInvoiceResult[0];
            if (invoice) {
                onSave(invoice.externalId);
            }
        },
        [existingInvoiceResult, onSave],
    );

    const handleCancel = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            onDismiss();
        },
        [onDismiss],
    );

    return (
        <div className="space-y-4">
            <p>
                To add an existing QuickBooks invoice to this client, enter the QuickBooks
                invoice number in the field below, confirm the details, and click Save.
            </p>
            <ExternalInvoiceSearch onResults={setExternalInvoiceResult} />
            <div className="flex items-center space-x-4 justify-end">
                <PrimaryButton
                    onClick={handleSave}
                    disabled={existingInvoiceResult.length === 0}>
                    Save
                </PrimaryButton>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
            </div>
        </div>
    );
};
