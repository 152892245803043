import {
    AdditionalClientContact,
    ClientId,
    ContactId,
    IAdditionalClient,
    IClient,
    IContact,
    InternalId,
    ReferenceContactId,
} from "@sp-crm/core";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { ClientContactRelationship } from "generated/graphql";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "store/selectors/selectors";
import { useTenantSettings } from "store/selectors/settings";
import { ApplicationState } from "store/state";
import {
    addAdditionalClientPhysicianContact,
    deleteAdditionalClientContactPerson,
    updateAdditionalClientContactPerson,
    updateClient,
    updateClientMultiField,
} from "../../../../store/actions";
import { ClientContacts } from "../client-contacts";
import EditCareProviderContact from "./edit-contact";
import MultipleClientContacts from "./multiple-clients";

const findContact = (contacts: IContact[], id: string): IContact =>
    contacts.find(contact => contact.id === id);

type CareProvidersOwnProps = {
    clientId: ClientId;
};

export const CareProviders: React.FC<CareProvidersOwnProps> = ({ clientId }) => {
    const globalDispatch = useDispatch();

    // Select client and additional client from state using clientId prop.
    const client: IClient = useSelector((state: ApplicationState) =>
        getClient(state, clientId),
    );

    const {
        settings: { showPhysicianContacts },
    } = useTenantSettings();

    const additionalClient: IAdditionalClient | undefined = client.additionalClient;

    // Local component state for managing the edit contact panel.
    const [shouldShowEditContactPanel, setShouldShowEditContactPanel] =
        React.useState<boolean>(false);
    const [editingContactId, setEditingContactId] = React.useState<ContactId | null>(
        null,
    );

    // Handle the checkbox toggle that controls whether the same contacts should be used
    const handleSameForBothClientsCheckbox = async (newValue: boolean) => {
        if (
            newValue &&
            additionalClient &&
            additionalClient.physicianContacts &&
            additionalClient.physicianContacts.length > 0
        ) {
            const result = await fancyConfirm(
                "Use same contacts for both clients?",
                "This will erase any Additional Client's Physician Contacts registered so far",
                "Yes, I'm sure",
                "Cancel",
            );
            if (result) {
                updateClientMultiField(
                    clientId,
                    {
                        "additionalClient.physicianContactsSameAsPrimaryClient": newValue,
                        "additionalClient.physicianContacts": [],
                    },
                    globalDispatch,
                );
            }
        } else {
            updateClient(
                clientId,
                "additionalClient.physicianContactsSameAsPrimaryClient",
                newValue,
                globalDispatch,
            );
        }
    };

    // Create a helper that calls the action for adding a new additional client contact.
    const onAddNewContactForAdditionalClient = (
        additionalClientId: InternalId,
        payload: Partial<AdditionalClientContact>,
    ) => {
        return addAdditionalClientPhysicianContact(
            clientId,
            additionalClientId,
            payload,
            globalDispatch,
        );
    };

    const handleAddAdditionalClientContact = () => {
        if (!additionalClient) return;
        onAddNewContactForAdditionalClient(additionalClient.id, {}).then(responseData => {
            if (responseData && responseData.newContactId) {
                showAdditionalClientEditContactPanel(responseData.newContactId);
            }
        });
    };

    const handleLinkAdditionalClientContact = (
        referenceContactId: ReferenceContactId,
    ) => {
        if (!additionalClient) return;
        onAddNewContactForAdditionalClient(additionalClient.id, {
            referenceId: referenceContactId,
        });
    };

    const handleDeleteAdditionalClientContact = (contactId: ContactId) => {
        if (!additionalClient) return;
        deleteAdditionalClientContactPerson(
            clientId,
            additionalClient.id,
            contactId,
            globalDispatch,
        );
    };

    const showAdditionalClientEditContactPanel = (contactId: ContactId) => {
        setShouldShowEditContactPanel(true);
        setEditingContactId(contactId);
    };

    const hideEditContactPanel = () => {
        setShouldShowEditContactPanel(false);
        setEditingContactId(null);
    };

    const handleUpdateContact = (
        contactId: ContactId,
        contact: AdditionalClientContact,
    ) => {
        updateAdditionalClientContactPerson(
            clientId,
            additionalClient.id,
            contactId,
            contact,
            globalDispatch,
        );
    };

    // Helper to load the currently editing contact based on editingContactId.
    const loadContact = (): IContact | null => {
        if (!editingContactId) {
            return null;
        }
        if (additionalClient && additionalClient.physicianContacts) {
            return findContact(additionalClient.physicianContacts, editingContactId);
        }
        return null;
    };

    if (!showPhysicianContacts) return null;

    const editingContact = loadContact();
    const showSingleClient = !additionalClient;

    return (
        <>
            {showSingleClient ? (
                <div className="input-form-block-no-bottom-margin">
                    <ClientContacts
                        clientId={client.id}
                        relationship={ClientContactRelationship.CareProvider}
                    />
                </div>
            ) : (
                <MultipleClientContacts
                    client={client}
                    additionalClient={additionalClient}
                    handleSameForBothClientsCheckbox={handleSameForBothClientsCheckbox}
                    physicianContactsSameAsPrimaryClient={
                        additionalClient.physicianContactsSameAsPrimaryClient
                    }
                    onAddNewAdditionalClientContact={handleAddAdditionalClientContact}
                    onLinkNewAdditionalClientContact={handleLinkAdditionalClientContact}
                    editAdditionalClientContact={showAdditionalClientEditContactPanel}
                    deleteAdditionalClientContact={handleDeleteAdditionalClientContact}
                />
            )}
            {shouldShowEditContactPanel && editingContact ? (
                <EditCareProviderContact
                    show={true}
                    contact={editingContact}
                    onUpdateContact={handleUpdateContact}
                    onCloseEditPanel={hideEditContactPanel}
                />
            ) : null}
        </>
    );
};
