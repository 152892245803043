import { ContentFull, ContentHeader, ContentSubheader } from "components/layout";
import { Radio, RadioOption } from "components/shared/radio";
import React from "react";
import { useProductName } from "store/selectors/branding";
import { useCurrentUser } from "store/selectors/hooks";
import { useTenantSettings } from "store/selectors/settings";
import { UserCheckboxPref } from "./account-settings-checkbox";
import { useCommitUserPreference } from "./account-settings-hooks";

const clientAssignmentDashboardOptions: RadioOption[] = [
    {
        key: "myself",
        text: "Myself",
        helpText: "On dashboard pages, default to showing clients assigned to me.",
    },
    {
        key: "everyone",
        text: "Everyone",
        helpText: "On dashboard pages, default to showing clients assigned to anyone.",
    },
];

export const AccountSettingsClient: React.FC<unknown> = () => {
    const user = useCurrentUser();
    const updateClientAssignmentFilter = useCommitUserPreference(
        "preferredClientAssignmentFilter",
    );
    const { settings } = useTenantSettings();
    const productName = useProductName();

    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>Clients</ContentHeader>
                <div className="space-y-4">
                    <UserCheckboxPref
                        field="preferAge"
                        label="Use Age (not Birthday) by default on Client Intake form"
                        description={`When entering Client details, default to using the client's "Age". If you prefer to track the client's birthday then uncheck this option."`}
                    />
                    <UserCheckboxPref
                        field="displayBestContactPhoneOnDashboard"
                        label="Show Best Contact phone on Dashboard"
                        description={`On the Dashboard pages, include the Best Contact phone number. This can be especially convenient when viewing ${productName} from smart phone.`}
                    />
                    <Radio
                        label={`Default "Filter by Employee" on Dashboard pages`}
                        value={
                            user.preferences.preferredClientAssignmentFilter || "myself"
                        }
                        onChange={updateClientAssignmentFilter}
                        options={clientAssignmentDashboardOptions}
                    />
                </div>
            </ContentFull>
            <ContentFull>
                <ContentHeader>Client referrals</ContentHeader>
                <ContentSubheader>
                    Default options when referring clients to communities.
                </ContentSubheader>
                <div className="space-y-4">
                    <UserCheckboxPref
                        field="useBulkCommunityEmail"
                        label="Use bulk community email"
                        description="When sending a Client Intake, show options to email any/all potential matched communities at the same time."
                    />
                    <UserCheckboxPref
                        field="showAdditionalContactsForReferrals"
                        label="Share client's Additional Contact(s) by default"
                        description="When downloading or emailing Client referral PDFs, include the Additional Contacts in the generated PDF by default."
                    />
                    <UserCheckboxPref
                        field="showContactPhoneNumbersForReferrals"
                        label="Share the client's contact(s) Phone Numbers by default"
                        description="When downloading or emailing Client referral PDFs, include contacts' phone numbers in the generated PDF by default."
                    />
                    <UserCheckboxPref
                        field="showContactEmailAddrsForReferrals"
                        label="Share the client's contact(s) Email Addresses by default"
                        description="When downloading or emailing Client referral PDFs, include contacts' email addresses in the generated PDF by default."
                    />
                    <UserCheckboxPref
                        field="showContactLocationsForReferrals"
                        label="Share the client's contact(s) locations by default"
                        description="When downloading or emailing Client referral PDFs, include contacts' location details in the generated PDF by default."
                    />
                    <UserCheckboxPref
                        field="showClientBudget"
                        label="Share the client's budget by default"
                        description="When downloading or emailing Client referral PDFs, include client's budget in the generated PDF by default."
                    />
                    <UserCheckboxPref
                        field="showClientLocation"
                        label="Share the client's current location by default"
                        description="When downloading or emailing Client referral PDFs, include client's current location details in the generated PDF by default."
                    />
                    {settings.showPhysicianContacts ? (
                        <UserCheckboxPref
                            field="showCareProvidersForReferrals"
                            label="Share the client's care providers by default"
                            description="When downloading or emailing Client referral PDFs, include client's care providers in generated PDF by default."
                        />
                    ) : null}
                    {settings.showInsuranceInfo ? (
                        <UserCheckboxPref
                            field="showHealthInsuranceForReferrals"
                            label="Share the client's health insurance by default"
                            description="When downloading or emailing Client referral PDFs, include client's health insurance details in generated PDF by default."
                        />
                    ) : null}
                </div>
            </ContentFull>
        </div>
    );
};
