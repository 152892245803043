import { Location } from "history";
import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import { ReminderProvider } from "../../contexts/reminder";
import * as actions from "../../store/actions";
import { ResponsiveMode } from "../../store/reducers/responsive";
import { Alerts } from "../alerts";
import { Responsive } from "../responsive";

import { QueryClient } from "react-query";
import { RouteComponentProps, withRouter } from "react-router";
import { useResponsiveMode } from "store/selectors/hooks";
import { baseAppDataLoaded } from "store/selectors/selectors";
import { useTenantSettings } from "store/selectors/settings";
import { AutoReload } from "./auto-reload";
import { Header } from "./header";
import { MobileNav } from "./navigation/mobile-nav";
import { UserChangedReload } from "./user-changed-reload";

const isLoginWorkflowRoute = (pathname: string): boolean => {
    if (pathname === "/login") return true;
    if (pathname === "/license") return true;
    if (pathname === "/account/reset-password") return true;
    if (pathname === "/account/reset") return true;
    return false;
};

interface ContainerPropsFromState {
    children: React.ReactNode;
    location: Location;
}

interface ContainerPropsFromDispatch {
    loadAll: (queryClient: QueryClient) => void;
}

interface ContainerOwnProps {
    queryClient: QueryClient;
}

type ContainerProps = ContainerPropsFromDispatch &
    ContainerPropsFromState &
    ContainerOwnProps;

class Container extends React.Component<ContainerProps, undefined> {
    UNSAFE_componentWillMount() {
        if (!isLoginWorkflowRoute(this.props.location.pathname)) {
            this.props.loadAll(this.props.queryClient);
        }
    }

    render() {
        return <ContainerInner {...this.props} />;
    }
}

const ContainerInner: React.FC<ContainerProps> = props => {
    const {
        location: { pathname },
        children,
    } = props;

    const responsiveMode = useResponsiveMode();
    const { status } = useTenantSettings();
    const baseReduxStateLoaded = useSelector((state: ApplicationState) =>
        baseAppDataLoaded(state),
    );
    const appDataLoaded = status === "success" && baseReduxStateLoaded;

    if (!isLoginWorkflowRoute(pathname) && !appDataLoaded) {
        return null;
    }

    return (
        <div className={`h-100 ms-App size-${ResponsiveMode[responsiveMode]}`}>
            <AutoReload />
            <UserChangedReload />
            <div className="app-component h-100 d-flex flex-column">
                <ReminderProvider>
                    <Header />
                    <MobileNav />
                </ReminderProvider>
                <div className="sp-body row flex-1">
                    <div className="col main-body h-100 position-relative">
                        {children}
                    </div>
                </div>
                <Alerts />
            </div>
            <Responsive />
        </div>
    );
};

function mapDispatchToProps(dispatch: Dispatch<Action>): ContainerPropsFromDispatch {
    return {
        loadAll: (queryClient: QueryClient) => {
            actions.loadAll(queryClient, dispatch);
        },
    };
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: RouteComponentProps & { children: React.ReactNode },
): ContainerPropsFromState {
    return {
        children: ownProps.children,
        location: ownProps.location,
    };
}

const component = withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
export { component as Container };
