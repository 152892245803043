import { ILocation, locationToCoordinates } from "@sp-crm/core";
import { WhiteLabelRestricted } from "components/app/white-label-restricted";
import { HoveredMapEntity } from "components/community-search/community-map/types";
import { Content, ContentContainer, SectionHeader, Stage } from "components/layout";
import { SearchRequestPagination } from "components/simple-search/search-request-pagination";
import { InlineBanner } from "components/ui/inline-banner";
import { defaultLinkStyle } from "components/ui/link";
import {
    useGetUserProfileQuery,
    UserProfileStatus,
    useSearchUserProfilesQuery,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ResponsiveMode } from "store/reducers/responsive";
import { useProductName } from "store/selectors/branding";
import { useFeature, useResponsiveMode } from "store/selectors/hooks";
import { useTenantSettings } from "store/selectors/settings";
import { AgentDirectoryFilter, agentDirectorySlice } from "store/slices/agent-directory";
import { AgentDirectoryMap } from "./agent-directory-map";
import { AgentDirectorySearchBar } from "./agent-directory-search-bar";
import { AgentDirectorySearchList } from "./agent-directory-search-list";

export const mapThreshold: ResponsiveMode = ResponsiveMode.xLarge;

export const AgentDirectory: React.FC<unknown> = () => {
    const directoryEnabled = useFeature("agentDirectory");
    const publicProfileEnabled = useTenantSettings().settings.enablePublicProfile;
    const dispatch = useAppDispatch();
    const newAppState = useAppSelector(state => state.agentDirectory);
    const {
        updateSearch,
        changePage,
        changePageSize,
        setExplicitMapBounds,
        resetMapBounds,
    } = agentDirectorySlice.actions;

    const userProfileQuery = useGetUserProfileQuery(
        {},
        { enabled: publicProfileEnabled, keepPreviousData: true },
    );

    const userProfileComplete = useMemo(() => {
        if (!userProfileQuery.data) {
            return false;
        }

        return (
            userProfileQuery.data.getUserProfile.status === UserProfileStatus.Public &&
            userProfileQuery.data.getUserProfile.isComplete
        );
    }, [userProfileQuery.data]);

    const displayMode = useMemo(() => {
        if (!publicProfileEnabled) {
            return "none";
        }

        if (userProfileQuery.isLoading) {
            return "none";
        }

        if (directoryEnabled) {
            return userProfileComplete ? "full" : "profile-incomplete";
        }

        return userProfileComplete ? "coming-soon-complete" : "coming-soon-incomplete";
    }, [
        publicProfileEnabled,
        directoryEnabled,
        userProfileComplete,
        userProfileQuery.isLoading,
    ]);

    const searchQuery = useSearchUserProfilesQuery(
        {
            params: newAppState.searchRequest,
        },
        {
            enabled: displayMode === "full" || displayMode === "profile-incomplete",
        },
    );

    const onSearchUpdated = useCallback(
        (search: AgentDirectoryFilter) => {
            dispatch(updateSearch(search));
        },
        [dispatch, updateSearch],
    );

    const handlePageChange = useCallback(
        (page: number) => dispatch(changePage({ page })),
        [dispatch, changePage],
    );

    const handlePageSizeChange = useCallback(
        (pageSize: number) => dispatch(changePageSize({ pageSize })),
        [dispatch, changePageSize],
    );

    const handleExplicitBoundsChanged = useCallback(
        (northWest: ILocation, southEast: ILocation) => {
            dispatch(
                setExplicitMapBounds({
                    northWest: locationToCoordinates(northWest),
                    southEast: locationToCoordinates(southEast),
                }),
            );
        },
        [dispatch, setExplicitMapBounds],
    );

    const handleResetMapView = useCallback(() => {
        dispatch(resetMapBounds());
    }, [dispatch, resetMapBounds]);

    const agentHits = useMemo(() => {
        if (searchQuery.data?.searchUserProfiles.hits.length > 0) {
            return searchQuery.data?.searchUserProfiles.hits.slice(
                newAppState.searchRequest.page * newAppState.searchRequest.perPage,
                newAppState.searchRequest.page * newAppState.searchRequest.perPage +
                    newAppState.searchRequest.perPage,
            );
        }

        return [];
    }, [
        searchQuery.data?.searchUserProfiles.hits,
        newAppState.searchRequest.page,
        newAppState.searchRequest.perPage,
    ]);

    const [hoveredAgent, setHoveredAgent] = React.useState<HoveredMapEntity | null>(null);

    const responsiveMode = useResponsiveMode();

    const productName = useProductName();

    const showMap = responsiveMode >= mapThreshold;
    return (
        <WhiteLabelRestricted>
            {displayMode === "none" ? null : displayMode === "coming-soon-complete" ? (
                <Stage>
                    <SectionHeader title="Advisor Directory" />
                    <Content>
                        <InlineBanner type="success">
                            Thank you for completing your public advisor profile. The{" "}
                            {productName} Advisor Directory will be available soon, and
                            once it is, you will be able to connect with other advisors in
                            the {productName} community.
                        </InlineBanner>
                    </Content>
                </Stage>
            ) : displayMode === "coming-soon-incomplete" ? (
                <Stage>
                    <SectionHeader title="Advisor Directory" />
                    <Content>
                        <InlineBanner type="info">
                            The {productName} Advisor Directory will be available soon,
                            where you will be able to find and connect with other advisors
                            in the {productName} community. To be included in the
                            directory, please complete your public advisor profile at your{" "}
                            <Link
                                className={defaultLinkStyle}
                                to="/settings/account/personal">
                                personal account settings
                            </Link>
                            .
                        </InlineBanner>
                    </Content>
                </Stage>
            ) : displayMode === "full" || displayMode === "profile-incomplete" ? (
                <Stage>
                    <SectionHeader title="Advisor Directory" />
                    {displayMode === "profile-incomplete" ? (
                        <>
                            <Content>
                                <InlineBanner type="info">
                                    To appear in the {productName} Advisor Directory,
                                    please complete and share your public advisor profile
                                    at your{" "}
                                    <Link
                                        className={`${defaultLinkStyle} underline`}
                                        to="/settings/account/personal">
                                        personal account settings
                                    </Link>
                                    .
                                </InlineBanner>
                            </Content>
                            <div className="mb-4"></div>
                        </>
                    ) : null}
                    <Content>
                        <AgentDirectorySearchBar
                            value={newAppState.searchFilter}
                            onChange={onSearchUpdated}
                            onResetMapView={handleResetMapView}
                        />
                    </Content>
                    <ContentContainer>
                        <div className="mt-2 lg:mt-4">
                            <div className="w-full flex">
                                <div className="full-width">
                                    <AgentDirectorySearchList
                                        agents={agentHits}
                                        onHover={setHoveredAgent}
                                    />
                                    {searchQuery.data ? (
                                        <SearchRequestPagination
                                            request={newAppState.searchRequest}
                                            total={
                                                searchQuery.data.searchUserProfiles.total
                                            }
                                            onPageChange={handlePageChange}
                                            onPageSizeChange={handlePageSizeChange}
                                        />
                                    ) : null}
                                </div>
                                {showMap ? (
                                    <AgentDirectoryMap
                                        hoveredAgent={hoveredAgent}
                                        searchParams={newAppState.searchRequest}
                                        onExplicitBoundsChanged={
                                            handleExplicitBoundsChanged
                                        }
                                    />
                                ) : null}
                            </div>
                        </div>
                    </ContentContainer>
                </Stage>
            ) : null}
        </WhiteLabelRestricted>
    );
};
