import * as Common from "@sp-crm/core";
import { CalendarDate, ITaskBase, UserId } from "@sp-crm/core";
import { PrimaryButton } from "components/ui/primary-button";
import * as React from "react";
import { handleEvent } from "util/user-events";
import { Header } from "../../header";
import { TaskTypeContext } from "../task-type-provider";
import { TaskForm } from "./task-form";

interface NewTaskProps {
    onAddTask: (
        assignedUserId: UserId | null,
        text: string,
        notes: string,
        dueDate: CalendarDate | null,
        dueDateTime: Date | null,
        taskTypeId: Common.TaskTypeId | null,
    ) => void;
    users: Common.User[];
    assignedToId: UserId | null;
}

interface NewTaskState {
    isAddingNewTask: boolean;
}

export class NewTask extends React.Component<NewTaskProps, NewTaskState> {
    constructor(props: NewTaskProps) {
        super(props);
        this.state = {
            isAddingNewTask: false,
        };
    }

    save(
        assignedUserId: UserId | null,
        text: string,
        notes: string,
        dueDate: CalendarDate | null,
        dueDateTime: Date | null,
        taskTypeId: Common.TaskTypeId | null,
    ) {
        handleEvent("task-create");
        this.showAddingNewTask(false);
        this.props.onAddTask(
            assignedUserId,
            text,
            notes,
            dueDate,
            dueDateTime,
            taskTypeId,
        );
    }

    cancel() {
        this.showAddingNewTask(false);
    }

    showAddingNewTask(showForm: boolean) {
        this.setState({
            ...this.state,
            isAddingNewTask: showForm,
        });
    }

    showCreateNewTaskForm(): JSX.Element {
        const task: ITaskBase = {
            ownerId: this.props.assignedToId,
            text: "",
            notes: "",
            dueDate: CalendarDate.todayNoTime,
            dueDateTime: null,
            taskTypeId: null,
        };
        return (
            <div className="tasks-section shadow bg-gray-100">
                <Header iconName="24_TaskNew">Create new task</Header>
                <TaskTypeContext.Consumer>
                    {types => (
                        <TaskForm
                            onSaveTask={this.save.bind(this)}
                            onCancelTaskChanges={this.cancel.bind(this)}
                            task={task}
                            saveButtonLabel="Add Task"
                            users={this.props.users}
                            taskTypes={types}
                        />
                    )}
                </TaskTypeContext.Consumer>
            </div>
        );
    }

    showNewTaskButton(): JSX.Element {
        return (
            <div className="new-task-button">
                <PrimaryButton onClick={() => this.showAddingNewTask(true)}>
                    Create new task
                </PrimaryButton>
            </div>
        );
    }

    render() {
        if (this.state.isAddingNewTask) return this.showCreateNewTaskForm();

        return this.showNewTaskButton();
    }
}
