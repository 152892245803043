import React from "react";
import { ISearchAddress } from "./util";

interface SearchLocationPreviewProps {
    address: ISearchAddress;
    specificZip: boolean;
    specificCity: boolean;
    specificCounty: boolean;
    specificState: boolean;
}

export const SearchLocationPreview: React.FC<SearchLocationPreviewProps> = props => {
    const { address, specificZip, specificCity, specificCounty, specificState } = props;
    if (specificZip) return <div>In {address.zip}</div>;
    if (specificCity)
        return (
            <div>
                In {address.city}, {address.state}
            </div>
        );
    if (specificCounty)
        return (
            <div>
                In {address.county}, {address.state}
            </div>
        );
    if (specificState) return <div>In {address.state}</div>;
    return (
        <div>
            {address.city && address.city.length ? (
                <span>
                    Near {address.city}
                    {address.state && address.state.length && `, ${address.state}`}
                </span>
            ) : address.zip ? (
                <span>Near {address.zip}</span>
            ) : (
                <span>Near {address.state}</span>
            )}
        </div>
    );
};
