import { NavMenu } from "components/navigation/nav-menu";
import React from "react";

const links = [
    {
        to: "/files",
        label: "Our Files",
        exact: true,
    },
    {
        to: "/files/signwise",
        label: "Signwise",
        exact: true,
    },
];

export const FilesNavigation: React.FC<unknown> = () => {
    return <NavMenu links={links} />;
};
