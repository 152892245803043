import { ILocation } from "@sp-crm/core";
import {
    HoveredMapEntity,
    MapMarkerId,
} from "components/community-search/community-map/types";
import { SearchResultsMap } from "components/map/search-results-map";
import { MapSearchResults, MapView } from "components/map/types";
import {
    SearchUserProfileRequest,
    useSearchUserProfilesMapViewQuery,
} from "generated/graphql";
import "maplibre-gl/dist/maplibre-gl.css";
import React, { useMemo } from "react";

const staleTime = 1000 * 60 * 10; // 10m

interface AgentDirectoryMapProps {
    hoveredAgent: null | HoveredMapEntity;
    searchParams: SearchUserProfileRequest;
    onExplicitBoundsChanged: (northWest: ILocation, southEast: ILocation) => void;
}

export const AgentDirectoryMap: React.FC<AgentDirectoryMapProps> = props => {
    const { hoveredAgent, searchParams, onExplicitBoundsChanged } = props;
    const [mapView, setMapView] = React.useState<MapView | null>(null);

    const query = useSearchUserProfilesMapViewQuery(
        {
            params: searchParams,
            mapView,
        },
        {
            staleTime,
            keepPreviousData: true,
            enabled: !!mapView,
        },
    );

    const searchResults: MapSearchResults = useMemo(() => {
        if (!query.data) {
            return null;
        }

        return {
            entityClusters: query.data.searchUserProfilesMapView.userProfileClusters.map(
                (c: { lat: number; lng: number; count: number }) => ({
                    lat: c.lat,
                    lng: c.lng,
                    count: c.count,
                }),
            ),
            entityCoordinates:
                query.data.searchUserProfilesMapView.userProfileCoordinates.map(
                    (coordinate: {
                        id: string;
                        lat: number;
                        lng: number;
                        name?: string;
                    }) => ({
                        id: coordinate.id as MapMarkerId,
                        lat: coordinate.lat,
                        lng: coordinate.lng,
                        name: coordinate.name || "(no name)",
                        color: "violet",
                    }),
                ),
            searchCoordinates: query.data.searchUserProfilesMapView.coordinates,
            recommendedZoom: query.data.searchUserProfilesMapView.recommendedZoom,
        };
    }, [query.data]);

    return (
        <SearchResultsMap
            explicitBounds={searchParams.geoParams?.bounds || null}
            onExplicitBoundsChanged={onExplicitBoundsChanged}
            hoveredEntity={hoveredAgent}
            isLoading={query.isLoading}
            onMapViewChanged={setMapView}
            results={searchResults}
            error={query.error}
        />
    );
};
