import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { FilesNavigation } from "components/files/files-navigation";
import { SignatureRequestList } from "components/files/signature-request/signature-request-list";
import { Col, Row } from "components/shared/grid";
import { InlineBanner } from "components/ui/inline-banner";
import { SearchInputDebounced } from "components/ui/search-input-debounced";
import { Select } from "components/ui/select";
import {
    SignatureRequestFilter,
    SignatureRequestState,
    useGetAllSignatureRequestsQuery,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRegionId } from "store/selectors/hooks";
import { signwiseDashboardSlice } from "store/slices/signwise-dashboard";
import { ApplicationState } from "store/state";
import { Content, SectionHeader, Stage } from "../layout";

const allSentinel = "_All_";

const selectStateOptions = [
    { label: "All States", value: allSentinel },
    { label: "In Progress", value: SignatureRequestState.InProgress },
    { label: "Complete", value: SignatureRequestState.Complete },
    { label: "Cancelled", value: SignatureRequestState.Abandoned },
];

export const SignwiseDashboard: React.FC<unknown> = () => {
    const regionId = useRegionId();
    const { updateSearchText, updateIncludeStates } = signwiseDashboardSlice.actions;
    const dispatch = useDispatch();

    const handleSearchTextChange = useCallback(
        (value: string) => {
            dispatch(updateSearchText(value));
        },
        [dispatch, updateSearchText],
    );

    const storedFilter = useSelector(
        (state: ApplicationState) => state.signwiseDashboard.filter,
    );

    const requestFilter: SignatureRequestFilter = useMemo(() => {
        return {
            ...storedFilter,
            regionId,
        };
    }, [regionId, storedFilter]);

    const query = useGetAllSignatureRequestsQuery(
        {
            signatureRequestFilter: requestFilter,
        },
        { keepPreviousData: true },
    );

    const signatureRequests = React.useMemo(() => {
        return (
            query.data?.getAllSignatureRequests.signatureRequests.map(sr => ({
                ...sr.signatureRequest,
                entity: sr.entity,
            })) ?? []
        );
    }, [query.data]);

    const currentStatus = useMemo(() => {
        if (requestFilter.includeStates?.length > 0) {
            return requestFilter.includeStates[0];
        }
        return allSentinel;
    }, [requestFilter.includeStates]);

    const handleStatusChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e.target.value === allSentinel) {
                dispatch(updateIncludeStates([]));
            } else {
                dispatch(updateIncludeStates([e.target.value as SignatureRequestState]));
            }
        },
        [dispatch, updateIncludeStates],
    );

    const atLimit = query.data?.getAllSignatureRequests.signatureRequests.length >= 100;

    return (
        <Row type="flex">
            <Col className="component-wrapper">
                <Row className="multi-page-nav">
                    <Col>
                        <FilesNavigation />
                    </Col>
                </Row>
                <Stage>
                    <SectionHeader title="Signwise requests" />
                    <Content>
                        <div className="flex space-x-2 items-center">
                            <div className="flex-1 max-w-lg">
                                <SearchInputDebounced
                                    label="Search"
                                    type="text"
                                    onChange={handleSearchTextChange}
                                    initial={requestFilter.search}
                                    placeholder="Template name, recipient email, or associated record name"
                                />
                            </div>
                            <div className="flex-1 max-w-lg">
                                <Select
                                    label="Status"
                                    onChange={handleStatusChange}
                                    value={currentStatus}>
                                    {selectStateOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </Content>
                    <div className="mt-2 lg:mt-4">
                        <Content>
                            {atLimit ? (
                                <InlineBanner type="info">
                                    This shows the most recent 100 requests. If you need
                                    to find an older request, use the search bar to narrow
                                    results.
                                </InlineBanner>
                            ) : null}
                            <QueryRenderer query={query} name="SignwiseDashboard">
                                {_ => (
                                    <SignatureRequestList
                                        refetchSignatures={query.refetch}
                                        signatureRequests={signatureRequests}
                                    />
                                )}
                            </QueryRenderer>
                        </Content>
                    </div>
                </Stage>
            </Col>
        </Row>
    );
};
