import { PhoneIcon } from "@heroicons/react/20/solid";
import {
    ArrowRightOnRectangleIcon,
    DocumentArrowDownIcon,
    EnvelopeIcon,
    LinkIcon,
    PlusIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import * as Common from "@sp-crm/core";
import { ActionType, ClientId, ContactId, Localization } from "@sp-crm/core";
import { WhiteLabelRestricted } from "components/app/white-label-restricted";
import { Activity } from "components/entity-activity/entity-activity";
import { SignatureRequestPanel } from "components/files/signature-request/signature-request-panel";
import { EntityPageHeader } from "components/layout";
import { MessageCompose } from "components/messages/message-compose";
import { SentMessages } from "components/messages/sent";
import { SpecialComposeCommmunityComparisonProps } from "components/messages/special/community-comparison";
import {
    QueryInvalidator,
    QueryInvalidatorHandle,
} from "components/store-aware-query-client-provider/invalidator";
import {
    ActionsMenu,
    ActionsMenuAction,
    ActionsMenuHeader,
    ActionsMenuSection,
    SignWiseMenuAction,
} from "components/ui/actions-menu";
import { AutosavingInput } from "components/ui/autosaving-input";
import { CheckboxUncontrolled } from "components/ui/checkbox";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ApplicationState } from "store/state";
import { twoLineFormattedAddress } from "util/address";
import { handleEvent } from "util/user-events";
import {
    AdvancedSearchEntityType,
    CommunityOrdering,
    FileEntityType,
    SearchClientsQuery,
} from "../../../generated/graphql";
import { currentLocale } from "../../../store/selectors/regions";
import { isAllowed } from "../../../util/permissions";
import { BylineHeaderEditable } from "../../byline/header-editable";
import { Feature, isFeatureEnabled } from "../../feature";
import { FilesControl } from "../../files";
import { Header } from "../../header";
import { LocaleContext } from "../../locale-provider/locale-provider";
import { RegionSelect } from "../../region-select";
import Shared from "../../shared";
import { Col, NgGrid, Row } from "../../shared/grid";
import { SubnavItem } from "../../shared/subnav/subnav-types";
import { Tasks } from "../../tasks/child";
import { ClientMovesData } from "../client-moves-data";
import { ClientNamesHeader } from "../client-names-header";
import { InvoiceInput } from "../invoices";
import { ShowClientActionBar } from "./action-bar";
import { CareProviders } from "./care-providers";
import { ClientOverviewTab } from "./client-overview-tab";
import { ClientCommunity } from "./community";
import { CommunityComparisonOrderable } from "./community-comparison/community-comparison-orderable";
import { CommunitiesComparePdfDialog } from "./community-comparison/community-pdf-dialog";
import { EmailCommunityComparisonCustomization } from "./community-comparison/email-community-comparison-customization";
import { LiveCommunityComparisonLink } from "./community-comparison/live-comparison-link";
import { DuplicateClientBanner } from "./duplicate-client-banner";
import { InsuranceDetails } from "./insurance-details";
import { ClientIntakeDownloadDialog } from "./intake/download-dialog";
import { ClientIntakeFax } from "./intake/fax";
import {
    ClientIntakeEmailDialogToClient,
    ClientIntakeEmailDialogToCommunity,
} from "./intake/intake";
import { InvoiceTab } from "./invoices/invoice-tab";
import { Moves } from "./moves";
import { NoActiveTasksPrompt } from "./no-active-tasks-prompt";
import ClientQuestionSection from "./question-section";
import { ShowClientReferrals } from "./referrals";
import { ShareInputFormLinkPanel } from "./share-input-form-link-panel";
import { TransferClientPanel } from "./transfer-client-panel";

export type Subpage =
    | "overview"
    | "needs"
    | "communities"
    | "tasks"
    | "compareCommunities"
    | "moves"
    | "invoices"
    | "referrals"
    | "emailsSent"
    | "files"
    | "activity";

enum OverlayExperience {
    None,
    ClientIntakeDownload,
    ClientIntakeEmailToCommunities,
    ClientIntakeFaxToCommunities,
    CommunityComparisonDownload,
    CommunityComparisonEmail,
    GenericClientEmail,
    ShareLink,
    Signature,
    ClientDisclosureEmail,
    TransferClient,
    ShareInputFormLink,
}

interface ShowClientOwnProps {
    client: Common.IClient;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChangeMulti: (newValues: any) => void;
    onClientCommunityOrderChange: (
        clientId: ClientId,
        ordering: CommunityOrdering[],
    ) => void;
    newlyAddedContactPerson?: ContactId;
    onUpdateAnswer: (answer: Common.IAnswer) => void;
    delete: () => void;
    reactivate: () => void;
    getShareLink: () => Promise<string>;
    questions: Common.IQuestion[];
    users: Common.User[];
    user: Common.User;
    tenant: Common.ITenant;
    now: Date;
    activeSubpage: Subpage;
    selectSection: (section: Subpage) => void;
    closeSection: () => void;
    pdfRenderable: boolean;
    fileCount: number;
    navigateToClients: () => void;
    tasks: Common.ITask[];
    refetchTasks: () => void;
    tasksLoaded: boolean;
}

interface ShowClientPropsFromState {
    showInvoicesTab: boolean;
    showReferralsTab: boolean;
    faxEnabled: boolean;
    locale: Localization;
    disclosureEnabled: boolean;
    shareInputFormLinkEnabled: boolean;
}

type ShowClientProps = ShowClientOwnProps & ShowClientPropsFromState;

interface ShowClientState {
    clientOnly: boolean;
    isCommunitiesPdfRequested: boolean;
    isNoteChanged: boolean;
    newlyAddedContactPerson?: ContactId;
    overlayExperience: OverlayExperience;
    previouslyAddedContactPerson?: ContactId;
    relatedClients: SearchClientsQuery["getClients"]["clients"];
    taskAlertsEnabled: boolean;
}

class ShowClient extends React.Component<ShowClientProps, ShowClientState> {
    private invalidatorRef = React.createRef<QueryInvalidatorHandle>();

    constructor(p: ShowClientProps) {
        super(p);
        this.state = {
            isCommunitiesPdfRequested: false,
            isNoteChanged: false,
            newlyAddedContactPerson: null,
            previouslyAddedContactPerson: null,
            clientOnly: false,
            relatedClients: [],
            overlayExperience: OverlayExperience.None,
            taskAlertsEnabled: true,
        };
        this.activityTab = this.activityTab.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
        this.communityWorkflowFindIdealTab =
            this.communityWorkflowFindIdealTab.bind(this);
        this.communityWorkflowOptionsTab = this.communityWorkflowOptionsTab.bind(this);
        this.emailsSentTab = this.emailsSentTab.bind(this);
        this.filesTab = this.filesTab.bind(this);
        this.invoicesTab = this.invoicesTab.bind(this);
        this.movesTab = this.movesTab.bind(this);
        this.needsTab = this.needsTab.bind(this);
        this.overlayClientIntakeDownload = this.overlayClientIntakeDownload.bind(this);
        this.overlayClientIntakeEmailToCommunities =
            this.overlayClientIntakeEmailToCommunities.bind(this);
        this.overlayClientIntakeFaxToCommunities =
            this.overlayClientIntakeFaxToCommunities.bind(this);
        this.overlayCommunityComparisonDownload =
            this.overlayCommunityComparisonDownload.bind(this);
        this.overlayCommunityComparisonEmail =
            this.overlayCommunityComparisonEmail.bind(this);
        this.overlaySendGenericClientEmail =
            this.overlaySendGenericClientEmail.bind(this);
        this.overlayShareLink = this.overlayShareLink.bind(this);
        this.overlaySignature = this.overlaySignature.bind(this);
        this.overviewTab = this.overviewTab.bind(this);
        this.referralsTab = this.referralsTab.bind(this);
        this.startDelete = this.startDelete.bind(this);
        this.startReactivate = this.startReactivate.bind(this);
        this.overlayClientDisclosureEmail = this.overlayClientDisclosureEmail.bind(this);
        this.overlayTransferClient = this.overlayTransferClient.bind(this);
        this.overlayShareInputFormLink = this.overlayShareInputFormLink.bind(this);
        this.tasksTab = this.tasksTab.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props: ShowClientProps) {
        if (props.newlyAddedContactPerson != this.state.previouslyAddedContactPerson) {
            // only update state if we've actually received a *new* contact person
            this.setState({
                ...this.state,
                newlyAddedContactPerson: props.newlyAddedContactPerson,
            });
        }
    }

    private closeOverlay(): void {
        this.refreshClientCommunities();
        this.setState({ overlayExperience: OverlayExperience.None });
    }

    private async refreshClientCommunities() {
        if (this.invalidatorRef.current) {
            await this.invalidatorRef.current.invalidateQueries("getClientCommunities");
        }
    }

    private async startDelete() {
        const shouldDelete = await fancyConfirm(
            `Delete ${this.props.client.preferredClientName}?`,
            `Really delete ${this.props.client.preferredClientName}?`,
            "Yes, delete",
            "Cancel",
            { extraConfirm: true },
        );
        if (shouldDelete) {
            this.setState({ taskAlertsEnabled: false }, () => {
                setTimeout(() => {
                    this.props.delete();
                }, 0);
            });
        }
    }

    private overlayClientIntakeDownload(): void {
        this.setState({ overlayExperience: OverlayExperience.ClientIntakeDownload });
    }

    private overlayClientIntakeEmailToCommunities() {
        this.setState({
            overlayExperience: OverlayExperience.ClientIntakeEmailToCommunities,
        });
    }

    private overlayClientIntakeFaxToCommunities() {
        this.setState({
            overlayExperience: OverlayExperience.ClientIntakeFaxToCommunities,
        });
    }

    private overlayCommunityComparisonDownload() {
        this.setState({
            overlayExperience: OverlayExperience.CommunityComparisonDownload,
        });
    }

    private overlayCommunityComparisonEmail() {
        this.setState({ overlayExperience: OverlayExperience.CommunityComparisonEmail });
    }

    private overlaySendGenericClientEmail() {
        this.setState({ overlayExperience: OverlayExperience.GenericClientEmail });
    }

    private overlaySignature(): void {
        this.setState({ overlayExperience: OverlayExperience.Signature });
    }

    private overlayShareLink(): void {
        this.setState({ overlayExperience: OverlayExperience.ShareLink });
    }

    private overlayClientDisclosureEmail() {
        this.setState({ overlayExperience: OverlayExperience.ClientDisclosureEmail });
    }

    private overlayTransferClient() {
        handleEvent("client-action", { type: "transferClient" });
        this.setState({ overlayExperience: OverlayExperience.TransferClient });
    }

    private overlayShareInputFormLink() {
        this.setState({ overlayExperience: OverlayExperience.ShareInputFormLink });
    }

    private async startReactivate() {
        handleEvent("client-action", { type: "startReactivate" });
        const shouldReactivate = await fancyConfirm(
            `Start a new move?`,
            `Start a new move for ${this.props.client.name}? This will archive the current workflow and start a new one.`,
            "Yes, start a new move process",
            "Cancel",
            { extraConfirm: true },
        );
        if (shouldReactivate) {
            this.props.reactivate();
        }
    }

    private archiveBar(): JSX.Element {
        if (this.props.client.archived) {
            return (
                <div className="row">
                    <InlineBanner type="warning">
                        <p className="sp-overrides--large-message">
                            You are viewing a previous move for{" "}
                            {this.props.client?.name || "(no name)"}. To view all moves,
                            see{" "}
                            <Link to={`/clients/show/${this.props.client.id}/moves`}>
                                Other Moves
                            </Link>
                            .
                        </p>
                    </InlineBanner>
                </div>
            );
        }
        return null;
    }

    private duplicateWarning(): JSX.Element {
        return (
            <DuplicateClientBanner
                clientId={this.props.client.id}
                clientName={this.props.client.name}
            />
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    private headerBar(): any {
        const communityComparisonDisabled = !this.props.client.allCommunities.length;
        return (
            <EntityPageHeader>
                <ClientNamesHeader
                    primaryClientName={this.props.client.name}
                    additionalClientName={this.props.client.additionalClient?.name}
                    bestContactName={this.props.client.bestContactEntity?.name}
                    serial={this.props.client.serial}
                />
                <div className="flex items-center space-x-4">
                    <div>
                        <BylineHeaderEditable
                            showUpdatedBy
                            entity={this.props.client}
                            createdAtDateModified={(newDate: Date) =>
                                this.props.onFieldChange(
                                    "dateAddedManualOverride",
                                    newDate,
                                )
                            }
                        />
                    </div>
                    <div>
                        <ActionsMenu>
                            <ActionsMenuSection>
                                <ActionsMenuHeader>
                                    Client Intake Details
                                </ActionsMenuHeader>
                                <ActionsMenuAction
                                    Icon={EnvelopeIcon}
                                    disabled={communityComparisonDisabled}
                                    onClick={this.overlayClientIntakeEmailToCommunities}>
                                    Email to community
                                </ActionsMenuAction>
                                {this.props.faxEnabled ? (
                                    <ActionsMenuAction
                                        Icon={PhoneIcon}
                                        disabled={communityComparisonDisabled}
                                        onClick={
                                            this.overlayClientIntakeFaxToCommunities
                                        }>
                                        Fax to community
                                    </ActionsMenuAction>
                                ) : null}
                                {this.props.shareInputFormLinkEnabled ? (
                                    <ActionsMenuAction
                                        Icon={LinkIcon}
                                        onClick={this.overlayShareInputFormLink}>
                                        Share input form
                                    </ActionsMenuAction>
                                ) : null}
                                <ActionsMenuAction
                                    Icon={DocumentArrowDownIcon}
                                    disabled={!this.props.pdfRenderable}
                                    onClick={this.overlayClientIntakeDownload}>
                                    Download PDF
                                </ActionsMenuAction>
                            </ActionsMenuSection>
                            <ActionsMenuSection>
                                <ActionsMenuHeader>
                                    Community Comparison
                                </ActionsMenuHeader>
                                <ActionsMenuAction
                                    Icon={EnvelopeIcon}
                                    disabled={communityComparisonDisabled}
                                    onClick={this.overlayCommunityComparisonEmail}>
                                    Email to client
                                </ActionsMenuAction>
                                <ActionsMenuAction
                                    Icon={LinkIcon}
                                    disabled={communityComparisonDisabled}
                                    onClick={this.overlayShareLink}>
                                    Shareable link
                                </ActionsMenuAction>
                                <ActionsMenuAction
                                    Icon={DocumentArrowDownIcon}
                                    disabled={communityComparisonDisabled}
                                    onClick={this.overlayCommunityComparisonDownload}>
                                    Download PDF
                                </ActionsMenuAction>
                            </ActionsMenuSection>
                            <ActionsMenuSection>
                                <ActionsMenuHeader>Other Actions</ActionsMenuHeader>
                                <ActionsMenuAction
                                    Icon={EnvelopeIcon}
                                    onClick={this.overlaySendGenericClientEmail}>
                                    Send email
                                </ActionsMenuAction>
                                <SignWiseMenuAction onClick={this.overlaySignature} />
                                <ActionsMenuAction
                                    Icon={PlusIcon}
                                    onClick={this.startReactivate}>
                                    Start new move
                                </ActionsMenuAction>
                                <Feature name="transferClient">
                                    <WhiteLabelRestricted>
                                        <ActionsMenuAction
                                            Icon={ArrowRightOnRectangleIcon}
                                            onClick={this.overlayTransferClient}>
                                            Transfer client
                                        </ActionsMenuAction>
                                    </WhiteLabelRestricted>
                                </Feature>
                                <ActionsMenuAction
                                    Icon={TrashIcon}
                                    onClick={this.startDelete}>
                                    Delete
                                </ActionsMenuAction>
                            </ActionsMenuSection>
                        </ActionsMenu>
                    </div>
                </div>
            </EntityPageHeader>
        );
    }

    private tasksTab() {
        const assignedUserId = this.props.client.assignedUserId || null;
        return (
            <Row type="flex" className="collapsible">
                <Col className="more-than-half-width">
                    <Tasks
                        parentId={this.props.client.id}
                        defaultAssigneeId={assignedUserId}
                        tasks={this.props.tasks}
                        refetch={this.props.refetchTasks}
                    />
                </Col>
            </Row>
        );
    }

    private referralsTab() {
        return (
            <Row type="flex" className="collapsible">
                <Col className="full-width">
                    <ShowClientReferrals clientId={this.props.client.id} />
                </Col>
            </Row>
        );
    }

    private emailsSentTab() {
        return (
            <SentMessages
                entityId={this.props.client.id}
                showCommunity={true}
                showClient={false}
            />
        );
    }

    private movesTab() {
        return <Moves clients={this.state.relatedClients} />;
    }

    private activityTab() {
        return <Activity entityId={this.props.client.id} entityType="Client" />;
    }

    private getInvoiceInputs(): InvoiceInput {
        const communityName =
            this.props.client.clientCommunities?.find(
                cc => cc.community.id === this.props.client.chosenCommunityId,
            )?.community.name || "";
        const moveDate = this.props.client.moveTimelineActual;

        return {
            communityName,
            moveDate,
        };
    }

    private invoicesTab() {
        return (
            <Row type="flex" className="collapsible">
                <Col className="full-width">
                    <InvoiceTab
                        clientId={this.props.client.id}
                        invoiceInput={this.getInvoiceInputs()}
                    />
                </Col>
            </Row>
        );
    }

    private filesTab() {
        return (
            <FilesControl
                entityId={this.props.client.id}
                entityType={FileEntityType.Client}
            />
        );
    }

    private communityWorkflowOptionsTab(): JSX.Element {
        return (
            <CommunityComparisonOrderable
                clientId={this.props.client.id}
                onClientCommunityOrderChange={this.props.onClientCommunityOrderChange}
                client={this.props.client}
                locale={this.props.locale}
            />
        );
    }
    private communityWorkflowFindIdealTab(): JSX.Element {
        return (
            <ClientCommunity
                client={this.props.client}
                questions={this.props.questions}
            />
        );
    }

    private copyBestLocationAddress() {
        this.props.onFieldChangeMulti({
            bestLocationAddress: this.props.client.bestContactEntity.address1,
            bestLocationCity: this.props.client.bestContactEntity.city,
            bestLocationState: this.props.client.bestContactEntity.state,
            bestLocationZip: this.props.client.bestContactEntity.zip,
            useBestContactAddressForBestLocation: true,
        });
    }

    private idealLocationReadOnly(): JSX.Element {
        return twoLineFormattedAddress(
            this.props.client.bestLocationAddress,
            this.props.client.bestLocationCity,
            this.props.client.bestLocationState,
            this.props.client.bestLocationZip,
        );
    }

    private idealLocationInputFields(): JSX.Element {
        const editableIdealLocationField = (label: string, fieldName: string) => {
            return (
                <AutosavingInput
                    label={label}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                    initial={(this.props.client as any)[fieldName]}
                    onCommit={newValue => this.props.onFieldChange(fieldName, newValue)}
                />
            );
        };

        return (
            <LocaleContext.Consumer>
                {locale => (
                    <>
                        {editableIdealLocationField("Address", "bestLocationAddress")}
                        <div className="flex space-x-2 items-center">
                            {editableIdealLocationField("City", "bestLocationCity")}
                            {editableIdealLocationField(
                                locale.strings.state,
                                "bestLocationState",
                            )}
                            {editableIdealLocationField(
                                locale.strings.zip,
                                "bestLocationZip",
                            )}
                        </div>
                    </>
                )}
            </LocaleContext.Consumer>
        );
    }

    private areBestLocationFieldsAllEmpty() {
        return (
            !this.props.client.bestLocationAddress &&
            !this.props.client.bestLocationCity &&
            !this.props.client.bestLocationState &&
            !this.props.client.bestLocationZip
        );
    }

    private async onChangeUseBestContactAddressForBestLocation(newValue: boolean) {
        if (!newValue) {
            // We're unchecking the box. No extra data to update.
            this.props.onFieldChange("useBestContactAddressForBestLocation", newValue);
        } else if (!this.areBestLocationFieldsAllEmpty()) {
            const shouldOverwrite = await fancyConfirm(
                "Copy Best Contact's Address?",
                "This will copy the Best Contact's address to the Ideal Location and overwrite the address you have currently specified.",
                "Yes, copy",
                "Cancel",
            );
            if (shouldOverwrite) {
                this.copyBestLocationAddress();
            }
        } else {
            // The box is being checked, but nothing will get overwritten.
            // Just copy it now!
            this.copyBestLocationAddress();
        }
    }

    private idealLocationSection(): JSX.Element {
        return (
            <div className="input-form-block-no-bottom-margin">
                <div className="flex-space-between flex-centered-column flex-row">
                    <div className="flex-column">
                        <Header iconName="16_IdealLocation">Ideal Location</Header>
                    </div>
                    <div className="flex-column">
                        <CheckboxUncontrolled
                            initial={
                                this.props.client.useBestContactAddressForBestLocation
                            }
                            onChange={e =>
                                this.onChangeUseBestContactAddressForBestLocation(
                                    e.target.checked,
                                )
                            }
                            label="Use Best Contact's Address"
                        />
                    </div>
                </div>
                <div className="space-y-2 md:space-y-3">
                    {this.props.client.useBestContactAddressForBestLocation
                        ? this.idealLocationReadOnly()
                        : this.idealLocationInputFields()}

                    <RegionSelect
                        selectedRegionKey={this.props.client.region}
                        onChange={newVal => {
                            this.props.onFieldChange("region", newVal);
                            this.props.navigateToClients();
                        }}
                    />
                    <AutosavingInput
                        label="Location Notes / Other Preferences"
                        multiLine={true}
                        initial={this.props.client.otherLocationNotes}
                        onCommit={newVal =>
                            this.props.onFieldChange("otherLocationNotes", newVal)
                        }
                    />
                </div>
            </div>
        );
    }

    private leftColumnQuestions(): JSX.Element {
        return (
            <>
                {this.idealLocationSection()}
                <ClientQuestionSection
                    category={Common.QuestionCategories.finance}
                    clientId={this.props.client.id}
                />
                <InsuranceDetails
                    clientId={this.props.client.id}
                    onFieldChange={this.props.onFieldChange}
                />
                <ClientQuestionSection
                    category={Common.QuestionCategories.activity}
                    clientId={this.props.client.id}
                />
                <ClientQuestionSection
                    category={Common.QuestionCategories.otherDetails}
                    clientId={this.props.client.id}
                />
                <ClientQuestionSection
                    category={Common.QuestionCategories.socialInterests}
                    clientId={this.props.client.id}
                />
            </>
        );
    }

    private rightColumnQuestions(): JSX.Element {
        return (
            <>
                <ClientQuestionSection
                    category={Common.QuestionCategories.type}
                    clientId={this.props.client.id}
                />
                <ClientQuestionSection
                    category={Common.QuestionCategories.staff}
                    clientId={this.props.client.id}
                />
                <CareProviders clientId={this.props.client.id} />
                <ClientQuestionSection
                    category={Common.QuestionCategories.services}
                    clientId={this.props.client.id}
                />
                <ClientQuestionSection
                    category={Common.QuestionCategories.amenity}
                    clientId={this.props.client.id}
                />
            </>
        );
    }

    private needsTab() {
        return (
            <NgGrid>
                <NgGrid.Column>{this.leftColumnQuestions()}</NgGrid.Column>
                <NgGrid.Column>{this.rightColumnQuestions()}</NgGrid.Column>
            </NgGrid>
        );
    }

    private overviewTab() {
        return (
            <ClientOverviewTab
                client={this.props.client}
                onFieldChange={this.props.onFieldChange}
                onFieldChangeMulti={this.props.onFieldChangeMulti}
                onSendDisclosure={this.overlayClientDisclosureEmail}
            />
        );
    }

    private dialogs(): JSX.Element {
        const { overlayExperience } = this.state;
        if (overlayExperience === OverlayExperience.None) {
            return null;
        }
        if (overlayExperience === OverlayExperience.ShareLink) {
            return (
                <LiveCommunityComparisonLink
                    onDismiss={this.closeOverlay}
                    clientId={this.props.client.id}
                />
            );
        }
        if (overlayExperience === OverlayExperience.ClientIntakeFaxToCommunities) {
            return (
                <ClientIntakeFax
                    clientId={this.props.client.id}
                    onDismiss={this.closeOverlay}
                />
            );
        }
        if (overlayExperience === OverlayExperience.ClientIntakeDownload) {
            return (
                <ClientIntakeDownloadDialog
                    clientId={this.props.client.id}
                    onDismiss={this.closeOverlay}
                />
            );
        }
        if (overlayExperience === OverlayExperience.ClientIntakeEmailToCommunities) {
            return (
                <ClientIntakeEmailDialogToCommunity
                    client={this.props.client}
                    onDismiss={this.closeOverlay}
                />
            );
        }
        if (overlayExperience === OverlayExperience.CommunityComparisonDownload) {
            return (
                <CommunitiesComparePdfDialog
                    action={`/api/clients/${this.props.client.id}/communities/pdf`}
                    onDismiss={this.closeOverlay}
                />
            );
        }
        if (overlayExperience === OverlayExperience.CommunityComparisonEmail) {
            return (
                <MessageCompose
                    entityId={this.props.client.id}
                    entityType={AdvancedSearchEntityType.Client}
                    requestSpecialMessageExperience="community-comparison"
                    renderCommunityComparison={(
                        props: SpecialComposeCommmunityComparisonProps,
                    ) => <EmailCommunityComparisonCustomization {...props} />}
                    onDismiss={this.closeOverlay}
                />
            );
        }
        if (
            overlayExperience === OverlayExperience.GenericClientEmail ||
            overlayExperience === OverlayExperience.ClientDisclosureEmail
        ) {
            return (
                <ClientIntakeEmailDialogToClient
                    client={this.props.client}
                    onDismiss={this.closeOverlay}
                    includeDisclosure={
                        overlayExperience === OverlayExperience.ClientDisclosureEmail
                    }
                />
            );
        }
        if (overlayExperience === OverlayExperience.Signature) {
            return (
                <SignatureRequestPanel
                    fileEntityType={FileEntityType.Client}
                    entityId={this.props.client.id}
                    isOpen={true}
                    onDismiss={this.closeOverlay}
                />
            );
        }
        if (overlayExperience === OverlayExperience.TransferClient) {
            return (
                <TransferClientPanel
                    client={this.props.client}
                    onDismiss={this.closeOverlay}
                />
            );
        }
        if (overlayExperience === OverlayExperience.ShareInputFormLink) {
            return (
                <ShareInputFormLinkPanel
                    client={this.props.client}
                    onDismiss={this.closeOverlay}
                />
            );
        }

        const exhaustiveCheck: never = overlayExperience;
        throw new Error(
            `Unhandled overlay experience type: ${OverlayExperience[exhaustiveCheck]}`,
        );
    }

    render() {
        const activeTasks = this.props.tasks.filter(t => t.active()) || [];
        const anyOverdue = activeTasks.some(t => t.overdue()) || false;
        const subpages: SubnavItem[] = [
            {
                linkText: "Overview",
                subpage: "overview",
                render: this.overviewTab,
            },
            {
                linkText: "Intake Details",
                subpage: "needs",
                render: this.needsTab,
            },
            {
                linkText: "Find Ideal Communities",
                subpage: "communities",
                render: this.communityWorkflowFindIdealTab,
            },
            {
                linkText: "Community Options",
                subpage: "compareCommunities",
                render: this.communityWorkflowOptionsTab,
                badgeCount:
                    this.props.client?.clientCommunities?.filter(
                        cc =>
                            cc.relationship !== Common.CommunityRelationshipType.excluded,
                    ).length || undefined,
            },
            this.props.showReferralsTab
                ? {
                      linkText: `Referrals`,
                      subpage: "referrals",
                      render: this.referralsTab,
                  }
                : null,
            {
                linkText: this.props.faxEnabled ? "Messages" : `Emails Sent`,
                subpage: "emailsSent",
                render: this.emailsSentTab,
            },
            this.props.showInvoicesTab
                ? {
                      linkText: `Invoices`,
                      subpage: "invoices",
                      render: this.invoicesTab,
                  }
                : null,
            this.state.relatedClients.length > 0
                ? {
                      linkText: `Other Moves`,
                      badgeCount: this.state.relatedClients.length,
                      subpage: "moves",
                      render: this.movesTab,
                  }
                : null,
            {
                linkText: "Tasks",
                subpage: "tasks",
                render: this.tasksTab,
                badgeCount: activeTasks.length || undefined,
                badgeType: anyOverdue ? "error" : "info",
            } as SubnavItem,
            {
                linkText: "Files",
                subpage: "files",
                badgeCount: this.props.fileCount,
                render: this.filesTab,
            },
            {
                linkText: `Activity`,
                subpage: "activity",
                render: this.activityTab,
            },
        ].filter(x => !!x);

        const grayBg =
            !this.props.activeSubpage ||
            this.props.activeSubpage === "overview" ||
            this.props.activeSubpage === "communities" ||
            this.props.activeSubpage === "needs" ||
            this.props.activeSubpage === "activity" ||
            this.props.activeSubpage === "invoices" ||
            this.props.activeSubpage === "compareCommunities";
        return (
            <div>
                {this.state.taskAlertsEnabled ? (
                    <NoActiveTasksPrompt
                        tasks={this.props.tasks}
                        tasksLoaded={this.props.tasksLoaded}
                        client={this.props.client}
                    />
                ) : null}
                <div className="row show-client-control">
                    <div className="col component-wrapper">
                        {this.headerBar()}
                        {this.duplicateWarning()}
                        {this.archiveBar()}
                        {this.dialogs()}
                        <ShowClientActionBar
                            reactivate={this.props.reactivate}
                            client={this.props.client}
                            goToTab={(section: Subpage) =>
                                this.props.selectSection(section)
                            }
                        />
                        <div>
                            <div
                                className={`col main-body full-width ${
                                    grayBg ? "bg-gray-100 min-h-screen" : ""
                                }`}>
                                <Shared.Nav.Subnav
                                    navigate={this.props.selectSection}
                                    selectedSubpage={this.props.activeSubpage}
                                    subpages={subpages}
                                    closeSection={this.props.closeSection}
                                    defaultSubpage="overview"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ClientMovesData
                    clientId={this.props.client.id}
                    sourceId={this.props.client.sourceId as ClientId}
                    onSuccess={relatedClients =>
                        this.setState({
                            ...this.state,
                            relatedClients,
                        })
                    }
                />
                <QueryInvalidator ref={this.invalidatorRef} />
            </div>
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: ShowClientOwnProps,
): ShowClientProps {
    return {
        ...ownProps,
        showInvoicesTab:
            isAllowed(ActionType.ViewOwnInvoices, state.permissions, state.region) ||
            isAllowed(ActionType.ViewAllInvoices, state.permissions, state.region),
        showReferralsTab: isAllowed(
            ActionType.LoadReferralsForEntity,
            state.permissions,
            state.region,
        ),
        faxEnabled: isFeatureEnabled(state, "fax"),
        locale: currentLocale(state),
        disclosureEnabled: isFeatureEnabled(state, "clientDisclosures"),
        shareInputFormLinkEnabled:
            isFeatureEnabled(state, "hostedIntakeFormExisting") &&
            isAllowed(ActionType.ReadHostedForm, state.permissions, state.region),
    };
}

const component = connect(mapStateToProps)(ShowClient);
export { component as ShowClient };
