import { formatEntityValue, LayoutSectionParentKey } from "@sp-crm/core";
import { CommunityCardAnswer } from "components/community-search/community-card/community-card-answer";
import { LayoutItems } from "components/layout/layout-items";
import {
    AdvancedSearchEntityType,
    ReferenceContactSearchQuery,
    useGetLayoutContainersByKeyQuery,
} from "generated/graphql";
import React, { useMemo } from "react";
import { useProductName } from "store/selectors/branding";
import { useBridgeQuestionsForCurrentRegion } from "store/selectors/bridge";
import { useRegions, useVisibleUsers } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";

interface ReferenceContactSearchCardAnswersProps {
    contact: ReferenceContactSearchQuery["referenceContactSearch"]["hits"][0];
}

export const ReferenceContactSearchCardAnswers: React.FC<
    ReferenceContactSearchCardAnswersProps
> = props => {
    const { contact } = props;

    const users = useVisibleUsers();
    const regions = useRegions();
    const productName = useProductName();

    const layoutContainerKeys = useMemo(() => {
        const keys = [];

        if (contact.businessId) {
            keys.push(LayoutSectionParentKey.ReferenceBusiness);
        }

        if (contact.communityId) {
            keys.push(LayoutSectionParentKey.ReferralCommunityCard);
        }

        keys.push(LayoutSectionParentKey.ReferenceContact);

        return keys;
    }, [contact.businessId, contact.communityId]);

    const layoutContainers = useGetLayoutContainersByKeyQuery(
        {
            keys: layoutContainerKeys,
        },
        stableQueryOptions(),
    );

    const layoutItems = useMemo(() => {
        const items = layoutContainers.data?.getLayoutContainersByKey.flatMap(c =>
            c.layoutSections.flatMap(s => s.layoutItems),
        );

        return items || [];
    }, [layoutContainers.data]);

    const contactQuestions = useBridgeQuestionsForCurrentRegion(
        AdvancedSearchEntityType.ReferenceContact,
    );
    const communityQuestions = useBridgeQuestionsForCurrentRegion(
        AdvancedSearchEntityType.Community,
    );
    const businessQuestions = useBridgeQuestionsForCurrentRegion(
        AdvancedSearchEntityType.ReferenceBusiness,
    );

    const questions = useMemo(() => {
        const allQuestions = [
            ...contactQuestions,
            ...communityQuestions,
            ...businessQuestions,
        ];
        return allQuestions.filter(q => layoutItems.some(i => i.questionId === q.id));
    }, [contactQuestions, communityQuestions, businessQuestions, layoutItems]);

    return (
        <LayoutItems
            getAnswers={() => contact.organizationAnswers.concat(contact.answers)}
            renderAnswer={(q, a) => (
                <CommunityCardAnswer question={q} answer={a} users={users} />
            )}
            renderStandardField={(
                contact: ReferenceContactSearchQuery["referenceContactSearch"]["hits"][0],
                fieldName,
                fieldDefinition,
            ) => {
                const formatted = formatEntityValue(contact, fieldName, fieldDefinition, {
                    regions,
                    users,
                    productName,
                });

                return formatted ? <p className="line-clamp-6">{formatted}</p> : null;
            }}
            questions={questions}
            layoutItems={layoutItems}
            fieldMetadataList={[]}>
            {(layoutItems, getTitle, renderValue) => (
                <>
                    {layoutItems.map(layoutItem => {
                        const value = renderValue(contact, layoutItem);

                        return value || layoutItem.showIfBlank ? (
                            <div key={layoutItem.id} className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    {getTitle(layoutItem)}
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900">
                                    {value}
                                </dd>
                            </div>
                        ) : null;
                    })}
                </>
            )}
        </LayoutItems>
    );
};
