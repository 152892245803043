import * as Common from "@sp-crm/core";
import {
    CalendarDate,
    CalendarDateTime,
    getCalendarDateTime,
    ITaskBase,
    UserId,
} from "@sp-crm/core";
import { UserSelect } from "components/shared/user-select";
import { CalendarDateTimeInput } from "components/ui/calendar-date-time";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import { TextArea } from "components/ui/textarea";
import { GetTaskTypesQuery } from "generated/graphql";
import * as React from "react";

interface TaskFormProps {
    onSaveTask: (
        assignedUserId: UserId,
        text: string,
        notes: string,
        dueDate: CalendarDate | null,
        dueDateTime: Date | null,
        taskTypeId: Common.TaskTypeId | null,
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onCancelTaskChanges: (event?: any) => void;
    task: ITaskBase;
    saveButtonLabel: string;
    users: Common.User[];
    formHeader?: string;
    taskTypes: GetTaskTypesQuery["getTaskTypes"];
}

interface TaskFormState {
    taskText: string; // this is really the task Title
    taskNotes: string;
    isDirty: boolean;
    assignedTo: UserId;
    dueDate: CalendarDate | null;
    dueDateTime: Date | null;
    isCancelRequested: boolean;
    taskTypeId: null | Common.TaskTypeId;
}

export class TaskForm extends React.Component<TaskFormProps, TaskFormState> {
    constructor(props: TaskFormProps) {
        super(props);

        this.state = {
            taskText: props.task.text,
            taskNotes: props.task.notes,
            isDirty: false,
            dueDate: props.task.dueDate,
            dueDateTime: props.task.dueDateTime,
            assignedTo: props.task.ownerId,
            isCancelRequested: false,
            taskTypeId: props.task.taskTypeId,
        };
    }

    private async requestCancel() {
        if (!this.state.isDirty) {
            this.props.onCancelTaskChanges();
        } else {
            const shouldCancel = await fancyConfirm(
                "Abandon Task Edits?",
                "You began creating or editing this task. Are you sure you want to abandon the changes you've made?",
                "Yes, Abandon",
                "Never mind",
            );
            if (shouldCancel) {
                this.props.onCancelTaskChanges();
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    private submit(e: any) {
        if (e) {
            e.preventDefault();
        }
        this.props.onSaveTask(
            this.state.assignedTo,
            this.state.taskText,
            this.state.taskNotes,
            this.state.dueDate,
            this.state.dueDateTime,
            this.state.taskTypeId,
        );
        this.setState({
            taskText: "",
            taskNotes: "",
            isDirty: false,
            dueDate: CalendarDate.todayNoTime,
            dueDateTime: null,
            taskTypeId: null,
            assignedTo: this.props.task.ownerId,
        });
    }

    private changeText(title: string): void {
        this.setState({
            ...this.state,
            taskText: title,
            isDirty: true,
        });
    }

    private changeTypeId(taskTypeId: null | Common.TaskTypeId): void {
        this.setState({
            taskTypeId: taskTypeId,
            isDirty: true,
        });
    }

    private changeNotes(notes: string): void {
        this.setState({
            ...this.state,
            taskNotes: notes,
            isDirty: true,
        });
    }

    private changeDate(newDate: CalendarDateTime): void {
        this.setState({
            ...this.state,
            dueDate: newDate.value.hasTime === false ? newDate.value.date : null,
            dueDateTime: newDate.value.hasTime === true ? newDate.value.date : null,
            isDirty: true,
        });
    }

    private changeAssignedTo(assignedTo: UserId): void {
        if (!assignedTo) {
            assignedTo = null;
        }
        this.setState({
            ...this.state,
            assignedTo: assignedTo,
            isDirty: true,
        });
    }

    private getDateValue(): CalendarDateTime {
        const val = this.state.isDirty
            ? getCalendarDateTime(this.state.dueDate, this.state.dueDateTime)
            : getCalendarDateTime(this.props.task.dueDate, this.props.task.dueDateTime);
        return val;
    }

    private getValue(): string {
        const val = this.state.isDirty ? this.state.taskText : this.props.task.text;
        if (!val) {
            return "";
        }
        return val;
    }

    private getNotes(): string {
        const val = this.state.isDirty ? this.state.taskNotes : this.props.task.notes;
        if (!val) {
            return "";
        }
        return val;
    }

    private getAssignedTo(): string {
        return this.state.assignedTo || "";
    }

    private getTaskTypeOptions(): JSX.Element[] {
        return this.props.taskTypes
            .slice()
            .filter(
                t =>
                    !t.isArchived ||
                    t.id === this.state.taskTypeId ||
                    t.id === this.props.task.taskTypeId,
            )
            .sort((a, b) =>
                a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
            )
            .map(t => (
                <option key={t.id} value={t.id}>
                    {t.isArchived
                        ? `${t.name ?? "(unnamed)"} (Archived)`
                        : t.name ?? "(unnamed)"}
                </option>
            ));
    }

    render() {
        return (
            <div>
                {this.props.formHeader ? <h3>{this.props.formHeader}</h3> : null}
                <form className="space-y-2 lg:space-y-4" onSubmit={e => this.submit(e)}>
                    <div>
                        <Input
                            label="Title"
                            value={this.getValue()}
                            onChange={e => this.changeText(e.target.value)}
                        />
                    </div>
                    <div>
                        <CalendarDateTimeInput
                            label="Due"
                            value={this.getDateValue()}
                            onChange={e => this.changeDate(e)}
                        />
                    </div>
                    <div>
                        <Select
                            value={this.state.taskTypeId ?? ""}
                            onChange={e =>
                                this.changeTypeId(
                                    (e.target.value as Common.TaskTypeId) || null,
                                )
                            }
                            label="Task type">
                            <option value=""></option>
                            {this.getTaskTypeOptions()}
                        </Select>
                    </div>
                    <div>
                        <UserSelect
                            includeUnassigned={false}
                            includeEveryone={false}
                            label="Assigned to"
                            value={this.getAssignedTo() as UserId}
                            onChange={v => this.changeAssignedTo(v as UserId)}
                        />
                    </div>
                    <div>
                        <TextArea
                            label="Notes"
                            rows={5}
                            value={this.getNotes()}
                            onChange={e => this.changeNotes(e.target.value)}
                            autoGrow
                        />
                    </div>
                </form>
                <div>
                    <div className="save-cancel-buttons flex space-x-2">
                        <SecondaryButton onClick={() => this.requestCancel()}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton onClick={() => this.submit(null)}>
                            {this.props.saveButtonLabel}
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        );
    }
}
