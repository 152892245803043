import { globalFilesEntityId } from "@sp-crm/core";
import { Col, Row } from "components/shared/grid";
import { SearchInput } from "components/ui/search-input";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filesDashboardSlice } from "store/slices/files-dashboard";
import { ApplicationState } from "store/state";
import { Content, SectionHeader, Stage } from "../layout";
import { FilesNavigation } from "./files-navigation";
import { FilesControl } from "./index";

export const StandaloneFiles: React.FC<unknown> = () => {
    const { updateQuery } = filesDashboardSlice.actions;
    const dispatch = useDispatch();
    const onUpdateQuery = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(updateQuery([globalFilesEntityId, e.target.value])),
        [dispatch, updateQuery],
    );
    const taskFilter = useSelector(
        (state: ApplicationState) =>
            state.filesDashboard[globalFilesEntityId] || { query: "" },
    );

    return (
        <Row type="flex">
            <Col className="component-wrapper">
                <Row className="multi-page-nav">
                    <Col>
                        <FilesNavigation />
                    </Col>
                </Row>
                <Stage>
                    <SectionHeader title="" />
                    <Content>
                        <div className="flex space-x-2 items-center justify-between">
                            <div className="flex-1 max-w-lg">
                                <SearchInput
                                    label="Search"
                                    type="text"
                                    onChange={onUpdateQuery}
                                    value={taskFilter.query ?? ""}
                                    placeholder="File name"
                                />
                            </div>
                        </div>
                    </Content>
                    <div className="mt-2 lg:mt-4">
                        <Content>
                            <FilesControl
                                includeSignwise={false}
                                entityId={globalFilesEntityId}
                            />
                        </Content>
                    </div>
                </Stage>
            </Col>
        </Row>
    );
};
