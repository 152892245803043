import { SearchGeoParamsCallout } from "components/community-search/search-bar/search-geo-params-callout";
import { useDebouncedState } from "components/shared/hooks";
import { SearchInput } from "components/ui/search-input";
import { SearchGeoParams } from "generated/graphql";
import React, { useEffect, useMemo } from "react";
import { AgentDirectoryFilter } from "store/slices/agent-directory";

interface AgentDirectorySearchBarProps {
    value: AgentDirectoryFilter;
    onChange: (filter: AgentDirectoryFilter) => void;
    onResetMapView: () => void;
}

export const AgentDirectorySearchBar: React.FC<AgentDirectorySearchBarProps> = props => {
    const { onResetMapView, value, onChange } = props;

    const [searchTerm, setSearchTerm, searchTermDebounced] = useDebouncedState(
        value.search,
        400,
    );

    useEffect(() => {
        if (searchTermDebounced !== value.search) {
            onChange({
                ...value,
                search: searchTermDebounced,
            });
        }
    }, [searchTermDebounced]); // eslint-disable-line react-hooks/exhaustive-deps

    const showClearButton = useMemo(() => {
        return value.search.length > 0;
    }, [value.search.length]);

    const handleGeoParamsChanged = (geoParams: SearchGeoParams | null) => {
        onChange({
            ...value,
            geoParams,
        });
    };

    return (
        <div className="flex items-end space-x-4 flex-wrap">
            <div className="flex-1 min-w-32 max-w-lg">
                <SearchInput
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    placeholder="Search by name or email"
                />
            </div>
            <div className="flex items-center space-x-4 pb-2">
                <SearchGeoParamsCallout
                    value={value.geoParams}
                    onChange={handleGeoParamsChanged}
                    onResetMapView={onResetMapView}
                />
                {showClearButton ? (
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            setSearchTerm("");
                            onChange({
                                search: "",
                                geoParams: null,
                            });
                        }}>
                        Clear all filters
                    </a>
                ) : null}
            </div>
        </div>
    );
};
