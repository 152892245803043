import { formatEntityValue, LayoutSectionParentKey } from "@sp-crm/core";
import { CommunityCardAnswer } from "components/community-search/community-card/community-card-answer";
import { LayoutItems } from "components/layout/layout-items";
import {
    AdvancedSearchEntityType,
    ReferenceOrganizationSearchQuery,
    useGetLayoutContainersByKeyQuery,
} from "generated/graphql";
import React, { useMemo } from "react";
import { useProductName } from "store/selectors/branding";
import {
    useBridgeEntityType,
    useBridgeQuestionsForCurrentRegion,
} from "store/selectors/bridge";
import { useRegions, useVisibleUsers } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";

interface ReferenceOrganizationSearchCardAnswersProps {
    organization: ReferenceOrganizationSearchQuery["referenceOrganizationSearch"]["hits"][0];
}

export const ReferenceOrganizationSearchCardAnswers: React.FC<
    ReferenceOrganizationSearchCardAnswersProps
> = props => {
    const { organization } = props;

    const users = useVisibleUsers();
    const regions = useRegions();
    const productName = useProductName();

    const layoutContainerKeys = useMemo(() => {
        const keys = [];

        if (organization.entityType === AdvancedSearchEntityType.ReferenceBusiness) {
            keys.push(LayoutSectionParentKey.ReferenceBusiness);
        }

        if (organization.entityType === AdvancedSearchEntityType.Community) {
            keys.push(LayoutSectionParentKey.ReferralCommunityCard);
        }

        return keys;
    }, [organization.entityType]);

    const layoutContainers = useGetLayoutContainersByKeyQuery(
        {
            keys: layoutContainerKeys,
        },
        stableQueryOptions(),
    );

    const layoutItems = useMemo(() => {
        const items = layoutContainers.data?.getLayoutContainersByKey.flatMap(c =>
            c.layoutSections.flatMap(s => s.layoutItems),
        );

        return items || [];
    }, [layoutContainers.data]);

    const { entityType: communityType } = useBridgeEntityType(
        AdvancedSearchEntityType.Community,
    );

    const communityQuestions = useBridgeQuestionsForCurrentRegion(
        AdvancedSearchEntityType.Community,
    );
    const businessQuestions = useBridgeQuestionsForCurrentRegion(
        AdvancedSearchEntityType.ReferenceBusiness,
    );

    const questions = useMemo(() => {
        const allQuestions = [...communityQuestions, ...businessQuestions];
        return allQuestions.filter(q => layoutItems.some(i => i.questionId === q.id));
    }, [communityQuestions, businessQuestions, layoutItems]);

    const fields = useMemo(() => {
        if (
            communityType &&
            organization.entityType === AdvancedSearchEntityType.Community
        ) {
            return communityType.fields;
        }

        return [];
    }, [communityType, organization.entityType]);

    return (
        <LayoutItems
            getAnswers={() => organization.answers}
            renderAnswer={(q, a) => (
                <CommunityCardAnswer question={q} answer={a} users={users} />
            )}
            renderStandardField={(
                organization: ReferenceOrganizationSearchQuery["referenceOrganizationSearch"]["hits"][0],
                fieldName,
                fieldDefinition,
            ) => {
                const formatted = formatEntityValue(
                    organization,
                    fieldName,
                    fieldDefinition,
                    {
                        regions,
                        users,
                        productName,
                    },
                );

                return formatted ? <p className="line-clamp-6">{formatted}</p> : null;
            }}
            questions={questions}
            layoutItems={layoutItems}
            fieldMetadataList={fields}>
            {(layoutItems, getTitle, renderValue) => (
                <>
                    {layoutItems.map(layoutItem => {
                        const value = renderValue(organization, layoutItem);

                        return value || layoutItem.showIfBlank ? (
                            <div key={layoutItem.id} className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    {getTitle(layoutItem)}
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900">
                                    {value}
                                </dd>
                            </div>
                        ) : null;
                    })}
                </>
            )}
        </LayoutItems>
    );
};
