import { CrmTablePagination } from "components/table/pagination";
import React, { useCallback } from "react";

export interface ISearchRequest {
    page: number;
    perPage: number;
    sort: string;
    sortDirection: string;
}

interface SearchRequestPaginationProps {
    request: ISearchRequest;
    total: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
}

export const SearchRequestPagination: React.FC<SearchRequestPaginationProps> = props => {
    const { request, total, onPageChange, onPageSizeChange } = props;
    const pageCount = Math.ceil(total / request.perPage);

    const handleNextPage = useCallback(() => {
        onPageChange(request.page + 1);
    }, [request.page, onPageChange]);

    const handlePreviousPage = useCallback(() => {
        onPageChange(request.page - 1);
    }, [request.page, onPageChange]);

    return (
        <CrmTablePagination
            pageIndex={request.page}
            count={total}
            pageSize={request.perPage}
            pageCount={pageCount}
            canNextPage={request.page < pageCount - 1}
            canPreviousPage={request.page > 0}
            nextPage={handleNextPage}
            previousPage={handlePreviousPage}
            gotoPage={onPageChange}
            setPageSize={onPageSizeChange}
        />
    );
};
