import { AdvancedSearchDownload } from "components/advanced-search/advanced-search-download";
import { AdvancedSearchInput } from "components/advanced-search/advanced-search-input";
import { AdvancedSearchResults } from "components/advanced-search/advanced-search-results";
import { AdvancedSearchSelect } from "components/advanced-search/advanced-search-select";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentContainer, ContentFull, SectionHeader } from "components/layout";
import { AdvancedSearchRequest, useGetEntitiesQuery } from "generated/graphql";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { customReportsSlice } from "store/slices/custom-reports";
import { ApplicationState } from "store/state";
import { stableQueryOptions } from "util/requests";
import { handleEvent } from "util/user-events";

interface Props {
    canSaveAdditional: boolean;
}

export const CustomReportsNew: React.FC<Props> = props => {
    const { canSaveAdditional } = props;
    const entitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());

    const dispatch = useAppDispatch();
    const { setNewReportInput } = customReportsSlice.actions;

    const cacheNewCustomReportInput = useCallback(
        (input: AdvancedSearchRequest) => {
            dispatch(setNewReportInput(input));
        },
        [dispatch, setNewReportInput],
    );

    const searchInputWorkingCopy = useAppSelector(
        (state: ApplicationState) => state.customReports.newReportInput,
    );
    const [committedSearchInput, setCommittedSearchInput] =
        useState<AdvancedSearchRequest>(searchInputWorkingCopy);

    useEffect(() => {
        handleEvent("view-adhoc-report-results");
    }, [committedSearchInput]);

    return (
        <QueryRenderer query={entitiesQuery} name="CustomReportsNew.getEntities">
            {data => (
                <div className="flex-1 min-w-0">
                    <SectionHeader title="Custom report">
                        <div className="flex items-center space-x-3">
                            <AdvancedSearchDownload
                                searchRequest={committedSearchInput}
                            />
                            {searchInputWorkingCopy?.select ? (
                                <AdvancedSearchSelect
                                    request={searchInputWorkingCopy}
                                    entities={data.getEntities}
                                    entityType={searchInputWorkingCopy.entityType}
                                    onChange={setCommittedSearchInput}
                                />
                            ) : null}
                        </div>
                    </SectionHeader>
                    <ContentContainer className="space-y-2 lg:space-y-4">
                        <AdvancedSearchInput
                            onCommit={setCommittedSearchInput}
                            entities={data.getEntities}
                            onChange={cacheNewCustomReportInput}
                            initial={searchInputWorkingCopy}
                            committed={committedSearchInput}
                            canSaveAdditional={canSaveAdditional}
                        />
                        {committedSearchInput ? (
                            <ContentFull>
                                <AdvancedSearchResults
                                    onChangeRequest={setCommittedSearchInput}
                                    request={committedSearchInput}
                                    includeIconLinks={true}
                                    includeSummaries={true}
                                />
                            </ContentFull>
                        ) : null}
                    </ContentContainer>
                </div>
            )}
        </QueryRenderer>
    );
};
