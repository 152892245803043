import { Maybe } from "@sp-crm/core";
import { Select } from "components/ui/select";
import * as React from "react";
import { useRegions } from "store/selectors/hooks";

interface RegionSelectProps {
    selectedRegionKey: string;
    onChange: (newKey: string) => void;
}

export const RegionSelect: React.FC<RegionSelectProps> = ({
    selectedRegionKey,
    onChange,
}) => {
    const regions = useRegions();
    const selectedRegion = regions.find(r => r.key === selectedRegionKey);

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(e.target.value);
        },
        [onChange],
    );

    const selectedKey = selectedRegion?.key ?? null;

    if (
        Maybe.fromValue(regions)
            .map(r => r.length)
            .getOrElse(0) <= 1
    ) {
        return null;
    }

    return (
        <div className="region-select">
            <Select label="Office" value={selectedKey} onChange={handleChange}>
                {[...regions]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(r => (
                        <option key={r.key} value={r.key}>
                            {r.name}
                        </option>
                    ))}
            </Select>
        </div>
    );
};
