import { ClientId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import React, { useMemo } from "react";
import { useRegionSettings } from "store/selectors/settings";
import { stableQueryOptions } from "util/requests";
import {
    InvoiceCreateExperience,
    useGetInvoiceCreateExperienceQuery,
} from "../../generated/graphql";
import { InvoiceInput } from "../clients/invoices";
import { InvoiceCreateExternal } from "./invoice-create-external";
import { InvoiceCreateInternal } from "./invoice-create-internal";

interface Props {
    clientId: ClientId;
    invoiceInput: InvoiceInput;
    refetch: () => void;
}

export const InvoiceCreate: React.FC<Props> = props => {
    const invoiceCreateExperience = useGetInvoiceCreateExperienceQuery(
        {},
        stableQueryOptions(),
    );

    const { settings } = useRegionSettings();

    const defaultDueDate = useMemo(() => {
        if (
            settings &&
            settings.defaultDaysBeforeInvoiceDue &&
            props.invoiceInput?.moveDate
        ) {
            return props.invoiceInput.moveDate.addDays(
                settings.defaultDaysBeforeInvoiceDue,
            );
        }

        return null;
    }, [settings, props.invoiceInput]);

    if (!settings) {
        return null;
    }

    return (
        <QueryRenderer query={invoiceCreateExperience} name="InvoiceCreate">
            {data =>
                data.getInvoiceCreateExperience.experience ===
                InvoiceCreateExperience.External ? (
                    <InvoiceCreateExternal {...props} defaultDueDate={defaultDueDate} />
                ) : (
                    <InvoiceCreateInternal
                        {...props}
                        defaultDueDate={defaultDueDate}
                        defaultNotes={settings.invoiceDefaultNotes}
                    />
                )
            }
        </QueryRenderer>
    );
};
