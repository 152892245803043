import {
    ClientIntakeMessage,
    FileEntityId,
    IClient,
    InternalId,
    globalFilesEntityId,
} from "@sp-crm/core";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendClientIntakeEmail } from "store/actions/email";
import { loadFilesAction } from "store/actions/files";
import { useFeature, usePreferences, useUserContacts } from "store/selectors/hooks";
import { useTenantSettings } from "store/selectors/settings";
import { ApplicationState } from "store/state";
import {
    useComposeMessageQuery,
    useGetClientForIntakeEmailQuery,
} from "../../../../generated/graphql";
import { ClientIntakeEmailDialogInternal } from "./email-dialog";

const staleTime = 1000 * 60 * 10; // 10 minutes

interface ClientIntakeEmailDialogToClientProps {
    client: IClient;
    onDismiss: () => void;
    includeDisclosure: boolean;
}

export const ClientIntakeEmailDialogToClient = (
    props: ClientIntakeEmailDialogToClientProps,
) => {
    const query = useComposeMessageQuery(
        {
            entityId: props.client.id,
            specialMessageExperience: "",
        },
        {
            staleTime,
            cacheTime: 0,
        },
    );
    const requests = useSelector((state: ApplicationState) => state.status.events);
    const disclosureEnabled = useFeature("clientDisclosures");
    const { settings } = useTenantSettings();
    const preferences = usePreferences();
    const dispatch = useDispatch();
    const loadFiles = useCallback(
        (entityId: FileEntityId) => {
            loadFilesAction(entityId, dispatch);
        },
        [dispatch],
    );
    const send = useCallback(
        (eventId: InternalId, data: ClientIntakeMessage) =>
            sendClientIntakeEmail(eventId, data, dispatch),
        [dispatch],
    );
    const files = useSelector((state: ApplicationState) => {
        return [
            {
                entityName: "Our Files",
                files: (state.files.files[globalFilesEntityId] || []).filter(
                    x => x.shareWithClient,
                ),
            },
            {
                entityName: props.client.preferredClientName,
                files: (state.files.files[props.client.id] || []).filter(
                    x => x.shareWithClient,
                ),
            },
        ];
    });

    const userContacts = useUserContacts();

    if (props.client && query.isSuccess && query.data && query.data.composeMessage) {
        return (
            <ClientIntakeEmailDialogInternal
                clientOnly={true}
                disclosureEnabled={disclosureEnabled}
                clientDisclosureFileId={settings.clientDisclosureFileId}
                recipients={query.data.composeMessage.recipients}
                userPreferences={preferences}
                bulkEmailEnabled={false}
                inClientIntakeWorkflow={false}
                files={files}
                loadFiles={loadFiles}
                send={send}
                client={props.client}
                clientCommunities={[]}
                requests={requests}
                initialTemplateId={
                    props.includeDisclosure && disclosureEnabled
                        ? settings.clientDisclosureSendTemplateId
                        : null
                }
                clientId={props.client.id}
                includeDisclosure={props.includeDisclosure}
                onDismiss={props.onDismiss}
                userContacts={userContacts}
            />
        );
    }

    return <></>;
};

interface ClientIntakeEmailDialogToCommunityProps {
    client: IClient;
    onDismiss: () => void;
}

export const ClientIntakeEmailDialogToCommunity = (
    props: ClientIntakeEmailDialogToCommunityProps,
) => {
    const clientIntakeQuery = useGetClientForIntakeEmailQuery(
        {
            clientId: props.client.id,
        },
        {
            cacheTime: 0,
        },
    );
    const requests = useSelector((state: ApplicationState) => state.status.events);
    const { settings } = useTenantSettings();
    const preferences = usePreferences();
    const dispatch = useDispatch();
    const loadFiles = useCallback(
        (entityId: FileEntityId) => {
            loadFilesAction(entityId, dispatch);
        },
        [dispatch],
    );
    const send = useCallback(
        (eventId: InternalId, data: ClientIntakeMessage) =>
            sendClientIntakeEmail(eventId, data, dispatch),
        [dispatch],
    );
    const client = props.client;

    const files = useSelector((state: ApplicationState) => {
        return [
            {
                entityName: "Our Files",
                files: (state.files.files[globalFilesEntityId] || []).filter(
                    x => x.shareWithClient,
                ),
            },
            {
                entityName: client.preferredClientName,
                files: (state.files.files[client.id] || []).filter(
                    x => x.shareWithClient,
                ),
            },
        ];
    });

    const userContacts = useUserContacts();

    if (
        client &&
        clientIntakeQuery.isSuccess &&
        clientIntakeQuery.data &&
        clientIntakeQuery.data.getClient
    ) {
        return (
            <ClientIntakeEmailDialogInternal
                clientCommunities={clientIntakeQuery.data.getClient.clientCommunities}
                clientOnly={false}
                disclosureEnabled={false}
                clientDisclosureFileId={settings.clientDisclosureFileId}
                userPreferences={preferences}
                bulkEmailEnabled={preferences.useBulkCommunityEmail}
                inClientIntakeWorkflow={true}
                includeDisclosure={false}
                files={files}
                loadFiles={loadFiles}
                send={send}
                requests={requests}
                client={client}
                clientId={client.id}
                onDismiss={props.onDismiss}
                userContacts={userContacts}
            />
        );
    }

    return <></>;
};
