import { ContentFull, ContentHeader } from "components/layout";
import { InlineEmailTemplateSetting } from "components/templates/inline-email-template-setting";
import { AdvancedSearchEntityType } from "generated/graphql";
import React from "react";
import { useTenantSettings } from "store/selectors/settings";

export const AgencySettingsSignWise: React.FC<unknown> = () => {
    const { settings, putSetting } = useTenantSettings();

    return (
        <>
            <ContentFull>
                <ContentHeader>SignWise</ContentHeader>
                <div className="space-y-4">
                    <div>
                        <ContentHeader>Signwise request email</ContentHeader>
                        <InlineEmailTemplateSetting
                            subjectLabel="SignWise request default email subject"
                            bodyLabel="SignWise request default email body"
                            value={settings.signatureRequestInitialTemplateId}
                            onChange={id => {
                                putSetting("signatureRequestInitialTemplateId", id);
                            }}
                            messageType="SignatureRequest"
                            includeAttachments
                            placeholderTypes={[
                                AdvancedSearchEntityType.Client,
                                AdvancedSearchEntityType.Community,
                                AdvancedSearchEntityType.ReferenceContact,
                                AdvancedSearchEntityType.ReferenceBusiness,
                            ]}
                        />
                    </div>
                    <div>
                        <ContentHeader>Signwise completed email</ContentHeader>
                        <InlineEmailTemplateSetting
                            subjectLabel="SignWise completed document email subject"
                            bodyLabel="SignWise completed document email body"
                            attachmentLabel="Additional attachments"
                            value={settings.signatureRequestCompleteTemplateId}
                            onChange={id => {
                                putSetting("signatureRequestCompleteTemplateId", id);
                            }}
                            includeAttachments
                        />
                    </div>
                </div>
            </ContentFull>
        </>
    );
};
