import { TenantBooleanSettingsFields } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import React from "react";
import { useTenantSettings } from "store/selectors/settings";

interface AgencyCheckboxPrefProps {
    description?: string;
    field: TenantBooleanSettingsFields;
    label: string;
}

export const AgencyCheckboxPref: React.FC<AgencyCheckboxPrefProps> = props => {
    const { description, field, label } = props;
    const { settings, putSetting } = useTenantSettings();
    const onCommitCallback = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            putSetting(field, e.target.checked);
        },
        [field, putSetting],
    );
    return (
        <div className="group">
            <Checkbox
                checked={settings[field] as boolean}
                onChange={onCommitCallback}
                label={label}
            />
            {description ? (
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    {description}
                </div>
            ) : null}
        </div>
    );
};
