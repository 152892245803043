import { CalendarDate, IClient } from "@sp-crm/core";
import { Header } from "components/header";
import { AutosavingInput } from "components/ui/autosaving-input";
import { CalendarDateInput } from "components/ui/calendar-date";
import { Checkbox } from "components/ui/checkbox";
import React from "react";
import { useFeature } from "store/selectors/hooks";
import { ClientDisclosureSummary } from "./client-disclosure-summary";

interface ClientAgreementSectionProps {
    client: IClient;
    onFieldChange: (fieldName: string, newValue: string | boolean) => void;
    onSendDisclosure: () => void;
}

export const ClientAgreementSection: React.FC<ClientAgreementSectionProps> = props => {
    const { client, onFieldChange, onSendDisclosure } = props;

    const disclosureEnabled = useFeature("clientDisclosures");

    return (
        <div className="input-form-block-no-bottom-margin">
            <Header iconName="44_SignedAgreement">Client agreement</Header>
            <div className="space-y-4">
                <div>
                    <Header headerSize="minor">Prior history</Header>
                    <div className="vertically-spaced-children">
                        <Checkbox
                            checked={client.contactedOtherAgency}
                            onChange={e =>
                                onFieldChange("contactedOtherAgency", e.target.checked)
                            }
                            label="Already contacted another agency"
                        />
                        <Checkbox
                            checked={client.touredPreviously}
                            onChange={e =>
                                onFieldChange("touredPreviously", e.target.checked)
                            }
                            label="Toured prior to contacting us"
                        />
                        {client.contactedOtherAgency ? (
                            <AutosavingInput
                                multiLine={true}
                                label="Other Agencies Contacted (Notes)"
                                initial={client.contactedOtherAgencyNotes}
                                onCommit={newVal =>
                                    onFieldChange("contactedOtherAgencyNotes", newVal)
                                }
                            />
                        ) : null}
                        {client.touredPreviously ? (
                            <AutosavingInput
                                multiLine={true}
                                label="Prior Tour Notes"
                                initial={client.touredPreviouslyNotes}
                                onCommit={newVal =>
                                    onFieldChange("touredPreviouslyNotes", newVal)
                                }
                            />
                        ) : null}
                    </div>
                </div>
                {disclosureEnabled ? (
                    <ClientDisclosureSummary
                        clientId={client.id}
                        mailShowing={false}
                        onSendDisclosure={onSendDisclosure}
                    />
                ) : (
                    <div className="vertically-spaced-children">
                        <Header headerSize="minor">Agency agreement / disclosure</Header>
                        <div className="vertically-spaced-children">
                            <Checkbox
                                checked={client.signedAgencyAgreement}
                                onChange={e =>
                                    onFieldChange(
                                        "signedAgencyAgreement",
                                        e.target.checked,
                                    )
                                }
                                label="Signed agreement / received our disclosure"
                            />
                            {client.signedAgencyAgreement ? (
                                <>
                                    <div>
                                        <CalendarDateInput
                                            label="Date signed"
                                            initial={client.agencyAgreementDate}
                                            onChange={(newVal: CalendarDate) =>
                                                onFieldChange(
                                                    "agencyAgreementDate",
                                                    newVal.toString(),
                                                )
                                            }
                                        />
                                    </div>
                                    <AutosavingInput
                                        label="Agreement notes"
                                        multiLine={true}
                                        initial={client.agreementNotes}
                                        onCommit={newVal =>
                                            onFieldChange("agreementNotes", newVal)
                                        }
                                    />
                                </>
                            ) : null}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
