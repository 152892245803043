import { makeInitialAdvancedSearchRequest } from "components/reports/show/custom-reports/custom-reports-helpers";
import { PrimaryButton } from "components/ui/primary-button";
import {
    AdvancedSearchCondition,
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    AdvancedSearchRequestCondition,
    BridgeEntityResult,
    GetSavedQueriesQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useEffect, useState } from "react";
import { useCustomLists } from "store/selectors/bridge";
import {
    useCurrentUserId,
    useIsMultiRegionUser,
    useRegionId,
    useRegions,
} from "store/selectors/hooks";
import {
    AdvancedSearchSaveExisting,
    AdvancedSearchSaveNew,
} from "./advanced-search-save";
import { ConditionEditorMode, simplifiableCondition } from "./advanced-search-utility";
import { ConditionEditor } from "./condition-editor";
import { EntityTypeEditor } from "./entity-type-editor";
import { SummaryEditor } from "./summary-editor";

interface AdvancedSearchInputProps {
    onCommit: (value: AdvancedSearchRequest) => void;
    entities: BridgeEntityResult[];
    initial?: AdvancedSearchRequest | null;
    report?: GetSavedQueriesQuery["getSavedQueries"]["savedQueries"][0];
    onChange?: (value: AdvancedSearchRequest) => void;
    committed: AdvancedSearchRequest;
    canSaveAdditional: boolean;
}

export const AdvancedSearchInput: React.FC<AdvancedSearchInputProps> = props => {
    const {
        onCommit,
        report,
        entities,
        initial,
        onChange,
        committed,
        canSaveAdditional,
    } = props;
    const regionId = useRegionId();
    const hasMultipleRegions = useIsMultiRegionUser();
    const regions = useRegions();
    const { customLists } = useCustomLists();
    const currentUserId = useCurrentUserId();
    const [searchInput, setSearchInput] = useState<AdvancedSearchRequest>(
        initial ||
            makeInitialAdvancedSearchRequest(
                entities,
                report?.entityType || AdvancedSearchEntityType.Client,
                regionId,
                hasMultipleRegions,
                regions,
                currentUserId,
                customLists,
            ),
    );
    const [editorMode, setEditorMode] = useState<ConditionEditorMode>(
        simplifiableCondition(searchInput.condition) ? "simple" : "advanced",
    );

    const onConditionChange = useCallback(
        (condition: AdvancedSearchRequestCondition) => {
            setSearchInput(
                produce(searchInput, draft => {
                    draft.condition = condition;
                }),
            );
        },
        [searchInput, setSearchInput],
    );

    const onEntityTypeChange = useCallback(
        (entityType: AdvancedSearchEntityType) => {
            setSearchInput(
                makeInitialAdvancedSearchRequest(
                    entities,
                    entityType,
                    regionId,
                    hasMultipleRegions,
                    regions,
                    currentUserId,
                    customLists,
                ),
            );
        },
        [
            setSearchInput,
            regionId,
            hasMultipleRegions,
            entities,
            regions,
            currentUserId,
            customLists,
        ],
    );

    const onSummariesChange = useCallback(
        (summaries: AdvancedSearchRequest["summaries"]) => {
            setSearchInput(
                produce(searchInput, draft => {
                    draft.summaries = summaries;
                }),
            );
        },
        [searchInput, setSearchInput],
    );

    useEffect(() => {
        if (onChange) {
            onChange(searchInput);
        }
    }, [searchInput]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (committed) {
            setSearchInput(prev =>
                produce(prev, draft => {
                    draft.sort = committed.sort;
                    draft.select = committed.select;
                    draft.summaries = committed.summaries;
                }),
            );
        }
    }, [committed?.sort, committed?.select, committed?.summaries]); // eslint-disable-line react-hooks/exhaustive-deps

    const submit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            if (e && e.preventDefault) {
                e.preventDefault();
            }
            onCommit(searchInput);
        },
        [searchInput, onCommit],
    );

    const entityMetadata = entities.find(e => e.name === searchInput.entityType);
    if (!entityMetadata) {
        throw new Error(
            `Unknown entity type for custom reporting ${searchInput.entityType}`,
        );
    }

    const switchAdvanced = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setEditorMode("advanced");
    }, []);

    const switchSimple = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setEditorMode("simple");
    }, []);

    return (
        <div className="bg-white w-full lg:rounded overflow-hidden lg:shadow">
            <form onSubmit={submit}>
                <div className="space-y-2 p-2 lg:p-4">
                    <EntityTypeEditor
                        entityType={searchInput.entityType}
                        entities={entities}
                        onChange={onEntityTypeChange}
                        preLabel="Record type"
                    />
                    <div className="flex items-center justify-between">
                        <div>Criteria</div>
                        <div>
                            {editorMode === "simple" ? (
                                <a
                                    href="#"
                                    onClick={switchAdvanced}
                                    className="twoverride text-sm text-gray-400">
                                    Switch to advanced mode
                                </a>
                            ) : simplifiableCondition(searchInput.condition) ? (
                                <a
                                    href="#"
                                    onClick={switchSimple}
                                    className="twoverride text-sm text-gray-400">
                                    Switch to simple mode
                                </a>
                            ) : null}
                        </div>
                    </div>
                    <ConditionEditor
                        entityMetadata={entityMetadata}
                        entities={entities}
                        customLists={customLists}
                        depth={1}
                        relativeDepth={1}
                        onChange={onConditionChange}
                        condition={searchInput.condition}
                        mode={editorMode}
                    />
                    <SummaryEditor
                        entityMetadata={entityMetadata}
                        entities={entities}
                        onChange={onSummariesChange}
                        summaries={searchInput.summaries}
                    />
                </div>
                <div className="bg-gray-50 p-2 lg:p-4 flex justify-between items-center">
                    <div className="flex space-x-4 lg:space-x-8 items-center">
                        {report ? (
                            <AdvancedSearchSaveExisting
                                searchRequest={searchInput}
                                report={report}
                            />
                        ) : (
                            <AdvancedSearchSaveNew
                                canSaveAdditional={canSaveAdditional}
                                searchRequest={searchInput}
                            />
                        )}
                    </div>
                    <PrimaryButton disabled={!validSearchInput(searchInput)}>
                        Run report
                    </PrimaryButton>
                </div>
            </form>
        </div>
    );
};

const validSearchInput = (searchInput: AdvancedSearchRequest) => {
    return validSearchCondition(searchInput.condition);
};

const validSearchCondition = (condition: AdvancedSearchCondition): boolean => {
    switch (condition.nodeType) {
        case AdvancedSearchConditionNodeType.And:
        case AdvancedSearchConditionNodeType.Or:
            return (
                Array.isArray(condition.children) &&
                condition.children.length > 0 &&
                condition.children.every(c => validSearchCondition(c))
            );
        case AdvancedSearchConditionNodeType.FieldCondition:
            return true;
        case AdvancedSearchConditionNodeType.RelationCondition:
            return true;
    }

    return false;
};
