import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { CalendarDate } from "@sp-crm/core";
import {
    AdvancedSearchRelativeDate,
    AdvancedSearchRelativeDateInterval,
} from "generated/graphql";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CalendarDateInput } from "./calendar-date";

type AbsoluteOrRelativeDateInputValue = {
    absolute?: CalendarDate | null;
    relative?: AdvancedSearchRelativeDate | null;
};

interface ReadOnlyValueProps {
    mode: "absolute" | "relative";
    value: AbsoluteOrRelativeDateInputValue;
}

const ReadOnlyValue: React.FC<ReadOnlyValueProps> = props => {
    const { mode, value } = props;
    const className = "text-lg flex-1";

    if (mode === "absolute" && value.absolute) {
        return <p className={className}>{value.absolute.toString()}</p>;
    }

    if (mode === "relative" && value.relative?.interval === "today") {
        return <p className={className}>Today</p>;
    }

    if (mode === "relative" && typeof value.relative?.value === "number") {
        return (
            <p className={className}>
                {Math.abs(value.relative.value)}{" "}
                {value.relative.value <= 0
                    ? `${value.relative.interval}${
                          Math.abs(value.relative.value) !== 1 ? "s" : ""
                      } ago`
                    : `${value.relative.interval}${
                          Math.abs(value.relative.value) !== 1 ? "s" : ""
                      } from now`}
            </p>
        );
    }

    return <p className={className}>Unspecified</p>;
};

interface AbsoluteOrRelativeDateInputProps {
    initial: AbsoluteOrRelativeDateInputValue;
    onChange: (value: AbsoluteOrRelativeDateInputValue) => void;
}

const valuesEqual = (
    a: AbsoluteOrRelativeDateInputValue,
    b: AbsoluteOrRelativeDateInputValue,
) => {
    if (a.absolute && b.absolute) {
        return a.absolute.equals(b.absolute);
    }

    if (a.relative && b.relative) {
        return (
            a.relative.interval === b.relative.interval &&
            a.relative.value === b.relative.value
        );
    }

    return false;
};

const toRelativeIntervalString = (value: AbsoluteOrRelativeDateInputValue) => {
    if (value.relative) {
        return `${value.relative.interval}:${
            value.relative.value <= 0 ? "ago" : "fromnow"
        }`;
    }
    return "day:fromnow";
};

export const AbsoluteOrRelativeDateInput: React.FC<
    AbsoluteOrRelativeDateInputProps
> = props => {
    const { initial, onChange } = props;

    const [absoluteDate, setAbsoluteDate] = useState<CalendarDate | null>(
        initial.absolute,
    );
    const [relativeValue, setRelativeValue] = useState<number>(
        Math.abs(initial.relative?.value || 1),
    );
    const [relativeInterval, setRelativeInterval] = useState<string>(
        toRelativeIntervalString(initial),
    );
    const [mode, setMode] = useState<"absolute" | "relative">(
        initial.absolute ? "absolute" : "relative",
    );

    const value = useMemo(() => {
        const [valueInterval, agoOrFromnow] = relativeInterval.split(":");
        return mode === "absolute"
            ? {
                  absolute: absoluteDate,
              }
            : {
                  relative: {
                      interval: valueInterval as AdvancedSearchRelativeDateInterval,
                      value: agoOrFromnow === "ago" ? -relativeValue : relativeValue,
                  },
              };
    }, [absoluteDate, mode, relativeValue, relativeInterval]);

    useEffect(() => {
        if (!valuesEqual(initial, value)) {
            setAbsoluteDate(initial.absolute);
            setRelativeValue(Math.abs(initial.relative?.value || 1));
            setRelativeInterval(toRelativeIntervalString(initial));
            setMode(initial.absolute ? "absolute" : "relative");
        }
    }, [initial]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onChange(value);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModeChange = useCallback(
        (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            newMode: "absolute" | "relative",
        ) => {
            e && e.preventDefault && e.preventDefault();
            setMode(newMode);
        },
        [setMode],
    );

    return (
        <Popover>
            {({ open }) => (
                <>
                    <PopoverButton>
                        <div className="flex items-center">
                            <ReadOnlyValue mode={mode} value={value} />
                            <ChevronDownIcon
                                className={`w-4 h-4 ml-1 transition duration-50 ${
                                    open ? "rotate-180 transform" : ""
                                }`}
                            />
                        </div>
                    </PopoverButton>
                    <PopoverPanel className="absolute z-10">
                        <div className="shadow-lg w-80 bg-white rounded border-2 border-gray-200">
                            <div className="flex">
                                <button
                                    className={`flex-1 py-2 border-b-2 ${
                                        mode === "absolute"
                                            ? "border-brand-500"
                                            : "border-gray-200"
                                    }`}
                                    onClick={e => handleModeChange(e, "absolute")}>
                                    Absolute
                                </button>
                                <button
                                    className={`flex-1 py-2 border-b-2 ${
                                        mode === "relative"
                                            ? "border-brand-500"
                                            : "border-gray-200"
                                    }`}
                                    onClick={e => handleModeChange(e, "relative")}>
                                    Relative
                                </button>
                            </div>
                            <div className="p-2">
                                {mode === "absolute" ? (
                                    <CalendarDateInput
                                        className="text-sm"
                                        label=""
                                        value={absoluteDate}
                                        onChange={date => setAbsoluteDate(date)}
                                    />
                                ) : (
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            pattern="\d+"
                                            className="form-input rounded-sm md:rounded disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300 w-28 text-sm"
                                            value={relativeValue || ""}
                                            disabled={relativeInterval === "today"}
                                            onChange={e => {
                                                setRelativeValue(
                                                    e.target.valueAsNumber || 0,
                                                );
                                            }}
                                        />
                                        <select
                                            className="form-select rounded-sm md:rounded w-full disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300 text-sm"
                                            value={relativeInterval}
                                            onChange={e =>
                                                setRelativeInterval(e.target.value)
                                            }>
                                            <option value="today">Today</option>
                                            <option value="day:ago">Days ago</option>
                                            <option value="week:ago">Weeks ago</option>
                                            <option value="month:ago">Months ago</option>
                                            <option value="year:ago">Years ago</option>
                                            <option value="minute:ago">
                                                Minutes ago
                                            </option>
                                            <option value="hour:ago">hours ago</option>
                                            <option value="second:ago">
                                                Seconds ago
                                            </option>
                                            <option value="day:fromnow">
                                                Days from now
                                            </option>
                                            <option value="week:fromnow">
                                                Weeks from now
                                            </option>
                                            <option value="month:fromnow">
                                                Months from now
                                            </option>
                                            <option value="year:fromnow">
                                                Years from now
                                            </option>
                                            <option value="minute:fromnow">
                                                Minutes from now
                                            </option>
                                            <option value="hour:fromnow">
                                                Hours from now
                                            </option>
                                            <option value="second:fromnow">
                                                Seconds from now
                                            </option>
                                        </select>
                                    </div>
                                )}
                            </div>
                        </div>
                    </PopoverPanel>
                </>
            )}
        </Popover>
    );
};
