import { ClientId } from "@sp-crm/core";
import { fancyConfirm } from "components/ui/fancy-confirm";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { getClient } from "store/selectors/selectors";
import { useTenantSettings } from "store/selectors/settings";
import { ApplicationState } from "store/state";
import MultipleClients from "./multiple-client";
import SingleClient from "./single-client";

interface InsuranceDetailsProps {
    clientId: ClientId;
    onFieldChange: (fieldName: string, newValue: unknown) => void;
}

export const InsuranceDetails: React.FC<InsuranceDetailsProps> = props => {
    const { clientId, onFieldChange } = props;
    const { settings } = useTenantSettings();
    const client = useSelector((state: ApplicationState) => getClient(state, clientId));

    const handleAdditionalClientFieldChange = useCallback(
        (fieldName: string, newValue: unknown) => {
            onFieldChange(`additionalClient.${fieldName}`, newValue);
        },
        [onFieldChange],
    );

    const handleSameForBothClientsCheckbox = useCallback(
        (newValue: boolean) => {
            // TODO: IS THIS REALLY THE RIGHT CHECK? DON'T WE CREATE INSURANCE INFO ALWAYS?!?
            // CONFIRMED - THIS IS NOT THE CORRECT CHECK :(
            // Now tracked by AB#334 (https://dev.azure.com/SeniorPlace/0eb160df-82f3-4a76-90c2-1263582636b2/_workitems/edit/334)
            // We actually need to check every field to see if it has a value.
            if (newValue && !!client.additionalClient.insuranceInfo) {
                (async () => {
                    const result = await fancyConfirm(
                        "Use same answers for both clients?",
                        "This will erase any Additional Client's answers registered so far",
                        "Yes, I'm sure",
                        "Cancel",
                    );
                    if (result) {
                        handleAdditionalClientFieldChange(
                            "insuranceAnswersSameAsPrimaryClient",
                            newValue,
                        );
                    }
                })();
            } else {
                handleAdditionalClientFieldChange(
                    "insuranceAnswersSameAsPrimaryClient",
                    newValue,
                );
            }
        },
        [client.additionalClient, handleAdditionalClientFieldChange],
    );

    if (!settings.showInsuranceInfo) return null;

    const hasMultipleClients = !!client.additionalClient;

    return (
        <>
            {hasMultipleClients ? (
                <MultipleClients
                    client={client}
                    additionalClient={client.additionalClient}
                    onFieldChange={onFieldChange}
                    onAdditionalClientFieldChange={handleAdditionalClientFieldChange}
                    onSameForBothClientsCheckboxChanged={handleSameForBothClientsCheckbox}
                />
            ) : (
                <SingleClient client={client} onFieldChange={onFieldChange} />
            )}
        </>
    );
};
