import {
    ClientId,
    CommunityId,
    CommunityRelationshipType,
    IClient,
    Localization,
    parseLocation,
} from "@sp-crm/core";
import { NextgenMap } from "components/community-search/community-map/map";
import { fancyConfirm } from "components/ui/fancy-confirm";

import { defaultLinkStyle } from "components/ui/link";
import { SecondaryButton } from "components/ui/secondary-button";
import { Toggle } from "components/ui/toggle";
import { produce } from "immer";
import * as React from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useCommunityCardQuestionIds } from "store/selectors/hooks";
import { useTenantSettings } from "store/selectors/settings";
import {
    CommunityOrdering,
    GetClientCommunitiesQuery,
    useGetClientCommunitiesQuery,
    useRemoveClientCommunitiesMutation,
    useSetCommunityOrderMutation,
} from "../../../../generated/graphql";
import {
    loadClient,
    removeCommunityFromClient,
    updateCommunityClient,
    updateCommunityClientNotes,
} from "../../../../store/actions";
import { calculateNewOrder } from "../../../../util/dnd";
import {
    CommunityCard,
    CommunityCardExperience,
} from "../../../community-search/community-card/community-card";
import {
    cardBasicsToListableCommunity,
    gqlCommunuityToMappableCommunity,
} from "../../../community-search/listable-community-utility";
import { ListableCommunity } from "../../../community-search/props";
import {
    ClientCommunityRelationship,
    UpdateRelationshipPayload,
} from "../../../community-search/types";
import { ContentSection2Up, ContentSectionColumn } from "../../../layout";
import { CompareChartModal } from "../compare-chart-modal";
import { ClientCommunityCardCompact } from "./client-community-card-compact";
import { sortAndExcludeClientCommunities } from "./community-comparison-query-util";
import { QueryRenderer } from "./query-renderer";
import { ShowInFamilyViewCheckbox } from "./show-in-family-view-checkbox";

interface Props {
    clientId: ClientId;
    onClientCommunityOrderChange: (
        clientId: ClientId,
        ordering: CommunityOrdering[],
    ) => void;
    client: IClient;
    locale: Localization;
}

export const CommunityComparisonOrderable: React.FC<Props> = props => {
    const { clientId, onClientCommunityOrderChange } = props;
    const [view, setView] = React.useState<"full" | "sortable">("full");
    const client = useQueryClient();
    const questionIds = useCommunityCardQuestionIds();
    const queryParams = React.useMemo(
        () => ({ id: clientId, questionIds }),
        [clientId, questionIds],
    );
    const query = useGetClientCommunitiesQuery(queryParams);
    const [hoveredCommunityId, setHoveredCommunityId] = React.useState<CommunityId>(null);
    const mutation = useSetCommunityOrderMutation();
    const { settings } = useTenantSettings();
    const bulkEnabled = settings.enableBulkCommunityOptions;
    const reorder = React.useCallback(
        (draggingIndex: number, targetIndex: number) => {
            const data = client.getQueryData<GetClientCommunitiesQuery>([
                "getClientCommunities",
                queryParams,
            ]);
            if (!data) {
                return;
            }
            const sortedCommunities = sortAndExcludeClientCommunities(data);
            const payload = produce(query.data, draft => {
                const newOrder = calculateNewOrder(
                    sortedCommunities,
                    draggingIndex,
                    targetIndex,
                );

                const current = sortedCommunities[draggingIndex];
                const currentActualElement = draft.getClient.clientCommunities.find(
                    c => c.community.id === current.community.id,
                );
                currentActualElement.order = newOrder;
            });
            client.setQueryData(["getClientCommunities", queryParams], payload);
        },
        [query, queryParams, client],
    );
    const commitOrder = React.useCallback(() => {
        (async () => {
            if (!query.data) return;
            if (!query.data.getClient) return;
            const ordering = sortAndExcludeClientCommunities(query.data).map((x, i) => {
                return { communityId: x.community.id, order: i };
            });
            await mutation.mutateAsync({ clientId: clientId, ordering, questionIds });
            query.refetch();

            onClientCommunityOrderChange(clientId, ordering);
        })();
    }, [query, mutation, questionIds, clientId, onClientCommunityOrderChange]);
    const revertOrder = React.useCallback(() => {
        (async () => {
            if (!query.data) return;
            if (!query.data.getClient) return;
            query.refetch();
        })();
    }, [query]);
    const dispatch = useDispatch();
    const updateClientCommunity = React.useCallback(
        (
            clientId: ClientId,
            communityId: CommunityId,
            type: CommunityRelationshipType,
        ) => {
            updateCommunityClient(clientId, communityId, type, dispatch);
            const payload = produce(query.data, draft => {
                const updated = draft.getClient.clientCommunities.find(
                    c => c.community.id === communityId,
                );
                if (updated) {
                    updated.relationship = type;
                }
            });
            client.setQueryData(["getClientCommunities", queryParams], payload);
        },
        [query, queryParams, client, dispatch],
    );

    const relationshipProvider = React.useCallback(
        (
            clientCommunity: GetClientCommunitiesQuery["getClient"]["clientCommunities"][0],
        ): ClientCommunityRelationship => {
            return {
                relationship: clientCommunity.relationship,
                publicNote: clientCommunity.publicNotes,
                privateNote: clientCommunity.privateNotes,
                referred: clientCommunity.referred,
                updateRelationship: (payload: UpdateRelationshipPayload) => {
                    if (payload.action === "updateNote") {
                        (async () => {
                            await updateCommunityClientNotes(
                                clientId,
                                clientCommunity.community.id,
                                payload.noteType,
                                payload.note,
                                dispatch,
                            );
                            query.refetch();
                        })();
                    } else if (payload.action === "updateRelationship") {
                        updateClientCommunity(
                            clientId,
                            clientCommunity.community.id,
                            payload.relationship,
                        );
                    } else if (payload.action === "removeRelationship") {
                        (async () => {
                            const shouldRemove = await fancyConfirm(
                                "Remove match?",
                                "Really remove this community as a potential match?",
                                "Yes, remove",
                                "Cancel",
                            );
                            if (shouldRemove) {
                                await removeCommunityFromClient(
                                    clientId,
                                    clientCommunity.community.id,
                                    dispatch,
                                );
                                query.refetch();
                            }
                        })();
                    }
                },
            };
        },
        [query, dispatch, clientId, updateClientCommunity],
    );

    const generateCommunityMapDetail = React.useCallback(
        (community: ListableCommunity) => {
            if (!query || !query.data || !query.data.getClient) {
                return null;
            }

            const clientCommunity = query.data.getClient.clientCommunities.find(
                cc => cc.community.id === community.id,
            );

            if (!clientCommunity) {
                return null;
            }

            const relationshipProviderResult = relationshipProvider(clientCommunity);

            return (
                <div>
                    <ShowInFamilyViewCheckbox
                        clientId={clientId}
                        onClientCommunityChange={updateClientCommunity}
                        clientCommunity={clientCommunity}
                    />
                    <button
                        onClick={e => {
                            e.preventDefault();
                            relationshipProviderResult.updateRelationship({
                                action: "removeRelationship",
                            });
                        }}
                        className={`${defaultLinkStyle} flex items-center space-x-1 text-gray-500`}>
                        <span>Remove match</span>
                    </button>
                </div>
            );
        },
        [query, updateClientCommunity, relationshipProvider, clientId],
    );

    const viewToggle = React.useCallback(
        (isChecked: boolean) => {
            setView(isChecked ? "sortable" : "full");
        },
        [setView],
    );

    const removeCommunities = useRemoveClientCommunitiesMutation();

    const handleRemoveAll = React.useCallback(
        async (
            clientCommunities: GetClientCommunitiesQuery["getClient"]["clientCommunities"],
        ) => {
            const confirmed = await fancyConfirm(
                "Remove all matches?",
                "This will remove all of the community options below. Would you like to proceed?",
                "Yes, remove",
                "Cancel",
            );

            if (confirmed) {
                await removeCommunities.mutateAsync({
                    clientId,
                    params: clientCommunities.map(c => ({ communityId: c.community.id })),
                });
                query.refetch();
                loadClient(clientId, dispatch);
            }
        },
        [removeCommunities, clientId, query, dispatch],
    );

    return (
        <QueryRenderer query={query} name="CommunityComparisonOrderable">
            {data => {
                const clientCommunities = sortAndExcludeClientCommunities(data);
                return (
                    <ContentSection2Up className="community-list">
                        <ContentSectionColumn>
                            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-3">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Community Options
                                </h3>
                                <div className="mt-3 md:flex md:items-center sm:mt-0 sm:ml-4 md:space-x-2 lg:space-x-4">
                                    {clientCommunities.length > 1 ||
                                    view === "sortable" ? (
                                        <div>
                                            <Toggle
                                                onChange={viewToggle}
                                                checked={view === "sortable"}
                                                label="Reorder list"
                                            />
                                        </div>
                                    ) : null}
                                    {clientCommunities.length > 0 ? (
                                        <div>
                                            <CompareChartModal
                                                client={props.client}
                                                locale={props.locale}
                                            />
                                        </div>
                                    ) : null}
                                    {bulkEnabled && clientCommunities.length > 0 ? (
                                        <SecondaryButton
                                            onClick={e => {
                                                e.preventDefault();
                                                handleRemoveAll(clientCommunities);
                                            }}>
                                            <span className="text-sm">
                                                Remove all options
                                            </span>
                                        </SecondaryButton>
                                    ) : null}
                                </div>
                            </div>
                            {clientCommunities.length === 0 ? (
                                <div>
                                    Please{" "}
                                    <Link to={`/clients/show/${clientId}/communities`}>
                                        Find Ideal Communities
                                    </Link>{" "}
                                    first
                                </div>
                            ) : (
                                <ul className="space-y-2 lg:space-y-4">
                                    {view === "full"
                                        ? clientCommunities.map(c => {
                                              return (
                                                  <li
                                                      key={c.community.id}
                                                      onMouseEnter={() =>
                                                          setHoveredCommunityId(
                                                              c.community.id,
                                                          )
                                                      }
                                                      onMouseLeave={() =>
                                                          setHoveredCommunityId(null)
                                                      }>
                                                      <CommunityCard
                                                          community={cardBasicsToListableCommunity(
                                                              c.community,
                                                          )}
                                                          experience={
                                                              CommunityCardExperience.ClientShortlist
                                                          }
                                                          clientCommunityRelationship={relationshipProvider(
                                                              c,
                                                          )}
                                                          geoReference={parseLocation(
                                                              data.getClient.baseLocation
                                                                  ?.lat,
                                                              data.getClient.baseLocation
                                                                  ?.lng,
                                                          ).getOrElse(null)}
                                                      />
                                                  </li>
                                              );
                                          })
                                        : clientCommunities.map((c, i) => (
                                              <ClientCommunityCardCompact
                                                  clientId={clientId}
                                                  clientCommunity={c}
                                                  key={c.community.id}
                                                  index={i}
                                                  reorder={reorder}
                                                  commitOrder={commitOrder}
                                                  revertOrder={revertOrder}
                                                  onClientCommunityChange={
                                                      updateClientCommunity
                                                  }
                                                  refetch={query.refetch}
                                                  onCommunityHoverStart={id =>
                                                      setHoveredCommunityId(id)
                                                  }
                                                  onCommunityHoverEnd={() =>
                                                      setHoveredCommunityId(null)
                                                  }
                                              />
                                          ))}
                                </ul>
                            )}
                        </ContentSectionColumn>
                        <ContentSectionColumn className="community-map-anchor lg:ml-2">
                            <NextgenMap
                                autoMoveMap={true}
                                childControlGenerator={generateCommunityMapDetail}
                                communities={clientCommunities
                                    .map(c => c.community)
                                    .map(gqlCommunuityToMappableCommunity)}
                                highlightCommunityId={hoveredCommunityId}
                                geoReference={props.client.baseLocation}
                            />
                        </ContentSectionColumn>
                    </ContentSection2Up>
                );
            }}
        </QueryRenderer>
    );
};
