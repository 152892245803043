import { IClient } from "@sp-crm/core";
import { InputClientContact } from "components/shared/input-client-contact";
import { ContactRenderInfo } from "components/shared/input-contact-person";
import { ClientContactRelationship } from "generated/graphql";
import React, { useCallback } from "react";
import { ClientContacts } from "./client-contacts";
import { DuplicateClientBanner } from "./duplicate-client-banner";

const mapBestContactPropsToClientProps: Record<string, string> = {
    name: "name",
    preferredName: "preferredName",
    cellPhone: "cellPhone",
    phone1: "phone",
    phoneNumbers: "phoneNumbers",
    email1: "email",
    email1OptOut: "emailOptOut",
    address1: "address",
    city: "city",
    state: "state",
    zip: "zip",
};

const mapBestContactPropsToIdealLocationProps: Record<string, string> = {
    address1: "bestLocationAddress",
    city: "bestLocationCity",
    state: "bestLocationState",
    zip: "bestLocationZip",
};

interface ClientBestContactProps {
    client: IClient;
    onFieldChangeMulti: (fields: Record<string, unknown>) => void;
}

export const ClientBestContact: React.FC<ClientBestContactProps> = props => {
    const { client, onFieldChangeMulti } = props;
    const contactRenderInfoBestContactPerson: ContactRenderInfo = {
        contactGroupIcon: "46_MoreContacts",
        contactGroupHeading: "",
        showAddressFields: true,
        showRelationshipField: true,
        showRoleField: false,
        hideContactNotes: true,
        allowReferenceLink: false,
        specializedLayout: "Standard",
        allowPreferredName: true,
    };

    const handleFieldChange = useCallback(
        (fieldName: string, newValue: string) => {
            const newValues: Record<string, unknown> = {};

            // Need to actually update the field on the Best Contact object no matter what
            const bestContactKey = "bestContact";
            newValues[`${bestContactKey}.${fieldName}`] = newValue;

            // Check if we are also cloning the client. If so, we'll need to copy to
            // that corresponding prop too.
            if (
                client.clientSameAsBestContact &&
                mapBestContactPropsToClientProps[fieldName]
            ) {
                newValues[`${mapBestContactPropsToClientProps[fieldName]}`] = newValue;
            }

            // Check if we're cloning the Best Contact address onto the Ideal Location Field.
            // If so, we'll need to update the corresponding ideal location field now too.
            if (
                client.useBestContactAddressForBestLocation &&
                mapBestContactPropsToIdealLocationProps[fieldName]
            ) {
                newValues[`${mapBestContactPropsToIdealLocationProps[fieldName]}`] =
                    newValue;
            }

            onFieldChangeMulti(newValues);
        },
        [client, onFieldChangeMulti],
    );

    return (
        <>
            <InputClientContact
                contact={client.bestContactEntity}
                onFieldChange={handleFieldChange}
                headerLabel="Best Contact Person"
                contactRenderInfo={contactRenderInfoBestContactPerson}
                banner={
                    <DuplicateClientBanner
                        clientId={client.id}
                        clientName={client.bestContactEntity?.name}
                    />
                }
            />
            <div className="input-form-block-no-bottom-margin">
                <ClientContacts
                    clientId={client.id}
                    relationship={ClientContactRelationship.MoreContact}
                />
            </div>
        </>
    );
};
