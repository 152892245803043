import { UserId } from "@sp-crm/core";
import { produce } from "immer";
import React, { useEffect, useMemo, useReducer } from "react";
import { navigate } from "store/actions";
import { useCurrentUserId } from "store/selectors/hooks";

type State = {
    otherTabUserId: UserId | null;
};

type Action = { type: "receiveUserIdBroadcast"; userId: UserId };

const initialState: State = {
    otherTabUserId: null,
};

const reducer = (state: State, action: Action) => {
    return produce(state, draft => {
        switch (action.type) {
            case "receiveUserIdBroadcast":
                draft.otherTabUserId = action.userId;
                break;
        }
    });
};

export const UserChangedReload: React.FC<unknown> = _ => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const currentUserId = useCurrentUserId();

    const broadcastChannel = useMemo((): BroadcastChannel => {
        if (!("BroadcastChannel" in window)) {
            return null;
        }

        return new BroadcastChannel("sp-crm-user-reload");
    }, []);

    useEffect(() => {
        if (broadcastChannel) {
            broadcastChannel.onmessage = event => {
                const userId = event.data;
                dispatch({ type: "receiveUserIdBroadcast", userId });
            };
        }

        return () => {
            broadcastChannel?.close();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.otherTabUserId && state.otherTabUserId !== currentUserId) {
            navigate("/");
            window.location.reload();
        }
    }, [state.otherTabUserId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentUserId && broadcastChannel) {
            broadcastChannel.postMessage(currentUserId);
        }
    }, [currentUserId]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};
