import {
    ClientId,
    CommunityId,
    CommunityRelationshipType,
    IBridgeFieldMetadata,
    ILocation,
    Localization,
    QuestionId,
} from "@sp-crm/core";
import { LayoutSectionResult } from "components/layout/layout-items";
import React from "react";
import { useBridgeQuestionsForCurrentRegion } from "store/selectors/bridge";
import { useQuestionIdsWithUpstreamIds } from "store/selectors/hooks";
import { useTenantSettings } from "store/selectors/settings";
import { stableQueryOptions } from "util/requests";
import {
    AdvancedSearchEntityType,
    LayoutSectionParentKey,
    useGetClientCommunitiesQuery,
    useGetEntitiesQuery,
    useGetLayoutSectionsQuery,
} from "../../../../generated/graphql";
import { sortAndExcludeClientCommunities } from "./community-comparison-query-util";
import { CommunityComparisonTable } from "./community-comparison-table";
import { QueryRenderer } from "./query-renderer";

interface ClientCommunityComparisonTableProps {
    clientId: ClientId;
    excludedRelationshipTypes: CommunityRelationshipType[];
    baseLocation: ILocation | null;
    locale: Localization;
}

type ClientCommunityComparisonTableRendererProps = ClientCommunityComparisonTableProps & {
    layoutSections: LayoutSectionResult[];
    fieldMetadataList: IBridgeFieldMetadata[];
};

const ClientCommunityComparisonTableRenderer: React.FC<
    ClientCommunityComparisonTableRendererProps
> = props => {
    const {
        clientId,
        excludedRelationshipTypes,
        layoutSections,
        baseLocation,
        locale,
        fieldMetadataList,
    } = props;

    const layoutQuestionIds: QuestionId[] = [];

    layoutSections.forEach(layoutSection => {
        layoutSection.layoutItems.forEach(layoutItem => {
            if (layoutItem.questionId) {
                layoutQuestionIds.push(layoutItem.questionId);
            }
        });
    });

    const questions = useBridgeQuestionsForCurrentRegion(
        AdvancedSearchEntityType.Community,
    );
    const questionIds = useQuestionIdsWithUpstreamIds(layoutQuestionIds);

    const { settings } = useTenantSettings();

    const clientCommunitiesQuery = useGetClientCommunitiesQuery({
        id: clientId,
        questionIds,
    });

    return (
        <QueryRenderer
            query={clientCommunitiesQuery}
            name="Community Comparison Table (Communities)">
            {communitiesData => {
                const clientCommunities = sortAndExcludeClientCommunities(
                    communitiesData,
                    excludedRelationshipTypes,
                );
                const communities = clientCommunities.map(c => c.community);
                const publicNotesMap = clientCommunities
                    .map(cc => ({
                        id: cc.community.id,
                        publicNotes: cc.publicNotes,
                    }))
                    .reduce(
                        (acc, curr) => ({ ...acc, [curr.id]: curr.publicNotes }),
                        {} as Record<CommunityId, string>,
                    );
                const getPublicNotes = (id: CommunityId) => publicNotesMap[id] || null;
                return (
                    <CommunityComparisonTable
                        communities={communities}
                        getPublicNotes={getPublicNotes}
                        layoutSections={layoutSections}
                        clickableCommunities={true}
                        baseLocation={baseLocation}
                        locale={locale}
                        fieldMetadataList={fieldMetadataList}
                        questions={questions}
                        tenantSettings={settings}
                    />
                );
            }}
        </QueryRenderer>
    );
};

export const ClientCommunityComparisonTable: React.FC<
    ClientCommunityComparisonTableProps
> = props => {
    const layoutSectionsQuery = useGetLayoutSectionsQuery(
        {
            parentKey: LayoutSectionParentKey.CommunityComparisonTable,
        },
        stableQueryOptions(),
    );

    const entitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());

    return (
        <QueryRenderer
            query={layoutSectionsQuery}
            name="Community Comparison Table (Layout)">
            {layoutSectionsData => (
                <QueryRenderer
                    query={entitiesQuery}
                    name="ClientCommunityComparisonTable.getEntities">
                    {entities => (
                        <ClientCommunityComparisonTableRenderer
                            {...props}
                            layoutSections={
                                layoutSectionsData.getLayoutSections.layoutSections
                            }
                            fieldMetadataList={
                                entities.getEntities.find(e => e.name === "Community")
                                    .fields
                            }
                        />
                    )}
                </QueryRenderer>
            )}
        </QueryRenderer>
    );
};
