import { allInvoiceSettingsKeys } from "@sp-crm/core";
import { ContentFull, ContentHeader, ContentSubheader } from "components/layout";
import { LocaleContext } from "components/locale-provider/locale-provider";
import { NumberInput } from "components/shared/number-input";
import { AutosavingInput } from "components/ui/autosaving-input";
import { AutosavingStateInput } from "components/ui/autosaving-state-input";
import { Checkbox } from "components/ui/checkbox";
import { fancyConfirm } from "components/ui/fancy-confirm";
import React, { ChangeEvent, useCallback, useMemo } from "react";
import { useCurrentRegion } from "store/selectors/hooks";
import { useRegionSettings } from "store/selectors/settings";

export const RegionSettingsInvoices: React.FC<unknown> = () => {
    const {
        settings,
        putSetting,
        putSettings,
        atLeastOneOverridden,
        deleteSettings,
        status,
    } = useRegionSettings();
    const region = useCurrentRegion();

    const overridden = useMemo(
        () => atLeastOneOverridden(allInvoiceSettingsKeys),
        [atLeastOneOverridden],
    );

    const handleOverrideChange = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                await putSettings(
                    allInvoiceSettingsKeys.map(key => ({ key, value: settings[key] })),
                );
            } else {
                const confirmed = await fancyConfirm(
                    "Are you sure?",
                    `This will delete all specific invoice settings for ${region?.name}.`,
                    "Yes, delete",
                    "No, cancel",
                );
                if (confirmed) {
                    await deleteSettings(allInvoiceSettingsKeys);
                }
            }
        },
        [putSettings, deleteSettings, settings, region],
    );

    if (status !== "success") {
        return null;
    }

    return (
        <LocaleContext.Consumer>
            {locale => (
                <div className="space-y-4 lg:space-y-8">
                    <ContentFull>
                        <ContentHeader>{`Invoices - ${region?.name}`}</ContentHeader>
                        <ContentSubheader>
                            To whom and where should communities send invoice payments?
                        </ContentSubheader>
                        <Checkbox
                            checked={overridden}
                            onChange={handleOverrideChange}
                            label={
                                <p>
                                    Use specific invoice settings for{" "}
                                    <strong>{region?.name}</strong>
                                </p>
                            }
                        />
                        <div className="space-y-4 mt-4">
                            <AutosavingInput
                                label="Name invoices payable to"
                                multiLine={false}
                                disabled={!overridden}
                                initial={settings.invoicePayableName}
                                onCommit={newValue =>
                                    putSetting("invoicePayableName", newValue)
                                }
                            />
                            <AutosavingInput
                                label="Address"
                                multiLine={false}
                                disabled={!overridden}
                                initial={settings.invoicePayableAddress}
                                onCommit={newValue =>
                                    putSetting("invoicePayableAddress", newValue)
                                }
                            />
                            <AutosavingInput
                                label="City"
                                multiLine={false}
                                disabled={!overridden}
                                initial={settings.invoicePayableCity}
                                onCommit={newValue =>
                                    putSetting("invoicePayableCity", newValue)
                                }
                            />
                            <AutosavingStateInput
                                label={locale.strings.state}
                                initial={settings.invoicePayableState}
                                disabled={!overridden}
                                onCommit={newValue =>
                                    putSetting("invoicePayableState", newValue)
                                }
                            />
                            <AutosavingInput
                                label={locale.strings.zip}
                                multiLine={false}
                                initial={settings.invoicePayableZip}
                                disabled={!overridden}
                                onCommit={newValue =>
                                    putSetting("invoicePayableZip", newValue)
                                }
                            />
                            <AutosavingInput
                                label="Phone"
                                multiLine={false}
                                initial={settings.invoicePayablePhone}
                                disabled={!overridden}
                                onCommit={newValue =>
                                    putSetting("invoicePayablePhone", newValue)
                                }
                            />
                            <AutosavingInput
                                label="Email"
                                multiLine={false}
                                initial={settings.invoicePayableEmail}
                                disabled={!overridden}
                                onCommit={newValue =>
                                    putSetting("invoicePayableEmail", newValue)
                                }
                            />
                            <AutosavingInput
                                label={
                                    <div>
                                        <p>Payment notes</p>
                                        <p className="text-gray-500 text-sm">
                                            For payment provider information like Zelle or
                                            Paypal
                                        </p>
                                    </div>
                                }
                                multiLine={false}
                                initial={settings.invoicePayableNotes}
                                disabled={!overridden}
                                onCommit={newValue =>
                                    putSetting("invoicePayableNotes", newValue)
                                }
                            />
                            <NumberInput
                                label="How many days after client move-in date is invoice due?"
                                initial={settings.defaultDaysBeforeInvoiceDue}
                                disabled={!overridden}
                                onCommit={newValue =>
                                    putSetting("defaultDaysBeforeInvoiceDue", newValue)
                                }
                            />
                            <AutosavingInput
                                label={
                                    <div>
                                        <p>Default invoice notes</p>
                                        <p className="text-gray-500 text-sm">
                                            Pre-populate new invoices with these notes
                                        </p>
                                    </div>
                                }
                                multiLine={true}
                                initial={settings.invoiceDefaultNotes}
                                disabled={!overridden}
                                onCommit={newValue =>
                                    putSetting("invoiceDefaultNotes", newValue)
                                }
                            />
                        </div>
                    </ContentFull>
                </div>
            )}
        </LocaleContext.Consumer>
    );
};
