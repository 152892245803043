import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import {
    AdvancedSearchEntityType,
    LayoutSectionKey,
    LayoutSectionParentKey,
    useGetEntitiesQuery,
    useGetLayoutSectionsQuery,
} from "generated/graphql";
import React from "react";
import { useBridgeQuestions } from "store/selectors/bridge";
import { stableQueryOptions } from "util/requests";
import { LayoutSectionItemSettings } from "../layout-section-item-settings";
import { AgencyCheckboxPref } from "./agency-settings-checkbox";
import { EntitySettings } from "./customize/entity-settings";

export const AgencySettingsReferrals: React.FC<unknown> = () => {
    const entitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());
    const layoutSections = useGetLayoutSectionsQuery(
        { parentKey: LayoutSectionParentKey.ReferralCommunityCard },
        stableQueryOptions(),
    );

    const questions = useBridgeQuestions(AdvancedSearchEntityType.Community);

    const cardSectionId = layoutSections.data?.getLayoutSections?.layoutSections?.find(
        s => s.sectionKey === LayoutSectionKey.ReferralCommunityCardKeyAttributes,
    )?.id;

    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>Referrals</ContentHeader>
                <div className="space-y-4">
                    <AgencyCheckboxPref
                        field="ownedReferralSources"
                        label="Referral sources are assignable to users"
                        description="Allow referral sources (both businesses and contacts) to be assigned to 1 user within the organization."
                    />
                </div>
            </ContentFull>
            <ContentFull>
                <ContentHeader>Referral search</ContentHeader>
                <div className="space-y-4">
                    {cardSectionId ? (
                        <LayoutSectionItemSettings
                            entityMetadataName="Community"
                            includeStandardFields={true}
                            questions={questions}
                            questionFilter={_ => true}
                            questionTitle={q => q.title}
                            categoryFilter={d => d.communityEnabled}
                            categoryTitle={d => d.communityHeading}
                            layoutSectionId={cardSectionId}
                            label="Community fields to show on referral search results:"
                            mode="alwaysEditing"
                            enableShowWhenEmpty={true}
                            enableNaryFormat={false}
                            relations={[]}
                            allowEditFields={true}
                            allowEditItemTitles={false}
                        />
                    ) : null}
                </div>
            </ContentFull>
            <QueryRenderer query={entitiesQuery} name="Settings.getEntities">
                {data => (
                    <>
                        <ContentFull>
                            <ContentHeader>Customize Organizations</ContentHeader>

                            <EntitySettings
                                entityName="ReferenceBusiness"
                                entities={data.getEntities}
                            />
                        </ContentFull>

                        <ContentFull>
                            <ContentHeader>Customize Contacts</ContentHeader>

                            <EntitySettings
                                entityName="ReferenceContact"
                                entities={data.getEntities}
                            />
                        </ContentFull>
                    </>
                )}
            </QueryRenderer>
        </div>
    );
};
