import { TertiarynavMenu, TertiarynavMenuItem } from "components/navigation/tertiarynav";
import { useGetWorkflowsQuery } from "generated/graphql";
import React from "react";

export const AccountSettingsNav: React.FC<unknown> = () => {
    const workflows = useGetWorkflowsQuery();

    return (
        <TertiarynavMenu>
            <TertiarynavMenuItem to="/settings/account/password">
                Change password
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/account/client">
                Client &amp; Client referrals
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/account/community">
                Communities
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/account/email">Email</TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/account/general">
                General
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/account/notifications">
                Notifications &amp; Calendar
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/account/personal">
                Personal
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/account/referral">
                Referral sources
            </TertiarynavMenuItem>
            {workflows.data &&
            workflows.data.getWorkflows &&
            workflows.data.getWorkflows.length > 0 ? (
                <TertiarynavMenuItem to="/settings/account/workflow">
                    Workflows
                </TertiarynavMenuItem>
            ) : null}
        </TertiarynavMenu>
    );
};
