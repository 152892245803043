import {
    CommunityComparisonPdfOptions,
    EmailTemplateId,
    LayoutSectionKey,
} from "@sp-crm/core";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import * as React from "react";
import { usePreferences } from "store/selectors/hooks";
import { useTenantSettings } from "store/selectors/settings";
import { CommunityComparePdfLayoutCustomization } from "./pdf-layout-customization";

interface CommunitiesComparePdfDialogProps {
    action: string;
    onDismiss: () => void;
}

type CommunitiesComparePdfDialogState = CommunityComparisonPdfOptions & {
    canSubmit: boolean;
};

export const CommunitiesComparePdfDialog: React.FC<CommunitiesComparePdfDialogProps> = ({
    action,
    onDismiss,
}) => {
    const { settings } = useTenantSettings();
    const preferences = usePreferences();
    const downloadFormRef = React.useRef<HTMLFormElement>(null);

    const [state, setState] = React.useState<CommunitiesComparePdfDialogState>({
        showPrimaryContacts: true,
        showFinancialDetails: true,
        showCommunityRecords: settings.showCommunityRecordsInCommunityComparePDF,
        showCoverPage: preferences.defaultShowCoverPage,
        coverPageTemplate: { templateId: preferences.defaultCommunityCompareTemplateId },
        sectionKeys: [],
        canSubmit: false,
    });

    const download = () => {
        if (downloadFormRef.current) {
            downloadFormRef.current.submit();
        }
        dismiss(777);
    };

    const dismiss = (delay = 0): boolean => {
        setTimeout(onDismiss, delay);
        return true;
    };

    const handleShowCoverPage = (selection: boolean) => {
        setState(prev => ({ ...prev, showCoverPage: selection }));
    };

    const handleCoverPageTemplateId = (selection: EmailTemplateId | null | undefined) => {
        setState(prev => ({ ...prev, coverPageTemplate: { templateId: selection } }));
    };

    const handleSectionKeys = (sectionKeys: LayoutSectionKey[]) => {
        setState(prev => ({ ...prev, sectionKeys, canSubmit: true }));
    };

    return (
        <Panel
            type={PanelType.largeFixed}
            onDismiss={onDismiss}
            isOpen={true}
            headerText="Save Community Comparison PDF">
            <div>
                <div>
                    <CommunityComparePdfLayoutCustomization
                        coverPageTemplate={{
                            templateId: state.coverPageTemplate.templateId,
                        }}
                        showCoverPage={state.showCoverPage}
                        sectionKeys={state.sectionKeys}
                        handleCoverPageTemplateId={handleCoverPageTemplateId}
                        handleShowCoverPage={handleShowCoverPage}
                        handleSectionKeys={handleSectionKeys}
                    />
                </div>
                <div className="mt-6">
                    <form
                        target="_blank"
                        method="GET"
                        ref={downloadFormRef}
                        action={action}>
                        <input
                            type="hidden"
                            value={state.showPrimaryContacts.toString()}
                            name="showPrimaryContacts"
                        />
                        <input
                            type="hidden"
                            value={state.showFinancialDetails.toString()}
                            name="showFinancialDetails"
                        />
                        <input
                            type="hidden"
                            value={state.showCommunityRecords.toString()}
                            name="showCommunityRecords"
                        />
                        <input
                            type="hidden"
                            value={state.showCoverPage.toString()}
                            name="showCoverPage"
                        />
                        <input
                            type="hidden"
                            value={state.coverPageTemplate?.templateId?.toString()}
                            name="coverPageTemplateId"
                        />
                        <input
                            type="hidden"
                            value={(state.sectionKeys || []).join(",")}
                            name="sectionKeys"
                        />
                        <div className="flex items-center justify-end space-x-2">
                            <SecondaryButton type="button" onClick={() => dismiss()}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                type="button"
                                disabled={!state.canSubmit}
                                onClick={() => download()}>
                                Download
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </div>
        </Panel>
    );
};
