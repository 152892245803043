import { ContentFull, ContentHeader } from "components/layout";
import { Select } from "components/ui/select";
import React from "react";
import { usePreferences } from "store/selectors/hooks";
import { UserCheckboxPref } from "./account-settings-checkbox";
import { useCommitUserPreference } from "./account-settings-hooks";

export const AccountSettingsReferralSources: React.FC<unknown> = () => {
    const { referralSearchCommunities } = usePreferences();
    const updateReferralSearchCommunities = useCommitUserPreference(
        "referralSearchCommunities",
    );

    return (
        <ContentFull>
            <ContentHeader>Referral sources</ContentHeader>
            <div className="space-y-4">
                <UserCheckboxPref
                    field="referralMapExperience"
                    label="Enable map view for Referral sources"
                    description={`When viewing all referral sources in the main "Referrals" section, display a map view of the referral sources.`}
                />
                <UserCheckboxPref
                    field="showReferralCityOnMainTable"
                    label="Show Referral Organization's City when viewing all Referral sources"
                    description={`When viewing all referral sources in the main "Referrals" section, display a value for the referral source's city.`}
                />
                <UserCheckboxPref
                    field="showReferralLastUpdatedDateOnMainTable"
                    label="Show Referral's Updated Date when viewing all Referral sources"
                    description={`When viewing all referral sources in the main "Referrals" section, display a value for the date that the referral source was last updated.`}
                />
                <UserCheckboxPref
                    field="persistReferralSearch"
                    label="Remember my filter when viewing all Referral sources"
                    description={`When viewing all referral sources in the main "Referrals" section, remember the last search that was performed, even when I log out and log back in.`}
                />
                <Select
                    label="Communities to show in Organization search"
                    onChange={e => updateReferralSearchCommunities(e.target.value)}
                    value={referralSearchCommunities}>
                    <option value="onlyReferred">Only referred communities</option>
                    <option value="all">All communities</option>
                </Select>
            </div>
        </ContentFull>
    );
};
