import { allInvoiceSettingsKeys } from "@sp-crm/core";
import { ContentFull, ContentHeader, ContentSubheader } from "components/layout";
import { LocaleContext } from "components/locale-provider/locale-provider";
import { NumberInput } from "components/shared/number-input";
import { AutosavingInput } from "components/ui/autosaving-input";
import { AutosavingStateInput } from "components/ui/autosaving-state-input";
import { InlineBanner } from "components/ui/inline-banner";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useCurrentRegion, useIsMultiRegionUser } from "store/selectors/hooks";
import { useRegionSettings, useTenantSettings } from "store/selectors/settings";

export const AgencySettingsInvoices: React.FC<unknown> = () => {
    const { settings, putSetting } = useTenantSettings();

    const multiRegion = useIsMultiRegionUser();
    const { atLeastOneOverridden } = useRegionSettings();

    const showOverriddenBanner = useMemo(() => {
        return multiRegion && atLeastOneOverridden(allInvoiceSettingsKeys);
    }, [multiRegion, atLeastOneOverridden]);
    const region = useCurrentRegion();

    return (
        <LocaleContext.Consumer>
            {locale => (
                <div className="space-y-4 lg:space-y-8">
                    <ContentFull>
                        <ContentHeader>
                            {multiRegion ? `Invoices - All Offices` : `Invoices`}
                        </ContentHeader>
                        <ContentSubheader>
                            To whom and where should communities send invoice payments?
                        </ContentSubheader>
                        {showOverriddenBanner ? (
                            <InlineBanner type="info">
                                <strong>{region?.name}</strong> has specific invoice
                                settings defined, which will override the settings defined
                                here.{" "}
                                <Link to="/settings/region/invoices">Click here</Link> to
                                change the {region?.name} invoice settings.
                            </InlineBanner>
                        ) : null}
                        <div className="space-y-4">
                            <AutosavingInput
                                label="Name invoices payable to"
                                multiLine={false}
                                initial={settings.invoicePayableName}
                                onCommit={newValue =>
                                    putSetting("invoicePayableName", newValue)
                                }
                            />
                            <AutosavingInput
                                label="Address"
                                multiLine={false}
                                initial={settings.invoicePayableAddress}
                                onCommit={newValue =>
                                    putSetting("invoicePayableAddress", newValue)
                                }
                            />
                            <AutosavingInput
                                label="City"
                                multiLine={false}
                                initial={settings.invoicePayableCity}
                                onCommit={newValue =>
                                    putSetting("invoicePayableCity", newValue)
                                }
                            />
                            <AutosavingStateInput
                                label={locale.strings.state}
                                initial={settings.invoicePayableState}
                                onCommit={newValue =>
                                    putSetting("invoicePayableState", newValue)
                                }
                            />
                            <AutosavingInput
                                label={locale.strings.zip}
                                multiLine={false}
                                initial={settings.invoicePayableZip}
                                onCommit={newValue =>
                                    putSetting("invoicePayableZip", newValue)
                                }
                            />
                            <AutosavingInput
                                label="Phone"
                                multiLine={false}
                                initial={settings.invoicePayablePhone}
                                onCommit={newValue =>
                                    putSetting("invoicePayablePhone", newValue)
                                }
                            />
                            <AutosavingInput
                                label="Email"
                                multiLine={false}
                                initial={settings.invoicePayableEmail}
                                onCommit={newValue =>
                                    putSetting("invoicePayableEmail", newValue)
                                }
                            />
                            <AutosavingInput
                                label={
                                    <div>
                                        <p>Payment notes</p>
                                        <p className="text-gray-500 text-sm">
                                            For payment provider information like Zelle or
                                            Paypal
                                        </p>
                                    </div>
                                }
                                multiLine={false}
                                initial={settings.invoicePayableNotes}
                                onCommit={newValue =>
                                    putSetting("invoicePayableNotes", newValue)
                                }
                            />
                            <NumberInput
                                label="How many days after client move-in date is invoice due?"
                                initial={settings.defaultDaysBeforeInvoiceDue}
                                onCommit={newValue =>
                                    putSetting("defaultDaysBeforeInvoiceDue", newValue)
                                }
                            />
                            <AutosavingInput
                                label={
                                    <div>
                                        <p>Default invoice notes</p>
                                        <p className="text-gray-500 text-sm">
                                            Pre-populate new invoices with these notes
                                        </p>
                                    </div>
                                }
                                multiLine={true}
                                initial={settings.invoiceDefaultNotes}
                                onCommit={newValue =>
                                    putSetting("invoiceDefaultNotes", newValue)
                                }
                            />
                        </div>
                    </ContentFull>
                </div>
            )}
        </LocaleContext.Consumer>
    );
};
