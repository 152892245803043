import { CommunityOrgTypeSentinel, formatDateNoTime } from "@sp-crm/core";
import { AdvancedSearchTable } from "components/advanced-search/advanced-search-table";
import { Content, SectionHeader, Stage } from "components/layout";
import { useCommitUserPreference } from "components/manage/account-settings/account-settings-hooks";
import { Subnav } from "components/shared/subnav";
import {
    ISearchRequest,
    SearchRequestPagination,
} from "components/simple-search/search-request-pagination";
import { SortDown, SortUp } from "components/ui/icon";
import { PrimaryButton } from "components/ui/primary-button";
import { Toggle } from "components/ui/toggle";
import {
    ReferenceBusinessSearchResult,
    ReferenceCommunitySearchResult,
    useCreateReferenceBusinessMutation,
    useCreateReferenceContactMutation,
    useReferenceBusinessSearchQuery,
    useReferenceCommunitySearchQuery,
    useReferenceContactSearchQuery,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { navigate } from "store/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
    useCanSeeReferralSourceOwners,
    usePreferences,
    useRegionId,
} from "store/selectors/hooks";
import {
    ReferenceFilter,
    referenceDashboardSlice,
} from "store/slices/reference-dashboard";
import { ReferenceSearchBar } from "./dashboard-reference/reference-search-bar";

interface SearchResultsHeaderProps {
    request: ISearchRequest;
    title: string;
    fieldName: string;
    onClick: (fieldName: string) => void;
}

const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = props => {
    const { title, fieldName, onClick, request } = props;

    const handleHeaderClick = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            onClick(fieldName);
        },
        [onClick, fieldName],
    );

    const showSort = request.sort === fieldName;

    return (
        <a href="#" onClick={handleHeaderClick}>
            <p className="text-gray-900 flex items-center space-x-2">
                <span>{title}</span>
                {showSort ? (
                    request.sortDirection === "ASC" ? (
                        <SortUp />
                    ) : (
                        <SortDown />
                    )
                ) : null}
            </p>
        </a>
    );
};

type ReferenceDashboardTableRouteMatch = {
    params?: {
        subpage?: string;
    };
} | null;

export const ReferenceDashboardTable: React.FC<unknown> = () => {
    const regionId = useRegionId();
    const dispatch = useAppDispatch();
    const newAppState = useAppSelector(state => state.referenceDashboard);
    const { updateSearch, changePage, changePageSize, changeSort } =
        referenceDashboardSlice.actions;

    const onSearchUpdated = useCallback(
        (search: ReferenceFilter) => {
            dispatch(updateSearch(search));
        },
        [dispatch, updateSearch],
    );

    const handleContactHeaderClick = useCallback(
        (fieldName: string) =>
            dispatch(changeSort({ aspect: "contact", sort: fieldName })),
        [dispatch, changeSort],
    );

    const handleBusinessHeaderClick = useCallback(
        (fieldName: string) =>
            dispatch(changeSort({ aspect: "business", sort: fieldName })),
        [dispatch, changeSort],
    );

    const handleCommunityHeaderClick = useCallback(
        (fieldName: string) =>
            dispatch(changeSort({ aspect: "community", sort: fieldName })),
        [dispatch, changeSort],
    );

    const handleContactPageChange = useCallback(
        (page: number) => dispatch(changePage({ aspect: "contact", page })),
        [dispatch, changePage],
    );

    const handleContactPageSizeChange = useCallback(
        (page: number) => dispatch(changePageSize({ aspect: "contact", pageSize: page })),
        [dispatch, changePageSize],
    );

    const handleBusinessPageChange = useCallback(
        (page: number) => dispatch(changePage({ aspect: "business", page })),
        [dispatch, changePage],
    );

    const handleBusinessPageSizeChange = useCallback(
        (page: number) =>
            dispatch(changePageSize({ aspect: "business", pageSize: page })),
        [dispatch, changePageSize],
    );

    const handleCommunityPageChange = useCallback(
        (page: number) => dispatch(changePage({ aspect: "community", page })),
        [dispatch, changePage],
    );

    const handleCommunityPageSizeChange = useCallback(
        (page: number) =>
            dispatch(changePageSize({ aspect: "community", pageSize: page })),
        [dispatch, changePageSize],
    );

    const referenceContactSearchQuery = useReferenceContactSearchQuery(
        {
            search: { regionId, ...newAppState.contactSearchRequest },
        },
        { keepPreviousData: true },
    );

    const referenceBusinessSearchQuery = useReferenceBusinessSearchQuery(
        {
            search: { regionId, ...newAppState.businessSearchRequest },
        },
        { keepPreviousData: true },
    );

    const referenceCommunitySearchQuery = useReferenceCommunitySearchQuery(
        {
            search: { regionId, ...newAppState.communitySearchRequest },
        },
        { keepPreviousData: true },
    );

    const handleSubpageSelected = useCallback((subpage: string) => {
        navigate(`/references/${subpage}`);
    }, []);

    const handleCloseSection = useCallback(() => {
        navigate(`/references`);
    }, []);

    const createReferenceContactMutation = useCreateReferenceContactMutation();
    const createReferenceBusinessMutation = useCreateReferenceBusinessMutation();

    const handleAddContact = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            const response = await createReferenceContactMutation.mutateAsync({
                regionId,
            });

            if (response?.createReferenceContact?.id) {
                navigate(
                    `/references/contacts/show/${response.createReferenceContact.id}`,
                );
            }
        },
        [createReferenceContactMutation, regionId],
    );

    const handleAddOrganization = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            const response = await createReferenceBusinessMutation.mutateAsync({
                regionId,
            });

            if (response?.createReferenceBusiness?.id) {
                navigate(
                    `/references/organizations/show/${response.createReferenceBusiness.id}`,
                );
            }
        },
        [createReferenceBusinessMutation, regionId],
    );

    const routeMatch: ReferenceDashboardTableRouteMatch =
        useRouteMatch(`/references/:subpage`);

    const userSettings = usePreferences();

    const includeLastUpdated = userSettings.showReferralLastUpdatedDateOnMainTable;
    const includeCity = userSettings.showReferralCityOnMainTable;
    const includeAssignedTo = useCanSeeReferralSourceOwners();

    const renderDate = useCallback(
        (params: { record: Record<string, unknown>; key: string }) => {
            const value = params.record[params.key];
            return <span>{formatDateNoTime(value, "")}</span>;
        },
        [],
    );

    const renderContactName = useCallback(
        (params: { record: Record<string, unknown>; key: string }) => {
            const name = params.record[params.key] as string;
            const id = params.record["id"];

            if (id) {
                return (
                    <Link to={`/references/contacts/show/${id}`}>
                        {name || "(no name)"}
                    </Link>
                );
            }

            return <span></span>;
        },
        [],
    );

    const renderBusinessName = useCallback(
        (params: { record: Record<string, unknown>; key: string }) => {
            const name = params.record[params.key] as string;
            const id = params.record["id"];

            if (id) {
                return (
                    <Link to={`/references/organizations/show/${id}`}>
                        {name || "(no name)"}
                    </Link>
                );
            }

            return <span></span>;
        },
        [],
    );

    const renderCommunityName = useCallback(
        (params: { record: Record<string, unknown>; key: string }) => {
            const name = params.record[params.key] as string;
            const id = params.record["id"];

            if (id) {
                return <Link to={`/communities/show/${id}`}>{name || "(no name)"}</Link>;
            }

            return <span></span>;
        },
        [],
    );

    const renderOrganizationName = useCallback(
        (params: { record: Record<string, unknown>; key: string }) => {
            const name = params.record[params.key] as string;

            if (params.record.communityId) {
                return (
                    <Link to={`/communities/show/${params.record.communityId}`}>
                        {name || "(no name)"}
                    </Link>
                );
            }

            if (params.record.businessId) {
                return (
                    <Link
                        to={`/references/organizations/show/${params.record.businessId}`}>
                        {name || "(no name)"}
                    </Link>
                );
            }

            if (name) {
                return <span> {name}</span>;
            }

            return <span></span>;
        },
        [],
    );

    const contactColumns = useMemo(() => {
        const columns = [
            {
                key: "name",
                header: (
                    <SearchResultsHeader
                        request={newAppState.contactSearchRequest}
                        fieldName="name"
                        title="Name"
                        onClick={handleContactHeaderClick}
                    />
                ),
                renderCell: renderContactName,
            },
            {
                key: "role",
                header: (
                    <SearchResultsHeader
                        request={newAppState.contactSearchRequest}
                        fieldName="role"
                        title="Role / Title"
                        onClick={handleContactHeaderClick}
                    />
                ),
            },

            includeLastUpdated
                ? {
                      key: "updatedAt",
                      header: (
                          <SearchResultsHeader
                              request={newAppState.contactSearchRequest}
                              fieldName="updatedAt"
                              title="Contact last updated"
                              onClick={handleContactHeaderClick}
                          />
                      ),
                      renderCell: renderDate,
                  }
                : null,
            {
                key: "organizationName",
                header: (
                    <SearchResultsHeader
                        request={newAppState.contactSearchRequest}
                        fieldName="organizationName"
                        title="Organization name"
                        onClick={handleContactHeaderClick}
                    />
                ),
                renderCell: renderOrganizationName,
            },
            {
                key: "organizationType",
                header: (
                    <SearchResultsHeader
                        request={newAppState.contactSearchRequest}
                        fieldName="organizationType"
                        title="Organization type"
                        onClick={handleContactHeaderClick}
                    />
                ),
            },
            includeCity
                ? {
                      key: "city",
                      header: (
                          <SearchResultsHeader
                              request={newAppState.contactSearchRequest}
                              fieldName="city"
                              title="City"
                              onClick={handleContactHeaderClick}
                          />
                      ),
                  }
                : null,
            {
                key: "numberOfReferrals",
                header: (
                    <SearchResultsHeader
                        request={newAppState.contactSearchRequest}
                        fieldName="numberOfReferrals"
                        title="Number of referrals"
                        onClick={handleContactHeaderClick}
                    />
                ),
            },
            {
                key: "mostRecentReferral",
                header: (
                    <SearchResultsHeader
                        request={newAppState.contactSearchRequest}
                        fieldName="mostRecentReferral"
                        title="Most recent referral"
                        onClick={handleContactHeaderClick}
                    />
                ),
                renderCell: renderDate,
            },
            includeAssignedTo
                ? {
                      key: "assignedTo",
                      header: (
                          <SearchResultsHeader
                              request={newAppState.contactSearchRequest}
                              fieldName="assignedTo"
                              title="Assigned to"
                              onClick={handleContactHeaderClick}
                          />
                      ),
                  }
                : null,
        ].filter(c => !!c);

        return columns;
    }, [
        includeLastUpdated,
        includeCity,
        includeAssignedTo,
        handleContactHeaderClick,
        newAppState.contactSearchRequest,
        renderDate,
        renderContactName,
        renderOrganizationName,
    ]);

    const businessColumns = useMemo(() => {
        const columns = [
            {
                key: "name",
                header: (
                    <SearchResultsHeader
                        request={newAppState.businessSearchRequest}
                        fieldName="name"
                        title="Name"
                        onClick={handleBusinessHeaderClick}
                    />
                ),
                renderCell: renderBusinessName,
            },
            includeLastUpdated
                ? {
                      key: "updatedAt",
                      header: (
                          <SearchResultsHeader
                              request={newAppState.businessSearchRequest}
                              fieldName="updatedAt"
                              title="Org last updated"
                              onClick={handleBusinessHeaderClick}
                          />
                      ),
                      renderCell: renderDate,
                  }
                : null,
            {
                key: "organizationType",
                header: (
                    <SearchResultsHeader
                        request={newAppState.businessSearchRequest}
                        fieldName="organizationType"
                        title="Organization type"
                        onClick={handleBusinessHeaderClick}
                    />
                ),
            },
            includeCity
                ? {
                      key: "city",
                      header: (
                          <SearchResultsHeader
                              request={newAppState.businessSearchRequest}
                              fieldName="city"
                              title="City"
                              onClick={handleBusinessHeaderClick}
                          />
                      ),
                  }
                : null,
            {
                key: "numberOfReferrals",
                header: (
                    <SearchResultsHeader
                        request={newAppState.businessSearchRequest}
                        fieldName="numberOfReferrals"
                        title="Number of referrals"
                        onClick={handleBusinessHeaderClick}
                    />
                ),
            },
            {
                key: "mostRecentReferral",
                header: (
                    <SearchResultsHeader
                        request={newAppState.businessSearchRequest}
                        fieldName="mostRecentReferral"
                        title="Most recent referral"
                        onClick={handleBusinessHeaderClick}
                    />
                ),
                renderCell: renderDate,
            },
            includeAssignedTo
                ? {
                      key: "assignedTo",
                      header: (
                          <SearchResultsHeader
                              request={newAppState.businessSearchRequest}
                              fieldName="assignedTo"
                              title="Assigned to"
                              onClick={handleBusinessHeaderClick}
                          />
                      ),
                  }
                : null,
        ].filter(c => !!c);

        return columns;
    }, [
        includeLastUpdated,
        includeCity,
        includeAssignedTo,
        handleBusinessHeaderClick,
        newAppState.businessSearchRequest,
        renderDate,
        renderBusinessName,
    ]);

    const communityColumns = useMemo(() => {
        const columns = [
            {
                key: "name",
                header: (
                    <SearchResultsHeader
                        request={newAppState.communitySearchRequest}
                        fieldName="name"
                        title="Name"
                        onClick={handleCommunityHeaderClick}
                    />
                ),
                renderCell: renderCommunityName,
            },
            includeLastUpdated
                ? {
                      key: "updatedAt",
                      header: (
                          <SearchResultsHeader
                              request={newAppState.communitySearchRequest}
                              fieldName="updatedAt"
                              title="Last updated"
                              onClick={handleCommunityHeaderClick}
                          />
                      ),
                      renderCell: renderDate,
                  }
                : null,
            includeCity
                ? {
                      key: "city",
                      header: (
                          <SearchResultsHeader
                              request={newAppState.communitySearchRequest}
                              fieldName="city"
                              title="City"
                              onClick={handleCommunityHeaderClick}
                          />
                      ),
                  }
                : null,
            {
                key: "numberOfReferrals",
                header: (
                    <SearchResultsHeader
                        request={newAppState.communitySearchRequest}
                        fieldName="numberOfReferrals"
                        title="Number of referrals"
                        onClick={handleCommunityHeaderClick}
                    />
                ),
            },
            {
                key: "mostRecentReferral",
                header: (
                    <SearchResultsHeader
                        request={newAppState.communitySearchRequest}
                        fieldName="mostRecentReferral"
                        title="Most recent referral"
                        onClick={handleCommunityHeaderClick}
                    />
                ),
                renderCell: renderDate,
            },
        ].filter(c => !!c);

        return columns;
    }, [
        includeLastUpdated,
        includeCity,
        handleCommunityHeaderClick,
        newAppState.communitySearchRequest,
        renderDate,
        renderCommunityName,
    ]);

    const renderContactsTable = useCallback(() => {
        return (
            <>
                <AdvancedSearchTable
                    data={
                        referenceContactSearchQuery.data?.referenceContactSearch.hits ||
                        []
                    }
                    columns={contactColumns}
                />
                {referenceContactSearchQuery.data ? (
                    <SearchRequestPagination
                        request={newAppState.contactSearchRequest}
                        total={
                            referenceContactSearchQuery.data.referenceContactSearch.total
                        }
                        onPageChange={handleContactPageChange}
                        onPageSizeChange={handleContactPageSizeChange}
                    />
                ) : null}
            </>
        );
    }, [
        referenceContactSearchQuery.data,
        contactColumns,
        newAppState.contactSearchRequest,
        handleContactPageChange,
        handleContactPageSizeChange,
    ]);

    const businessResults: ReferenceBusinessSearchResult = useMemo(() => {
        if (newAppState.contactSearchRequest.contactCondition) {
            return {
                hits: [],
                total: 0,
            };
        }

        return (
            referenceBusinessSearchQuery.data?.referenceBusinessSearch || {
                hits: [],
                total: 0,
            }
        );
    }, [
        referenceBusinessSearchQuery.data?.referenceBusinessSearch,
        newAppState.contactSearchRequest.contactCondition,
    ]);

    const renderBusinessesTable = useCallback(() => {
        return (
            <>
                <AdvancedSearchTable
                    data={businessResults.hits || []}
                    columns={businessColumns}
                />
                {businessResults ? (
                    <SearchRequestPagination
                        request={newAppState.businessSearchRequest}
                        total={businessResults.total}
                        onPageChange={handleBusinessPageChange}
                        onPageSizeChange={handleBusinessPageSizeChange}
                    />
                ) : null}
            </>
        );
    }, [
        businessResults,
        businessColumns,
        newAppState.businessSearchRequest,
        handleBusinessPageChange,
        handleBusinessPageSizeChange,
    ]);

    const communityResults: ReferenceCommunitySearchResult = useMemo(() => {
        if (
            (newAppState.contactSearchRequest.organizationTypes?.length > 0 &&
                !newAppState.contactSearchRequest.organizationTypes.includes(
                    CommunityOrgTypeSentinel,
                )) ||
            newAppState.contactSearchRequest.contactCondition ||
            newAppState.contactSearchRequest.businessCondition
        ) {
            return {
                hits: [],
                total: 0,
            };
        }

        return (
            referenceCommunitySearchQuery.data?.referenceCommunitySearch || {
                hits: [],
                total: 0,
            }
        );
    }, [
        referenceCommunitySearchQuery.data?.referenceCommunitySearch,
        newAppState.contactSearchRequest.organizationTypes,
        newAppState.contactSearchRequest.contactCondition,
        newAppState.contactSearchRequest.businessCondition,
    ]);

    const renderCommunitiesTable = useCallback(() => {
        return (
            <>
                <AdvancedSearchTable
                    data={communityResults.hits}
                    columns={communityColumns}
                />
                {communityResults ? (
                    <SearchRequestPagination
                        request={newAppState.communitySearchRequest}
                        total={communityResults.total}
                        onPageChange={handleCommunityPageChange}
                        onPageSizeChange={handleCommunityPageSizeChange}
                    />
                ) : null}
            </>
        );
    }, [
        communityResults,
        communityColumns,
        newAppState.communitySearchRequest,
        handleCommunityPageChange,
        handleCommunityPageSizeChange,
    ]);

    const subpages = [
        {
            href: `/references/contacts`,
            linkText: `Contacts`,
            badgeCount: referenceContactSearchQuery.data?.referenceContactSearch.total,
            subpage: "contacts",
            render: renderContactsTable,
        },
        {
            href: `/references/organizations`,
            linkText: `Organizations`,
            badgeCount: businessResults.total,
            subpage: "organizations",
            render: renderBusinessesTable,
        },
        {
            href: `/references/communities`,
            linkText: `Communities`,
            badgeCount: communityResults.total,
            subpage: "communities",
            render: renderCommunitiesTable,
        },
    ];

    const commitMap = useCommitUserPreference("referralMapExperience");

    return (
        <Stage>
            <SectionHeader title="Referral sources">
                <div className="flex items-center space-x-2">
                    <PrimaryButton onClick={handleAddContact}>
                        Add a new contact
                    </PrimaryButton>
                    <PrimaryButton onClick={handleAddOrganization}>
                        Add a new organization
                    </PrimaryButton>
                </div>
            </SectionHeader>
            <Content>
                <ReferenceSearchBar
                    value={newAppState.searchFilter}
                    onChange={onSearchUpdated}
                />
            </Content>
            <div className="mt-2 lg:mt-4">
                <Content>
                    <Subnav
                        subpages={subpages}
                        navigate={handleSubpageSelected}
                        selectedSubpage={routeMatch?.params?.subpage}
                        defaultSubpage="contacts"
                        closeSection={handleCloseSection}
                        widthMode="auto"
                        headerComponent={
                            <Toggle
                                label="Use map experience"
                                checked={false}
                                onChange={newValue => {
                                    commitMap(newValue);
                                }}
                            />
                        }
                    />
                </Content>
            </div>
        </Stage>
    );
};
