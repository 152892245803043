import { TertiarynavMenu, TertiarynavMenuItem } from "components/navigation/tertiarynav";
import React from "react";

export const RegionSettingsNav: React.FC<unknown> = () => {
    return (
        <TertiarynavMenu>
            <TertiarynavMenuItem to="/settings/region/invoices">
                Invoices
            </TertiarynavMenuItem>
        </TertiarynavMenu>
    );
};
