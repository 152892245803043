import { TenantCredentialId } from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import { linkStyle } from "components/reports/show/custom-reports/custom-reports-sidebar";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { PremiumCallout } from "components/ui/premium-callout";
import { primaryClasses } from "components/ui/primary-button";
import {
    GetTenantCredentialsQuery,
    TenantCredentialStatus,
    TenantCredentialType,
    useDeleteTenantCredentialMutation,
    useGetTenantCredentialsQuery,
} from "generated/graphql";
import React, { useCallback } from "react";
import { useProductName } from "store/selectors/branding";
import { useFeature } from "store/selectors/hooks";

interface ManageIntegrationsQuickBooksProps {}

export const ManageIntegrationsQuickBooks: React.FC<
    ManageIntegrationsQuickBooksProps
> = props => {
    const isFeatureEnabled = useFeature("quickbooks");
    const productName = useProductName();

    return (
        <ContentFull>
            <ContentHeader>QuickBooks Integration</ContentHeader>
            {isFeatureEnabled ? (
                <ManageIntegrationsQuickBooksActual />
            ) : (
                <PremiumCallout>
                    QuickBooks integration is only available on request to {productName}{" "}
                    Premium customers. <br />
                    Please{" "}
                    <SupportEmailLink
                        label="contact support"
                        subject="QuickBooks Integration"
                    />{" "}
                    to request an upgrade.
                </PremiumCallout>
            )}
        </ContentFull>
    );
};

export const ManageIntegrationsQuickBooksActual: React.FC<unknown> = () => {
    const query = useGetTenantCredentialsQuery();

    const handleDeleted = useCallback(() => {
        query.refetch();
    }, [query]);
    const productName = useProductName();

    return (
        <QueryRenderer query={query} name="ManageIntegrationsQuickBooks">
            {data =>
                data.getTenantCredentials.length > 0 ? (
                    <ul>
                        {data.getTenantCredentials.map(credential => (
                            <li key={credential.id}>
                                <QuickBooksConnection
                                    credential={credential}
                                    onDeleted={handleDeleted}
                                />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="space-y-4">
                        <div>
                            In order to set up QuickBooks integration, please click{" "}
                            <strong>Connect to QuickBooks</strong> below. You will be
                            taken to your QuickBooks online account and will be asked to
                            give permission to {productName} in order to access your data.
                            Once that is complete, you will be returned to {productName}.
                        </div>
                        <div>
                            <a href="/api/auth/quickbooks" className={primaryClasses}>
                                Connect to QuickBooks
                            </a>
                        </div>
                    </div>
                )
            }
        </QueryRenderer>
    );
};

interface QuickBooksConnectionProps {
    credential: GetTenantCredentialsQuery["getTenantCredentials"][0];
    onDeleted: (id: TenantCredentialId) => void;
}

export const QuickBooksConnection: React.FC<QuickBooksConnectionProps> = props => {
    const { credential, onDeleted } = props;

    const deleteTenantCredential = useDeleteTenantCredentialMutation();
    const productName = useProductName();

    const handleDisconnect = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const confirmed = await fancyConfirm(
                "Remove QuickBooks",
                `Are you sure you want to disconnect QuickBooks? Your QuickBooks invoices will still be available in ${productName}, but they will no longer be synchronized automatically.`,
                "Yes, disconnect",
                "Cancel",
            );

            if (confirmed && credential?.id) {
                await deleteTenantCredential.mutateAsync({ id: credential.id });
                onDeleted(credential.id);
            }
        },
        [credential?.id, deleteTenantCredential, onDeleted, productName],
    );

    if (credential?.type !== TenantCredentialType.QuickBooks) {
        return null;
    }

    return (
        <div className="space-y-2">
            {credential.status === TenantCredentialStatus.Active ? (
                <InlineBanner type="success">QuickBooks is connected</InlineBanner>
            ) : (
                <>
                    <InlineBanner type="error">
                        QuickBooks is no longer connected to {productName}. Please click{" "}
                        <strong>Reconnect QuickBooks</strong> below to reconnect. You will
                        be taken to the QuickBooks website to reauthorize access. Once
                        that is complete, you will be returned to {productName}.
                    </InlineBanner>
                    <div>
                        <a href="/api/auth/quickbooks" className={primaryClasses}>
                            Reconnect QuickBooks
                        </a>
                    </div>
                </>
            )}
            <div className="flex space-x-1 items-center">
                <strong>Company/Realm ID:</strong>
                <pre>{credential.quickBooksDetails?.realmId}</pre>
            </div>
            <button onClick={handleDisconnect} className={linkStyle}>
                <span className="text-sm">Remove integration</span>
            </button>
        </div>
    );
};
