import { IClient, QuestionCategories } from "@sp-crm/core";
import { Notes } from "components/notes";
import { NgGrid } from "components/shared/grid";
import { WorkflowEntityExecutions } from "components/workflow/workflow-entity-executions";
import React from "react";
import { useTenantSettings } from "store/selectors/settings";
import { ClientAgreementSection } from "./client-agreement-section";
import { ClientBestContact } from "./client-best-contact";
import { ClientCurrentLocation } from "./client-current-location";
import { ClientInfoForm } from "./client-info-form";
import { ClientReferralSource } from "./client-referral-source";
import { LegacyPowerOfAttorney } from "./power-of-attorney";
import ClientQuestionSection from "./question-section";

interface ClientOverviewTabProps {
    client: IClient;
    onFieldChange: (fieldName: string, newValue: string) => void;
    onFieldChangeMulti: (fieldValues: Record<string, unknown>) => void;
    onSendDisclosure: () => void;
}

export const ClientOverviewTab: React.FC<ClientOverviewTabProps> = props => {
    const { client, onFieldChange, onFieldChangeMulti, onSendDisclosure } = props;
    const {
        settings: { showClientFirst },
    } = useTenantSettings();
    return (
        <NgGrid>
            <NgGrid.Column>
                {showClientFirst ? (
                    <>
                        <ClientInfoForm
                            client={client}
                            onFieldChange={onFieldChange}
                            onMultiFieldChange={onFieldChangeMulti}
                        />
                        <ClientCurrentLocation
                            client={client}
                            onFieldChange={onFieldChange}
                        />
                        <ClientBestContact
                            client={client}
                            onFieldChangeMulti={onFieldChangeMulti}
                        />
                    </>
                ) : (
                    <>
                        <ClientBestContact
                            client={client}
                            onFieldChangeMulti={onFieldChangeMulti}
                        />
                        <ClientInfoForm
                            client={client}
                            onFieldChange={onFieldChange}
                            onMultiFieldChange={onFieldChangeMulti}
                        />
                        <ClientCurrentLocation
                            client={client}
                            onFieldChange={onFieldChange}
                        />
                    </>
                )}
                <LegacyPowerOfAttorney client={client} onFieldChange={onFieldChange} />
            </NgGrid.Column>
            <NgGrid.Column>
                <Notes entityId={client.id} />
                <ClientQuestionSection
                    category={QuestionCategories.summary}
                    clientId={client.id}
                />
                <ClientReferralSource client={client} onFieldChange={onFieldChange} />
                <ClientAgreementSection
                    client={client}
                    onFieldChange={onFieldChange}
                    onSendDisclosure={onSendDisclosure}
                />
                <WorkflowEntityExecutions entity={client} />
            </NgGrid.Column>
        </NgGrid>
    );
};
