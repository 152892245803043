import * as Common from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { SelectLabelPicker } from "components/ui/select-label-picker";
import * as React from "react";
import { useTenantSettings } from "store/selectors/settings";
import Shared from "../../shared";

interface BuiltInPriceFieldsProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    getLastUpdatedLabel: (prop: any) => string;
    priceCommitHelper: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        newValue: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        oldValue: any,
        priceProp: string,
        priceModifiedProp: string,
    ) => void;
    community: Common.ICommunity;
}

export const BuiltInPriceFields: React.FC<BuiltInPriceFieldsProps> = props => {
    const {
        settings: {
            showCommunityBaseAndHighPrice,
            showCommunitySecondPersonFee,
            showCommunityPetDeposit,
            showCommunityFee,
        },
    } = useTenantSettings();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const onCommitMonthlyPrice = (newValue: any) => {
        props.priceCommitHelper(
            newValue,
            props.community.monthlyPrice,
            "monthlyPrice",
            "monthlyPriceLastModified",
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const onCommitPriceHighEnd = (newValue: any) => {
        props.priceCommitHelper(
            newValue,
            props.community.priceHighEnd,
            "priceHighEnd",
            "priceHighEndLastModified",
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const onCommitDepositPrice = (newValue: any) => {
        props.priceCommitHelper(
            newValue,
            props.community.moveInDeposit,
            "moveInDeposit",
            "depositPriceLastModified",
        );
    };

    return (
        <>
            <div
                className={
                    // only use a className if we have at least one real child div
                    showCommunityBaseAndHighPrice ||
                    showCommunitySecondPersonFee ||
                    showCommunityPetDeposit
                        ? "mb-4 flex flex-wrap flex-grow flex-shrink"
                        : null
                }>
                {showCommunityBaseAndHighPrice ? (
                    <>
                        <div className="min-w-half w-60 pr-4 flex-grow flex-shrink">
                            <Shared.InputCurrency
                                label="Monthly Base Price"
                                secondaryLabel={props.getLastUpdatedLabel(
                                    props.community.monthlyPriceLastModified,
                                )}
                                initial={props.community.monthlyPrice}
                                onCommit={newVal => onCommitMonthlyPrice(newVal)}
                            />
                        </div>
                        <div className="min-w-half w-60 pr-4 flex-grow flex-shrink">
                            <Shared.InputCurrency
                                label="Price (High End)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" // Hack so that as
                                // the width resizes downward, this remains the same width as the
                                // "Monthly Base Price" string.
                                secondaryLabel={props.getLastUpdatedLabel(
                                    props.community.priceHighEndLastModified,
                                )}
                                initial={props.community.priceHighEnd}
                                onCommit={newVal => onCommitPriceHighEnd(newVal)}
                            />
                        </div>
                    </>
                ) : null}
                {showCommunitySecondPersonFee ? (
                    <div className="min-w-half w-60 pr-4 flex-grow flex-shrink">
                        <Shared.InputCurrency
                            label="Second Person Fee"
                            initial={props.community.secondPersonFee}
                            onCommit={newVal =>
                                props.onFieldChange("secondPersonFee", newVal)
                            }
                        />
                    </div>
                ) : null}
                {showCommunityPetDeposit ? (
                    <div className="min-w-half w-60 pr-4 flex-grow flex-shrink">
                        <AutosavingInput
                            label="Pet Deposit (if applicable)"
                            initial={props.community.petDeposit}
                            onCommit={newVal => props.onFieldChange("petDeposit", newVal)}
                        />
                    </div>
                ) : null}
            </div>
            {showCommunityFee ? (
                <div>
                    <div className="form-group min-w-half pr-4 flex-grow flex-shrink">
                        <SelectLabelPicker
                            value={props.community.feeType || "CommunityFee"}
                            onChange={e => props.onFieldChange("feeType", e.target.value)}
                            options={[
                                { value: "CommunityFee", text: "Community Fee" },
                                { value: "EntranceFee", text: "Entrance Fee" },
                            ]}
                        />
                        <Shared.InputCurrency
                            secondaryLabel={props.getLastUpdatedLabel(
                                props.community.depositPriceLastModified,
                            )}
                            initial={props.community.moveInDeposit}
                            onCommit={newVal => onCommitDepositPrice(newVal)}
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};
